import React from "react";
import "./Outlets.css";
import Footer from "../components/Footer";

import { useState, useEffect } from "react";
import axios from "../api/axios";

import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import { BASE_URL } from '../constants';

const Artistcontent2 = () => {
  const navigate = useNavigate();

  const [allartists, setAllartists] = useState([]);

  const itemsPerPage = 16;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(allartists.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentArtists = allartists.slice(startIndex, endIndex);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let isMounted = true;

    const getAllartists = async () => {
      try {
        
        const response = await axios.get(`/artists`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        isMounted && setAllartists(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getAllartists();

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, []);

  return (
    <div className="layout mb-5">
      <div className="mb-5  pb-10 ">
      <div className=" allevt_crds-main align-items-center justify-content-center flex-wrap">
          {currentArtists.map((artist, index) => (
            <div
              key={index}
              onClick={() =>
                navigate(`/videos/show_videos/${artist.artist_id}/${artist.artist_stage_name}`)
              }
              className="flex-column  cursor-pointer align-items-center imagesinglecard allevent-crd"
              // style={{ height: "350px", width: "350px" }}
            >
              <img
                src={`${BASE_URL}${artist.artist_profile_icon}`} 
                alt="one"
                // style={{ height: "90%", width: "90%", objectFit: "cover" }}
                className="all-evt-cards"
              />

              <div
                style={{ height: "10%", width: "90%" }}
                className="flex align-items-center  justify-center "
              >
                <h1
                  className="mt-3 text-center font-semibold "
                  style={{ left: "50%", color: "white" }}
                >
                  {artist.artist_stage_name}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>

      <div className="footer-overlay">
        <Footer />
      </div>
    </div>
  );
};

export default Artistcontent2;
