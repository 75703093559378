import axios from "../api/axios";
import useAuth from "./useAuth";

function useRefreshToken() {
  const { auth, setAuth } = useAuth();

  const refresh = async () => {
    try {      
      const response = await axios.post('/user/refresh', {}, {
        withCredentials: true,
        headers: {
                'Authorization': `Bearer ${localStorage.getItem('refreshToken')}`,  // Include access token in the Authorization header
                'Content-Type': 'application/json',  // Set Content-Type if needed
            },
      });

      setAuth(prev => {
      
        return { ...prev, accessToken: response.data.accessToken, role: response.data.role };
      });

      return response.data.accessToken;
    } catch (error) {
      // Handle error (e.g., log, show notification, etc.)
      console.error('Error refreshing token:', error);

      // Assuming you want to handle unauthorized cases and possibly log the user out
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error (e.g., log out the user)
      }

      // Propagate the error or handle it in the caller if needed
      throw error;
    }
  };

  return refresh;
}

export default useRefreshToken;
