import React from 'react'
import not_found_image from "../assets/page_not_found.png"
import Footer from '../components/Footer';

function PageNotFound() {
  return (
    <div className='layout'>
<div className='parent flex align-items-center justify-content-center'>
      <img
        src={not_found_image}
        alt="not_found_image"
        style={{ height: "90%", width: "95%", objectFit: "cover" }}
       
      />
      
    </div>
     <div  className="footer-overlay">
     <Footer />
   </div>
    </div>
    
  )
}

export default PageNotFound;
