import React from "react";

import "./Outlets.css";
import Footer from "../components/Footer";
import "./Outlets.css";

import axios from "../api/axios";

import { axiosPrivate } from "../api/axios";

import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";

import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import Box from "@mui/material/Box";

import { useInView } from "react-intersection-observer";

import { BASE_URL } from "../constants";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";


const Eventsvideos = () => {
  const { id, type } = useParams();
  const [allVideos, setAllVideos] = useState([]);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const [userRole, setUserRole] = useState("");

  const [open, setOpen] = React.useState(false);

  const [selectedVideo, setSelectdVideo] = useState(null);

  const [VideoId, setVideoId] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const itemsPerPage = 15;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(allVideos.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentArtist = allVideos.slice(startIndex, endIndex);
  const handleChange1 = (event, value) => {
    setPage(value);
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % allVideos.length);
    }, 5000); // Change the interval (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [allVideos]);

  const handleChange = (e) => {
    const allowedVideoTypes = ["video/mp4", "video/webm"];

    if (allowedVideoTypes.includes(e.target.files[0].type)) {
      setSelectdVideo(e.target.files[0]);
    } else {
      alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
    }
  };

  const handleReplace = (id) => {
    setVideoId(id);
    handleOpen();
  };

  const handleSubmit = async () => {
    if (!selectedVideo) {
      alert("Please Select Video to Proceed");
      return;
    }
    const formData = new FormData();
    formData.append("video_url", selectedVideo);
    const response = await axiosPrivate.put(
      `/videos/replace/${VideoId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      alert("Video Replaced Successfully");
      setOpen(false);
      await getallVideos();
      window.location.reload();
    } else {
      alert("Failed to Replace Video");
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        let userId = await localStorage.getItem("userId");
        if (!userId) return;
        const response = await axiosPrivate.get(`/user/${userId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setUserRole(response.data.role);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getUserData();
    getallVideos();
  }, []);

  const getallVideos = async () => {
    try {
      const response = await axiosPrivate.get(`/videos/${type}/${id}`);

      setAllVideos(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (id) => {
    let deleteConfirmed = window.confirm(
      "Are You Sure To Delete This Video ? "
    );

    if (deleteConfirmed) {
      const result = await axiosPrivate.delete(`/videos/delete/${id}`);
      alert("Video Deleted Successfully");
      getallVideos();
    }
  };

  return (
    <div className="layout mb-5">
      <div className="row justify-between">
        <div className="col-md-5">
          <div className="flex align-items-center justify-content-center p-5">
            <div>
              {type === "experience_videos" ? (
                <h1
                  ref={ref}
                  className={`animated-element ${
                    inView ? "animate" : ""
                  }  text-light fw-bold fs-3 mt-2 mb-2`}
                  style={{padding:"200px"}}
                >
                  EXPERIENCE
                </h1>
              ) : type === "show_videos" ? (
                <h1
                  ref={ref}
                  className={`animated-element ${
                    inView ? "animate" : ""
                  } text-light fw-bold fs-3 mt-2 mb-2`}
                  style={{padding:"200px"}}
                >
                  SNIPPETS
                </h1>
              ) : (
                <h1
                  ref={ref}
                  className={`animated-element ${
                    inView ? "animate" : ""
                  } text-light fw-bold fs-3 mt-2 mb-2 `}
                  style={{padding:"200px"}}
                >
                  VIDEOS
                </h1>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div style={{ width: "100%" , height:"500px"}}>
            {allVideos?.map((event, index) => (
              <video
                style={{ width: "100%", height: "500px", objectFit: "cover" }}
                key={index}
                autoPlay
                loop
                muted
                className={index === currentVideoIndex ? "visible" : "hidden"}
              >
                <source
                  src={`${BASE_URL}${event.video_url}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ))}
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-5 mb-4">
        {allVideos?.map((video, index) => (
          <div key={index} className="col-md-3 position-relative mb-4">
            <video
              className="img-fluid"
              style={{ objectFit: "cover" }}
              controls
            >
              <source src={`${BASE_URL}${video.video_url}`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {userRole === "superadmin" && (
              <div
                className=" flex"
                style={{
                  height: "40px",
                  width: "80px",
                  position: "absolute",
                  bottom: "15px",
                  right: "0",
                }}
              >
                <div
                  onClick={() => handleReplace(video._id)}
                  className=" d-flex justify-content-center align-items-center"
                  style={{
                    height: "100%",
                    width: "40px",
                    backgroundColor: "greenyellow",
                    cursor: "pointer",
                  }}
                >
                  <PublishedWithChangesIcon />{" "}
                </div>

                {userRole === "superadmin" && (
                  <div
                    onClick={() => handleDelete(video._id)}
                    className=" d-flex justify-content-center align-items-center"
                    style={{
                      height: "100%",
                      width: "40px",
                      backgroundColor: "black",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteForeverIcon style={{ color: "white" }} />{" "}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>


      <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange1}
          />
        </Stack>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="mx-auto  ">
            <div className="mt-3 mx-2 mb-3">
              <label for="exampleFormControlInput1" className="form-label mb-2">
                Select Video To Replace
              </label>
              <input
                type="file"
                name="intro_video"
                multiple
                accept="video/*"
                onChange={handleChange}
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                required
              />
            </div>

            <div
              onClick={handleSubmit}
              className="mx-2 mt-4 flex align-items-center justify-content-center btnstyles"
            >
              <h1 className="text-white" style={{ fontSize: "20px" }}>
                {" "}
                SUBMIT{" "}
              </h1>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Eventsvideos;
