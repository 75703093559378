import React from "react";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import * as XLSX from 'xlsx';

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { CheckCircle, Cancel } from '@mui/icons-material';

import { axiosPrivate } from "../api/axios";

import { useState, useEffect } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';





const CustomerFilter = () => {
  const [customerData, setCustomerData] = useState([]);

  const today = new Date();


  let userId = localStorage.getItem("userId");

  const location = useLocation();
  const { userRole } = location.state;

  const [row, setRow] = useState([]);

  const [mandetoryInfo, setMandetoryInfo] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);

 

  const handleAuthentication =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Authenticate This Customer ? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.patch(`/customers/authenticate/${id}`);
     alert("Customer Authenticated Succesfully");
  
     getCustomerbyFilter();
  
  
    } 
  
  };

  const handleExportClick = () => {
    const data = customerData.map(row => columns.map(col => row[col.field]));
    const ws = XLSX.utils.aoa_to_sheet([columns.map(col => col.headerName), ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };

  const handleLimit =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Limit This Customer ? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.patch(`/customers/limit/${id}`);
     alert("Customer Limited Succesfully");
  
     getCustomerbyFilter();
  
  
    } 
  
  }

  const handleDelete =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Delete This Customer ? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.delete(`/customers/${id}`);
     alert("Customer Deleted Succesfully");
  
     getCustomerbyFilter();
  
  
    } 
  
  }
  
  
  
  const columns = [
    { field: "id", headerName: "Customer ID", width: 150 },
    {
      field: "first_name",
      // approved or not approved
      headerName: "First Name",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      headerAlign: "center",
      align: 'center'
    },
  
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "phone",
      headerName: "Contact",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "age_group",
      headerName: "Age Group",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "registration_date",
      headerName: "Registration Date",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "music_preference",
      headerName: "Music Preference",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "event_preference",
      headerName: "Event Preference",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "outing_frequency",
      headerName: "Outing Frequency",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "communication_channels",
      headerName: "Communication Channels",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    { 
      field: 'authentication_status',
      headerName: 'Authentication',
      width: 150,
      align: 'center',
      headerAlign: 'left',
      renderCell: (params) => {
        // Define color based on authentication status
        let color;
        switch (params.value) {
          case true:
            color = 'green';
            break;
          case false:
            color = 'orange';
            break;
          default:
            color = 'orange';
            break;
        }
  
        // Define icon based on authentication status
        let icon;
        switch (params.value) {
          case true:
            icon = <CheckCircle style={{ color }} />;
            break;
          case false:
            icon = <Cancel style={{ color }} />;
            break;
          default:
            icon = null;
            break;
        }
        return (
          <div  style={{ display: 'flex', width: '60px', height:'40px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: color, marginRight: 8 }}>
              {icon}
            </div>
           
           
          </div>
        );
      }
    },

    {
      field: "category",
      headerName: "Category",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
 
  
    
  
    {
      field: "membership_plan",
      headerName: "Membership",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    { 
      field: 'controls',
      headerName: 'Controls',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // Define color based on authentication status
       
        // Define icon based on authentication status
        
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              {params.row.authentication_status ?
                <Button
                className="me-2"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={()=>handleLimit(params.row.id)}
                >
                  Limit
                </Button>

                :

                <Button
                className="me-2"
                  style={{ backgroundColor: "greenYellow", color: "black" }}
                  // onClick={()=>handleLimit(params.row.id)}
                  onClick = {()=>handleAuthentication(params.row.id)}
                >
                  <CheckCircleOutlineIcon />
                </Button>

                // <CheckCircleOutlineIcon style={{cursor: 'pointer'}} onClick = {()=>handleAuthentication(params.row.id)} />

              
              }

              {userRole === 'superadmin'  &&
               <Button
               style={{ backgroundColor: "red", color: "white" }}
               onClick = {()=>handleDelete(params.row.id)}
             >
               Delete
             </Button>
              }
  
            
           
             
          
          </div>
        );
      }
    },
  
  
  ];



  const [form, setForm] = useState({
    music_preference: "",
    event_preference: "",
    music_platform: "",
    outing_frequency: "",
    communication_preference: "",
    gender: "",
    age_group: "",
    fromDate: null,
    endDate: null
  });

  useEffect(() => {
    form.fromDate = selectedDate;
  }, [selectedDate]);

  useEffect(() => {
    form.endDate = selectedDate2;
  }, [selectedDate2]);







  const getCustomerbyFilter = async () =>{

    const formData = new FormData();

    for (const key in form) {
      formData.append(key, form[key]);   
    }

    const result = await axiosPrivate.post("/customers/filter", formData);
    
    setCustomerData(result.data);
  }

  const getCustomerSurveybyFilter = async () =>{

    const formData = new FormData();


    for (const key in form) {
      formData.append(key, form[key]);   
    }

    const result = await axiosPrivate.post(`/customers/filterSurvey/${userId}`, formData);
    
    setCustomerData(result.data);
  }

  const handleSubmit = async () =>{

    if(
        form.music_preference.length === 0  &&
        form.event_preference.length === 0  &&
        form.music_platform.length === 0  &&
        form.outing_frequency.length === 0  &&
        form.communication_preference.length === 0  &&
        form.gender.length === 0  &&
        form.age_group.length === 0 &&
        form.fromDate === null &&
        form.endDate === null 


    ){
        setMandetoryInfo(true);
    }
    else{

      if(userRole === 'outlet'){
        getCustomerSurveybyFilter();
      }else{
        await getCustomerbyFilter();
      }

        


        setOpen(false);
    }
  }


  useEffect(()=>{
    let isMounted = true;

    const getCustomers = async () =>{
        const result = await axiosPrivate.get("/customers");
        setCustomerData(result.data);
    }

    const getCustomersbyOutlet = async () =>{
      let outletId  = localStorage.getItem("userId");
      const result = await axiosPrivate.get(`/customers/customerbyOutlet/${outletId}`);
      setCustomerData(result.data);
  }



    if(userRole === 'outlet'){
      isMounted && getCustomersbyOutlet();

    }else{
      isMounted && getCustomers();
    }
   
    

    return()=>{
        isMounted = false;
    }
  }, [userRole]);

  useEffect(()=>{
    const rows = customerData.map(customer => ({
        id: customer.customer_id,
        first_name: customer.customer_first_name,
        last_name: customer.customer_last_name,
        email: customer.email,
        phone: customer.phone,
        gender: customer.gender,
        age_group: customer.age_group,
        registration_date: new Date(customer.createdAt).getDate()+'/'+(new Date(customer.createdAt).getMonth()+1)+'/'+new Date(customer.createdAt).getFullYear(),
        authentication_status: customer.authentication_status,
        category: customer.self_registered? 'System' : customer.surveyed_outletName,
        membership_plan: customer.membership_plan ?  customer.membership_plan : 'Normal',
        music_preference: customer.music_preference,
        event_preference: customer.event_preference,
        outing_frequency: customer.outing_frequency,
        communication_channels: customer.communication_preference,
      
      }));

      setRow(rows);
  }, [customerData]);


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  }

  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  }

  return (
    <div className="parent">
      <div className="mainsection ">
        <div className="container-fluid text-center ">
          <div className="roas_section p-3" id="pos">
            <div className="flex cursor-pointer mb-3" onClick={handleOpen}>

              <div className="flex align-items-center">
                <p
                  className="text-center text-light   font-semibold"
                  style={{ fontSize: "20px" }}
                >
                  FILTER CUSTOMER
                </p>

                <FilterAltIcon className="ms-2"  />
              </div>


            </div>

            <Box
              sx={{
                height: "auto",
                width: "100%",
              }}
            >
              <DataGrid
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  authentication_status: userRole==='outlet'?false:true,
                  category: userRole==='outlet'?false:true,
                  controls: userRole==='outlet'?false:true,
                  membership_plan: userRole==='outlet'?false:true,
                  music_preference: userRole==='outlet'?true:false,
                  event_preference: userRole==='outlet'?true:false,
                  outing_frequency: userRole==='outlet'?true:false,
                  communication_channels: userRole==='outlet'?true:false,
                }}



                rows={row}
                columns={columns}
                sx={{ backgroundColor: "white", fontSize: "17px" }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick

               
              
                components={{
                  Toolbar: () => (
                    
                    
                    <div>
                      {(userRole === "superadmin" || userRole === "outlet") &&
                       <GridToolbar />
                     }
                    </div>
                    
                   
                  ),
                }}
                

                
              />
            </Box>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="mb-2" style={{ fontSize: "17px" }}>
            Select By Category
          </h1>

          <div className=" p-3 flex justify-normal flex-wrap">
            <div className="mb-4 me-4" style={{ width: "120px" }}>
              <label for="exampleFormControlInput1" className="form-label mb-2">
                AGE GROUP
              </label>
              <select
                name="age_group"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.age_group}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="16-20">16-20</option>
                <option value="21-25">21-25</option>
                <option value="26-30">26-30</option>
                <option value="31-35">31-35</option>
                <option value="36-40">36-40</option>
                <option value="40+">40+</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "120px" }}>
              <label for="exampleFormControlInput1" className="form-label mb-2">
                GENDER
              </label>
              <select
                name="gender"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.gender}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="Male">MALE</option>
                <option value="Female">FEMALE</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label
                
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
              >
                MUSIC PREFERENCE
              </label>
              <select
                name="music_preference"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.music_preference}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="BOLLYWOOD (हिंदी)">BOLLYWOOD (हिंदी)</option>
                <option value="EDM">EDM</option>
                <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
                <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
                <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
                <option value="DEEP/HOUSE">DEEP/HOUSE</option>
                <option value="CHILL/HOUSE">CHILL/HOUSE</option>
                <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
                <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
                <option value="KANNADA (ಕನ್ನಡ)">KANNADA (ಕನ್ನಡ)</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label
                
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
                value={form.event_preference}
              >
                EVENT PREFERENCE
              </label>
              <select
                name="event_preference"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="LADIES NIGHT">LADIES NIGHT</option>
                <option value="BRUNCH">BRUNCH</option>
                <option value="FRIDAY NIGHT LIVE">FRIDAY NIGHT LIVE</option>
                <option value="SATURDAY NIGHT LIVE">SATURDAY NIGHT LIVE</option>
                <option value="SUNDAY NIGHT LIVE">SUNDAY NIGHT LIVE</option>
                <option value="COSTUME & THEMES">COSTUME & THEMES</option>
                <option value="CONCEPTS">CONCEPTS</option>
                <option value="HAPPY HOURS">HAPPY HOURS</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label
                
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
              >
                RADIO PLATFORM
              </label>
              <select
                name="music_platform"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.music_platform}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="APPLE ITUNES">APPLE ITUNES</option>
                <option value="SPOTIFY">SPOTIFY</option>
                <option value="JIO SAAVAN">JIO SAAVAN</option>
                <option value="AMAZON MUSIC">AMAZON MUSIC</option>
                <option value="SOUNDCLOUD">SOUNDCLOUD</option>
                <option value="YOUTUBE MUSIC">YOUTUBE MUSIC</option>
                <option value="BEATPORT">BEATPORT</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label
                
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
              >
                OUTING FREQUENCY
              </label>
              <select
                name="outing_frequency"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.outing_frequency}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="ONCE EVERY WEEK">ONCE EVERY WEEK</option>
                <option value="ONCE EVERY TWO WEEK">ONCE EVERY TWO WEEK</option>
                <option value="ONCE EVERY MONTH">ONCE EVERY MONTH</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label for="exampleFormControlInput1" className="form-label mb-2">
                COMMUNICATION PREFERENCE
              </label>
              <select
                name="communication_preference"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.communication_preference}
              >
                <option value="">Select</option>
                <option value="EMAIL">EMAIL</option>
                <option value="WHATSAPP">WHATSAPP</option>
                <option value="MESSAGES">MESSAGES</option>
              </select>
            </div>

            






          </div>

          <div >

          <h1 className="mb-2" style={{ fontSize: "17px" }}>
            Select By Date
          </h1>

            <div className="flex p-3 flex-wrap">
            <div className="mb-4 me-4" style={{ width: "200px" }}>
                <label
                  
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                 From Date
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mb-4 me-4" style={{ width: "200px" }}>
                <label
                  
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                 To Date
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate2}
                      onChange={handleDateChange2}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                      minDate={selectedDate}
                    //   maxDate={today}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
         
          </div>

          {mandetoryInfo &&
           <h1 className="text-danger ms-3">Please Select An Option To Progress</h1>
          }

         

          <div className="flex justify-content-end pe-1">
            <Button 
                onClick={handleSubmit}
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              SUBMIT
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomerFilter;
