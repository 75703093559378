import React, { useState } from "react";
import axios from "../api/axios";
import "./Outlets.css";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { axiosPrivate } from "../api/axios";

function OutletsInsights() {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    outlet_icon: null,
    intro_video: null,
    opening_time: "12:00 PM",
    closing_time: "01:00 AM",
    outlet_category: "NIGHTCLUB",
    website: "",
    address: "",
    map: "",
    facebook_url: "",
    instragram_url: "",
    description: "",
    google_url: "",
    outlet_profile_rules_and_regulation:
      "1. Rights To Admission Reserved\n2. Cover Charges Applicable at Gate Post 8 PM\n3. 21+ /Physical ID Mandatory\n4. Club Rules Applied\n5. Cover Charges Redeemable Inside",
  });

  const handleChange = (e) => {
  
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedVideoTypes = ["video/mp4", "video/webm"];

    if (e.target.type === "file") {
      if (
        allowedTypes.includes(e.target.files[0].type) ||
        allowedVideoTypes.includes(e.target.files[0].type)
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.files[0],
        });
      } else {
        alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
      }
    }
    else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };


  useEffect(() => {
    let isMounted = true;

    const getOutletData = async () => {
      let outletId = localStorage.getItem("userId");

      const response = await axiosPrivate.get(`/outlets/${outletId}`);

      let outlet_data = response.data;
      console.log("outlet data------->", outlet_data)
      const opening_time = new Date(outlet_data.opening_time);
      console.log("open------->", opening_time)
      const event_end_time = new Date(outlet_data.opening_time);

      const starting_day = String(opening_time.getDate()).padStart(2, '0');
      const starting_hours = String(opening_time.getHours()).padStart(2, "0");
      const starting_minutes = String(opening_time.getMinutes()).padStart(
        2,
        "0"
      );
      const starting_seconds = String(opening_time.getSeconds()).padStart(
        2,
        "0"
      );

      const ending_hours = String(event_end_time.getHours()).padStart(2, '0');
      const ending_minutes = String(event_end_time.getMinutes()).padStart(2, '0');
      const ending_seconds = String(event_end_time.getSeconds()).padStart(2, '0');

      setForm({
        opening_time: outlet_data.opening_time,
        closing_time: outlet_data.closing_time,
        outlet_category: outlet_data.outlet_category,
        website: outlet_data.website,
        address: outlet_data.address,
        map: outlet_data.map,
        facebook_url: outlet_data.facebook_url,
        instragram_url: outlet_data.instragram_url,
        description: outlet_data.description,
        google_url: outlet_data.google_url,
        outlet_profile_rules_and_regulation:
          outlet_data.outlet_profile_rules_and_regulation,
      });
    };

    isMounted && getOutletData();

    return () => {
      isMounted = false;
    };
  }, []);

  const resetForm = () => {
    // Define your initial form state or an empty object
    const initialFormState = {
      outlet_icon: null,
      intro_video: null,
      opening_time: "01:00 PM",
      closing_time: "01:00 AM",
      outlet_category: "NIGHTCLUB",
      website: "",
      address: "",
      map: "",
      facebook_url: "",
      instragram_url: "",
      description: "",
      google_url: "",
      outlet_profile_rules_and_regulation:
        "1. Rights To Admission Reserved\n2. Cover Charges Applicable at Gate Post 8 PM\n3. 21+ /Physical ID Mandatory\n4. Club Rules Applied\n5. Cover Charges Redeemable Inside",
    };

    // Update the state to reset the form
    setForm(initialFormState);
  };

  const submitServey = async () => {
    try {
      const userId = await localStorage.getItem("userId");
      const formData = new FormData();

      for (const key in form) {
        formData.append(key, form[key]);
        console.log("key->",key)
        console.log("from-->",form)

      }
      console.log("userid-->",userId)
      console.log("formdata->", formData)
      const response = await axios.patch(
        `/outlets/insights/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
     


      if (response.status === 200) {
        alert("Insights updated successfully");
        // Assuming you have the `navigate` function from your router
        resetForm();
        navigate("/outletProfile");
        
      } else {
        alert("Failed to update insights");
      }
    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while updating insights");
    }
  };

  return (
    <div className="layout ">
      <div
        className="mx-auto mt-40 mb-5"
        style={{ height: "500px", width: "500px" }}
      >
        <h1
          className="text-center"
          style={{
            fontSize: "30px",
            fontWeight: "500",
            color: "white",
            letterSpacing: "1px",
          }}
        >
          OUTLET INSIGHTS
        </h1>

        <div className="mt-20 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            OUTLET ICON
          </label>
          <input
            type="file"
            name="outlet_icon"
            accept=".png, .jpg, .jpeg"
            onChange={handleChange}
            className="form-control"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            OUTLET INTRO VIDEO
          </label>
          <input
            type="file"
            name="intro_video"
            accept="video/*"
            onChange={handleChange}
            className="form-control"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
          />
        </div>

        <div className="row g-3 mt-3 mx-1">
          <div className="col">
            <label
              style={{ color: "white" }}

              for="time1"
              className="form-label mb-2"
            >
              OPENING TIME
            </label>
            <input
              type="time"
              id="time1"
              name="opening_time"
              onChange={handleChange}
              className="form-control"
              value={form.opening_time}
            />
          </div>
          <div className="col">
            <label
              style={{ color: "white" }}

              for="time2"
              className="form-label mb-2"
            >
              CLOSING TIME
            </label>
            <input
              type="time"
              id="time2"
              name="closing_time"
              onChange={handleChange}
              className="form-control"
              value={form.closing_time}
            />
          </div>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            DESCRIBE YOUR OUTLET CATEGORY
          </label>
          <select
            name="outlet_category"
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
            value={form.outlet_category}
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="NIGHTCLUB">NIGHTCLUB</option>
            <option value="LOUNGE">LOUNGE</option>
            <option value="ROOFTOP CAFE AND BAR">ROOFTOP CAFE AND BAR</option>
            <option value="RESTO CAFE AND BAR">RESTO CAFE AND BAR</option>
            <option value="PUB & BREWERY">PUB & BREWERY</option>
            <option value="OPEN AIR">OPEN AIR</option>
            <option value="POOLSIDE">POOLSIDE</option>
            <option value="CONCERT HALL">CONCERT HALL</option>
          </select>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            OUTLET ADDRESS
          </label>
          <textarea
            maxLength={250}
            value={form.address}
            className="form-control"
            name="address"
            onChange={handleChange}
            placeholder="Enter your outlet address"
            id="floatingTextarea2"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            LOCATION
          </label>
          <input
            type="text"
            value={form.map}
            name="map"
            placeholder="Google Map"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            Facebook
          </label>
          <input
            type="text"
            value={form.facebook_url}
            name="facebook_url"
            placeholder="Enter your facebook url"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            Instagram
          </label>
          <input
            type="text"
            value={form.instragram_url}
            name="instragram_url"
            placeholder="Enter your instagram url"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            Google Business
          </label>
          <input
            type="text"
            value={form.google_url}
            name="google_url"
            placeholder="Enter your Google Business url"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            Website
          </label>
          <input
            type="text"
            value={form.website}
            name="website"
            placeholder="Enter your Website url"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            SHARE YOUR OUTLET DISCOGRAPHY
          </label>
          <textarea
            maxLength={250}
            value={form.description}
            className="form-control"
            name="description"
            onChange={handleChange}
            placeholder="Description about your outlet"
            id="floatingTextarea2"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            DISCLAIMER
          </label>
          <textarea
            maxLength={250}
            value={form.outlet_profile_rules_and_regulation}
            className="form-control"
            name="outlet_profile_rules_and_regulation"
            onChange={handleChange}
            placeholder="Rules & Regulations about your outlet"
            id="floatingTextarea3"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div
          onClick={submitServey}
          className="mx-2 mt-4 flex align-items-center justify-content-center btnstyles"
        >
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            SUBMIT
          </h1>
        </div>
      </div>
    </div>
  );
}

export default OutletsInsights;
