import React from "react";
import "./Outlets.css";

import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import axios from "../api/axios";
import { ElevatorSharp } from "@mui/icons-material";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAuth from "../hooks/useAuth";

import CircularProgress from '@mui/material/CircularProgress';

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import { useRole } from "../context/UserContext";

const REGISTER_URL = "/user/register";


function UserRegister() {
  const { role } = useParams();
  const { setRoleValue } = useRole();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const [admins, setAdmins] = useState([]);

  const { setAuth } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const maxDate = dayjs().subtract(16, "years");

  const [sentOtp, setSentOtp] = useState();
  const [receivedOtp, setReceivedOtp] = useState();

  const [loading, setLoading] = useState(false);

  const handleloadingOpen = () => setLoading(true);
  const handleloadingClose = () => setLoading(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isOtpModelOpen, setIsOtpModelOpen] = React.useState(false);

  const handleOtpModelOpen = () => setIsOtpModelOpen(true);
  const handleOtpModelClose = () => setIsOtpModelOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "rgba(0,0,0,0.5)",
    boxShadow: 24,
    color: 'white',
    p: 4,
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const navigate = useNavigate();

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    userId: "",
    phone: "",
    email: "",
    password: "",
    password2: "",
    dob: "",
    gender: "",
    role: "",
    age_group: "",
  });

  useEffect(() => {
    form.dob = selectedDate;
  }, [selectedDate]);



  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [docCount, setDocCount] = useState({});

  const [customerID, setCustomerID] = useState(() => {
    // Initialize from localStorage or default to 1
    return parseInt(localStorage.getItem("customerID")) || 1;
  });

  const [artistID, setArtistID] = useState(() => {
    // Initialize from localStorage or default to 1
    return parseInt(localStorage.getItem("artistID")) || 1;
  });

  useEffect(() => {
    let isMounted = true;

    const getAdmins = async () => {
      try {
        const response = await axios.get(`/user/admins`, {
          // signal: controller.signal
        });
        setAdmins(response.data);
        
      } catch (err) {
        console.error(err);
      }
    };

    const getDocCount = async () => {
      try {
        const response = await axios.get(`/documents`, {
          // signal: controller.signal
        });
        setDocCount(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getDocCount();
    isMounted && getAdmins();

    return () => {
      isMounted = false;
      // controller.abort();
    };
  }, []);

  const paddingLength = 10;

  const handleRegister = ()=>{
    handleSendOTP();
  }

  const handleSubmit2 = async () => {
    try {
      const response = await axios.post(REGISTER_URL, JSON.stringify(form), {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      


      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;
      const userName = response?.data?.userName;
      const email = response?.data?.email;
      localStorage.setItem("refreshToken", refreshToken);

      const userId = response?.data?.customer_id;
      const userRole = response?.data?.role;
      localStorage.setItem("userId", userId);
      localStorage.setItem("userName", userName);

      setRoleValue(form.role);

      setAuth({ userId, email, accessToken, userName, userRole });
      
      sendmessges();
      sendWelcomeMessage();
      

      
    } catch (err) {
      handleClose();
      if (!err?.response) {
        alert("No Server Response");
      } else if (err.response?.status === 400) {
        alert("All fields are required");
      } else if (err.response?.status === 401) {
        alert("User Already Exists");
      } else {
        alert("Registration Failed");
      }
    }
  };

  function handlePassword(password, password2) {
    // Regular expressions to check for uppercase, lowercase, and special characters
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Check length
    if (password.length < 8) {
        alert("Password should be minimum of 8 characters");
        return false;
    }

    // Check for uppercase letter
    if (!uppercaseRegex.test(password)) {
        alert("Password should contain at least one uppercase letter");
        return false;
    }

    // Check for lowercase letter
    if (!lowercaseRegex.test(password)) {
        alert("Password should contain at least one lowercase letter");
        return false;
    }

    // Check for special character
    if (!specialRegex.test(password)) {
        alert("Password should contain at least one special character");
        return false;
    }

    if (password !== password2) {
      alert("Passwords do not match");
      return false;
  }

    // If all conditions are met
    return true;
}

  const handleSubmit = async () => {
    try {

      setOpen(false);
      if (!form.first_name || !form.email || !form.phone || !form.password || !form.password2) {
        alert("please select all fields");
        ;
      }

      else {

        if (handlePassword(form.password, form.password2)) {
          if (role === "artist") {
            form.userId = `NA${String(docCount.totalArtists + 1).padStart(
              paddingLength,
              "0"
            )}`;
          } else if (role === "outlet") {
            form.userId = `NO${String(docCount.totalOutlets + 1).padStart(
              paddingLength,
              "0"
            )}`;
          } else {
            form.userId = `NC${String(docCount.totalCustomers + 1).padStart(
              paddingLength,
              "0"
            )}`;
          }
  
          form.role = role;
         
  
       
          setOpen(true);




        } 


       
       
      }
    } catch (err) {
      if (!err?.response) {
        alert("No Seerver Rewsponse");
      }
    }
  };

  const handleOTP = async () =>{
    const receivedOtpNumber = Number(receivedOtp);
  
    
    if(sentOtp === receivedOtpNumber){
        setReceivedOtp(0);
        handleSubmit2();
        


    }else{
      alert("OTP Invalid, Please try again ");
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      let today = new Date();
      let birthDate = new Date(date);
      let age = today.getFullYear() - birthDate.getFullYear();
      let monthDifference = today.getMonth() - birthDate.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      let calculatedAgeGroup = "";
      if (age >= 16 && age <= 20) {
        calculatedAgeGroup = "16-20";
      } else if (age >= 21 && age <= 25) {
        calculatedAgeGroup = "21-25";
      } else if (age >= 26 && age <= 30) {
        calculatedAgeGroup = "26-30";
      } else if (age >= 31 && age <= 35) {
        calculatedAgeGroup = "31-35";
      } else if (age >= 36 && age <= 40) {
        calculatedAgeGroup = "36-40";
      } else {
        calculatedAgeGroup = "40+";
      }
      setForm({ ...form, age_group: calculatedAgeGroup });
    }
  };

  const sendmessges = async () => {
    try { 
        
        const response = await axios.post(
          `/inbox`,
          {
            "message_receivers": JSON.stringify(

              role==='outlet'?

              admins
              .filter(admin => admin.role !== "admin1")
              .map(admin => ({
                'id': admin.userId,
                'email_id': admin.email,
                'name': admin.first_name + ' ' + admin.last_name
              }))
              :

              admins
              .filter(admin => admin.role !== "admin2")
              .map(admin => ({
                'id': admin.userId,
                'email_id': admin.email,
                'name': admin.first_name + ' ' + admin.last_name
              }))
              
              
              ),
              "subject":  role==='outlet'?"OUTLET ADDED":role==='artist'?"ARTIST ADDED":"CUSTOMER ADDED",
              "message": `New ${role==='outlet'?"Outlet":role==='artist'?"Artist":"Customer"} has been successfully registered in the system.\n\n Recorded Details:\n
                          Name: ${form.first_name +' '+form.last_name} 
                          Email: ${form.email} 
                          Contact: ${form.phone}`,
              "category": role==='outlet'?"Outlet":role==='artist'?"Artist":"Customer"

          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        

       
      
    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };

  const sendWelcomeMessage = async () => {
    setLoading(true);
    const response = await axios.post('/user/sendWelcomeMessage', {
       email: form.email ,
       name: form.first_name + ' '+ form.last_name
    });
    if (response.status === 200) {
      
      handleloadingClose();
      handleClose();
      alert("Registered Successfully");
      if (form.role === "user") {
        const response2  = await axios.patch(
          `/documents/totalCustomers`
        );
        navigate("/user-servey");
      } else if (form.role === "artist") {
        const response2  = await axios.patch(
          `/documents/totalArtists`
        );
        navigate("/artist-insights");
      } else {
        const response2  = await axios.patch(
          `/documents/totalOutlets`
        );
        navigate("/outlet-insights");
      }
      
      
    }else{
      handleloadingClose();
      alert('We are sorry for the inconvenience, please check your email and try again.');
    }
  };

  const handleSendOTP = async () => {
    await sendOTPToBackend(form.email);
  };

  const sendOTPToBackend = async (email) => {
    setLoading(true);

    const response = await axios.post('/user/sendOtp', {
       email: email 
    });
    if (response.status === 200) {
      handleloadingClose();
      const otp = await response.data;
 
      alert('OTP has been sent to your email.');
      setSentOtp(otp);
      handleOtpModelOpen();
      
    }else{
      alert('Network Error.');
    }
  };


  return (
    <React.Fragment>
      <div className="layout">
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            Terms And Conditions
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {role === "outlet" ? (
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;BY REGISTERING AND PARTICIPATING ON
                  THIS SOFTWARE, YOU DECLARE THAT YOU AS AN “OUTLET” MANAGE AN
                  OPERATING BUSINESS AS IN NIGHTCLUB, BAR, RESTO CAFÉ AND OTHER
                  CATEGORIZED ON THE WEBSITE AND THAT YOU ARE ON A BUSINESS
                  RELATIONSHIP WITH NEROLIFE NETWORKS PVT. LTD. OR ALIAS OF
                  NEROLIFE GROUP OF COMPANIES AND HENCE YOU MEET THE MINIMUM
                  REQUIREMENTS LAID OUT IN THE TERMS & CONDITIONS TO REGISTER
                  AND PARTICIPATE. THIS WEB BASED SOFTWARE AND IT’S
                  REPRESENTATION “AS IS” ARE REAL IN NATURE. THE DATA, INFO
                  GRAPHICS, FOUND ON THIS CRM ARE ACTUAL STATISTICS COLLECTED OR
                  DISTRIBUTED ON REAL TIME AS REPRESENTED.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;NIGHTCUBE SYSTEMS SAAS © NEROLIFE
                  NETWORKS IS PRIVATELY OWNED AND OPERATED BY NEROLIFE NETWORKS
                  PVT. LTD. AND MAY CONTAIN ANALYTICS REPORTS, CUSTOMER
                  INFORMATION, ANALYTICAL GRAPHS, INDUSTRIAL HEAT MAPS OR OTHER
                  LINKS TO MONETIZE MARKET DYNAMICS.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp; NIGHTCUBE SYSTEMS SAAS © NEROLIFE
                  NETWORKS PVT. LTD. ABIDES BY WORD OF MOUTH MARKETING
                  STANDARDS. WE BELIEVE IN HONESTY OF RELATIONSHIP, OPINIONS AND
                  IDENTITY. THE INFORMATION SHOWCASED ON THIS PLATFORM MAY
                  INFLUENCE THE ADVERTISING CONTENT, TOPICS OR POSTS MADE IN
                  ORDER TO SUSTAIN A RELATIVE BUSINESS OUTCOME. THAT CONTENT,
                  ADVERTISING SPACE OR POST WILL BE CLEARLY IDENTIFIED AS PAID
                  OR SPONSORED CONTENT.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;NIGHTCUBE SYSTEMS SAAS © NEROLIFE
                  NETWORKS NEVER DIRECTLY COMPENSATE TO PROVIDE OPINION ON
                  PRODUCTS, EVENTS, SERVICES, WEBSITES AND VARIOUS OTHER 3RD
                  PARTY RELATIVE TOPICS. THE VIEWS AND OPINIONS EXPRESSED ON
                  THIS CRM PLATFORM ARE PURELY THOSE OF SEGREGATION & DATA
                  ANALYTICS. IF WE CLAIM OR APPEAR TO BE EXPERTS ON ANY CERTAIN
                  TOPIC OR PRODUCTS OR SERVICE AREA, WE WILL ONLY ENCOURAGE
                  SOLUTIONS OR SERVICES THAT WE BELIEVE IN, BASED ON OUR
                  EXPERTISE, OR ARE WORTHY OF SUCH ENDORSEMENT. ANY CLAIMS,
                  STATISTIC, ANALYSIS OR OTHER REPRESENTATION ABOUT A SOLUTION
                  OR SERVICE SHOULD BE VERIFIED WITH THE MARKET DYNAMICS OF
                  INDUSTRY STANDARD.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;THIS CRM DOES NOT CONTAIN ANY CONTENT
                  OR MATERIALS WHICH MIGHT PRESENT A CONFLICT OF INTEREST.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;NIGHTCUBE SYSTEMS SAAS © NEROLIFE
                  NETWORKS MAKES NO WARRANTIES, OR ASSURANCES TOWARDS THE
                  ACCURACY, STATISTICS OR COMPLETENESS OF CONTENTS OR MATERIALS
                  ON THIS CRM OR ANY SITES LINKED TO OR FROM THIS SITE.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;NEROLIFE NETWORKS, ITS EMPLOYEES,
                  AGENTS, EVENT PARTNERS, SUPPLIERS, ADVERTISERS AND SPONSORS
                  ARE NOT LIABLE AND BEAR NO RESPONSIBILITY FOR SPECIAL OR
                  CONSEQUENTIAL DAMAGE RESULTING FROM THE USE, MISUSE,
                  INTERPRETATION OR INABILITY TO USE THE CONTENTS OF THIS
                  WEBSITE AND UNDER NO CIRCUMSTANCES WE TAKE LIABILITIES
                  REGARDING ANY WARRANTIES IN RELATION TO THIS WEBSITE AND/OR
                  THE MATERIALS, CONTENTS AND INFORMATION PROVIDED ON THIS
                  WEBSITE.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;THIS WEBSITE DOES NOT IN ANY WAY
                  ENCOURAGE ADULTRY, VULGARITY, NUDITY, PROFANITY OR OBSCENITY
                  AND IS SOLELY INTENDED FOR ENTERTAINMENT AND INFOTAINMENT
                  PURPOSES. IF FOUND GUILTY, RESPECTIVE PERSON OR BUSINESS
                  ENTITY WILL BE COMPLETELY LIABLE FOR DAMAGES CAUSED TOWARDS
                  NEROLIFE NETWORKS PVT. LTD. AS PER REGULATORY ACTS UNDER
                  GOVERNMENT OF INDIA, AND AS PER SATISFACTION TO THE LEVEL OF
                  COMPENSATION PROVISIONAL UNDER NEROLIFE NETWORKS PVT. LTD.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;BY REGISTERING AND PARTICIPATING ON
                  THIS WEBSITE, YOU DECLARE THAT YOU AS AN “OUTLET” HAVE FULLY
                  UNDERSTOOD OUR TERMS & CONDITIONS, PRIVACY STATEMENT, COOKIE &
                  WEB BEACON POLICIES AND YOU ARE FULLY ABLE AND COMPETENT TO
                  ENTER INTO THE TERMS & CONDITIONS, OBLIGATIONS, AFFIRMATIONS
                  AND LEGAL REPRESENTATIONS AS GUIDED IN HERE.
                  <br />
                  <br />
                  <h2 className="">CONTACT : </h2>
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;IF YOU HAVE ANY QUESTIONS OR
                  QUERIES REGARDING FOLLOWING POLICY, OR YOUR ARRANGEMENTS WITH
                  OUR SOLUTIONS, CRM, SOFTWARE. WEBSITE, PLEASE WRITE US HERE:
                  <br />
                  <br />
                  <div className="m-2">
                    CEO :{" "}
                    <a style={{ color: "blue" }} href="mailto:ceo@nerolife.in">
                      CEO@NEROLIFE.IN
                    </a>
                  </div>
                  <div className="m-2">
                    ADMIN 2 :{" "}
                    <a
                      style={{ color: "blue" }}
                      href="mailto:uea@admin1@nerolife.in"
                    >
                      OPS.ADMIN2@NEROLIFE.IN
                    </a>
                  </div>
                  <div className="m-2">
                    GENERAL :{" "}
                    <a
                      style={{ color: "blue" }}
                      href="mailto:nerolifeexclusivenetworks@gmail.com"
                    >
                      NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM
                    </a>
                  </div>
                  <div className="m-2 flex align-items-center">
                    WELCOME TO{" "}
                    <span className="ms-1 me-1 font-bold">NEROLIFE</span>{" "}
                    <span className="thoughtsAppliedstyles">
                      THOUGHTS APPLIED
                    </span>
                  </div>
                </div>
              ) : role === "artist" ? (
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;BY REGISTERING AND PARTICIPATING ON
                  THIS WEBSITE, YOU DECLARE THAT YOU ARE AT LEAST 18+ (YEARS OF
                  AGE) OR OLDER AND IS LEGALLY RESPONSIBLE TO ENTER THIS WEBSITE
                  THIS WEBSITE IS NOT INTENDED TOWARDS CHILDREN OR MINORS BELOW
                  18 YEARS OLD. IN ADDITION, THOSE WHO WISH TO REGISTER AND
                  PARTICIPATE MUST MEET THE MINIMUM REQUIREMENTS LAID OUT IN THE
                  TERMS & CONDITIONS SECTION OF USE.
                  <br />
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;THIS WEBSITE AND IT’S
                  REPRESENTATION “AS IS” ARE REAL IN NATURE. THE ENTITIES,
                  ARTISTS, OUTLETS, EVENTS, AND USERS FOUND ON THIS WEBSITE ARE
                  ACTUAL PERSONS IN EXISTENCE, ACTIVE BUSINESSES AND EVENTS
                  CONDUCTED ON REAL TIME AS REPRESENTED. THIS WEBSITE DOES NOT
                  IN ANY WAY ENCOURAGE ADULTRY, VULGARITY OR OBSCENITY AND IS
                  SOLELY INTENDED FOR ENTERTAINMENT AND INFOTAINMENT PURPOSES.
                  <br />
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;THIS WEBSITE DOES NOT IN ANY WAY
                  ENCOURAGE ADULTRY, VULGARITY, NUDITY, PROFANITY OR OBSCENITY
                  AND IS SOLELY INTENDED FOR ENTERTAINMENT AND INFOTAINMENT
                  PURPOSES. IF FOUND GUILTY, RESPECTIVE PERSON OR BUSINESS
                  ENTITY WILL BE COMPLETELY LIABLE FOR DAMAGES CAUSED TOWARDS
                  NEROLIFE NETWORKS PVT. LTD. AS PER REGULATORY ACTS UNDER
                  GOVERNMENT OF INDIA, AND AS PER SATISFACTION TO THE LEVEL OF
                  COMPENSATION PROVISIONAL UNDER NEROLIFE NETWORKS PVT. LTD.
                  <br />
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; BY REGISTERING AND PARTICIPATING
                  ON THIS WEBSITE, YOU DECLARE THAT YOU AS AN “ARTIST” HAVE
                  FULLY UNDERSTOOD OUR TERMS & CONDITIONS, PRIVACY STATEMENT,
                  COOKIE & WEB BEACON POLICIES AND YOU ARE FULLY ABLE AND
                  COMPETENT TO ENTER INTO THE TERMS & CONDITIONS, OBLIGATIONS,
                  AFFIRMATIONS AND LEGAL REPRESENTATIONS AS GUIDED IN HERE.
                  <br />
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;NEROLIFE NETWORKS, ITS EMPLOYEES,
                  AGENTS, EVENT PARTNERS, SUPPLIERS, ADVERTISERS AND SPONSORS
                  ARE NOT LIABLE AND BEAR NO RESPONSIBILITY FOR SPECIAL OR
                  CONSEQUENTIAL DAMAGE RESULTING FROM THE USE, MISUSE,
                  INTERPRETATION OR INABILITY TO USE THE CONTENTS OF THIS
                  WEBSITE AND UNDER NO CIRCUMSTANCES WE TAKE LIABILITIES
                  REGARDING ANY WARRANTIES IN RELATION TO THIS WEBSITE AND/OR
                  THE MATERIALS, CONTENTS AND INFORMATION PROVIDED ON THIS
                  WEBSITE.
                  <h2 className="m-3">CONTACT : </h2>
                  &nbsp; &nbsp; &nbsp; &nbsp;IF YOU HAVE ANY QUESTIONS OR
                  QUERIES REGARDING FOLLOWING POLICY, OR YOUR ARRANGEMENTS WITH
                  OUR SOLUTIONS, CRM, SOFTWARE. WEBSITE, PLEASE WRITE US HERE:
                  <br />
                  <br />
                  <div className="m-2">
                    CEO :{" "}
                    <a style={{ color: "blue" }} href="mailto:ceo@nerolife.in">
                      CEO@NEROLIFE.IN
                    </a>
                  </div>
                  <div className="m-2">
                    ADMIN 1 :{" "}
                    <a
                      style={{ color: "blue" }}
                      href="mailto:uea@admin1@nerolife.in"
                    >
                      UEA.ADMIN1@NEROLIFE.IN
                    </a>
                  </div>
                  <div className="m-2">
                    GENERAL :{" "}
                    <a
                      style={{ color: "blue" }}
                      href="mailto:nerolifeexclusivenetworks@gmail.com"
                    >
                      NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM
                    </a>
                  </div>
                  <div className="m-2 flex align-items-center">
                    WELCOME TO{" "}
                    <span className="ms-1 me-1 font-bold">NEROLIFE</span>{" "}
                    <span className="thoughtsAppliedstyles">
                      THOUGHTS APPLIED
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  &nbsp; &nbsp; &nbsp; &nbsp;BY REGISTERING AND PARTICIPATING ON
                  THIS WEBSITE, YOU DECLARE THAT YOU ARE AT LEAST 18+ (YEARS OF
                  AGE) OR OLDER AND IS LEGALLY RESPONSIBLE TO ENTER THIS WEBSITE
                  THIS WEBSITE IS NOT INTENDED TOWARDS CHILDREN OR MINORS BELOW
                  18 YEARS OLD. IN ADDITION, THOSE WHO WISH TO REGISTER AND
                  PARTICIPATE MUST MEET THE MINIMUM REQUIREMENTS LAID OUT IN THE
                  TERMS & CONDITIONS SECTION OF USE.
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;THIS WEBSITE AND IT’S
                  REPRESENTATION “AS IS” ARE REAL IN NATURE. THE ENTITIES,
                  ARTISTS, OUTLETS, EVENTS, AND USERS FOUND ON THIS WEBSITE ARE
                  ACTUAL PERSONS IN EXISTENCE, ACTIVE BUSINESSES AND EVENTS
                  CONDUCTED ON REAL TIME AS REPRESENTED. THIS WEBSITE DOES NOT
                  IN ANY WAY ENCOURAGE ADULTRY, VULGARITY OR OBSCENITY AND IS
                  SOLELY INTENDED FOR ENTERTAINMENT AND INFOTAINMENT PURPOSES.
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;THIS WEBSITE DOES NOT IN ANY WAY
                  ENCOURAGE ADULTRY, VULGARITY, NUDITY, PROFANITY OR OBSCENITY
                  AND IS SOLELY INTENDED FOR ENTERTAINMENT AND INFOTAINMENT
                  PURPOSES. IF FOUND GUILTY, RESPECTIVE PERSON OR BUSINESS
                  ENTITY WILL BE COMPLETELY LIABLE FOR DAMAGES CAUSED TOWARDS
                  NEROLIFE NETWORKS PVT. LTD. AS PER REGULATORY ACTS UNDER
                  GOVERNMENT OF INDIA, AND AS PER SATISFACTION TO THE LEVEL OF
                  COMPENSATION PROVISIONAL UNDER NEROLIFE NETWORKS PVT. LTD.
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;BY REGISTERING AND PARTICIPATING ON
                  THIS WEBSITE, YOU DECLARE THAT YOU AS AN “INDIVIDUAL” HAVE
                  FULLY UNDERSTOOD OUR TERMS & CONDITIONS, PRIVACY STATEMENT,
                  COOKIE & WEB BEACON POLICIES AND YOU ARE FULLY ABLE AND
                  COMPETENT TO ENTER INTO THE TERMS & CONDITIONS, OBLIGATIONS,
                  AFFIRMATIONS AND LEGAL REPRESENTATIONS AS GUIDED IN HERE.
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp;NEROLIFE NETWORKS, ITS EMPLOYEES,
                  AGENTS, EVENT PARTNERS, SUPPLIERS, ADVERTISERS AND SPONSORS
                  ARE NOT LIABLE AND BEAR NO RESPONSIBILITY FOR SPECIAL OR
                  CONSEQUENTIAL DAMAGE RESULTING FROM THE USE, MISUSE,
                  INTERPRETATION OR INABILITY TO USE THE CONTENTS OF THIS
                  WEBSITE AND UNDER NO CIRCUMSTANCES WE TAKE LIABILITIES
                  REGARDING ANY WARRANTIES IN RELATION TO THIS WEBSITE AND/OR
                  THE MATERIALS, CONTENTS AND INFORMATION PROVIDED ON THIS
                  WEBSITE.
                  <br />
                  <h2 className="m-3">CONTACT : </h2>
                  &nbsp; &nbsp; &nbsp; &nbsp;IF YOU HAVE ANY QUESTIONS OR
                  QUERIES REGARDING FOLLOWING POLICY, OR YOUR ARRANGEMENTS WITH
                  OUR SOLUTIONS, CRM, SOFTWARE. WEBSITE, PLEASE WRITE US HERE:
                  <br />
                  <div className="m-2">
                    CEO :{" "}
                    <a style={{ color: "blue" }} href="mailto:ceo@nerolife.in">
                      CEO@NEROLIFE.IN
                    </a>
                  </div>
                  <div className="m-2">
                    ADMIN 1 :{" "}
                    <a
                      style={{ color: "blue" }}
                      href="mailto:uea@admin1@nerolife.in"
                    >
                      UEA.ADMIN1@NEROLIFE.IN
                    </a>
                  </div>
                  <div className="m-2">
                    GENERAL :{" "}
                    <a
                      style={{ color: "blue" }}
                      href="mailto:nerolifeexclusivenetworks@gmail.com"
                    >
                      NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM
                    </a>
                  </div>
                  <div className="m-2 flex align-items-center">
                    WELCOME TO{" "}
                    <span className="ms-1 me-1 font-bold">NEROLIFE</span>{" "}
                    <span className="thoughtsAppliedstyles">
                      THOUGHTS APPLIED
                    </span>
                  </div>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="flex align-items-center">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label>I accept the terms and conditions</label>
            </div>

            <Button
              disabled={!isChecked}
              style={{ backgroundColor: "#007bff", color: "white" }}
              onClick={handleRegister}
            >
              Register
            </Button>
          </DialogActions>
        </Dialog>

        <div className="m-auto" style={{ height: "500px", width: "500px" }}>
          <h1
            style={{
              fontSize: "30px",
              fontWeight: "400",
              color: "white",
              letterSpacing: "1px",
            }}
          >
            REGISTER
          </h1>
          {role === "outlet" ? (
            <div className="row g-3 mt-2 flex flex-wrap">
              <div className="col">
                <label
                  style={{ color: "white" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label mb-2"
                >
                  OUTLET NAME
                </label>
                <input
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Outlet Name"
                  required
                />
              </div>
            </div>
          ) : (
            <div className="row g-3 mt-2 flex flex-wrap">
              <div className="col">
                <label
                  style={{ color: "white" }}
                  htmlFor="exampleFormControlInput1"
                  className="form-label mb-2"
                >
                  FIRST NAME
                </label>
                <input
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter first name"
                  required
                />
              </div>
              <div className="col">
                <label
                  style={{ color: "white" }}
                  htmlFor="exampleFormControlInput2"
                  className="form-label mb-2"
                >
                  LAST NAME
                </label>
                <input
                  type="text"
                  name="last_name"
                  onChange={handleChange}
                  className="form-control"
                  id="exampleFormControlInput2"
                  placeholder="Enter last name"
                  required
                />
              </div>
            </div>
          )}

          <div className="row g-3 mt-4">
            <div className="col">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput3"
                className="form-label mb-2"
              >
                CONTACT
              </label>
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput3"
                placeholder="Enter your mobile number"
                required
              />
            </div>
            <div className="col">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput4"
                className="form-label mb-2"
              >
                EMAIL ADDRESS
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput4"
                placeholder="Enter email"
                required
              />
            </div>
          </div>

          {role !== "outlet" && (
            <div>
              <div className=" mt-4">
                <label
                  style={{ color: "white" }}
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                  DOB
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                      maxDate={maxDate} // Set the minimum date
                    />
                  </LocalizationProvider>
                </div>
                <p className="text-light mt-2" style={{ fontSize: "12px" }}>
                  * YOU HAVE TO BE MINIMUM 16 YEARS OR OLDER TO CREATE AN
                  ACCOUNT
                </p>
              </div>
              <div className=" mt-4">
                <label
                  style={{ color: "white" }}
                  htmlFor="exampleFormControlInput4"
                  className="form-label mb-2"
                >
                  GENDER
                </label>
                <div className="row px-3">
                  <div className="col   p-2" style={{ height: "50px" }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="gridRadios1"
                        value="Male"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label text-light"
                        for="gridRadios1"
                      >
                        MALE
                      </label>
                    </div>
                  </div>
                  <div className="col  p-2 " style={{ height: "50px" }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="gridRadios2"
                        value="Female"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label text-light"
                        for="gridRadios2"
                      >
                        FEMALE
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row  flex flex-wrap">
            <div className="row g-3 flex flex-wrap">
            <div className="col">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput5"
                className="form-label mb-2"
              >
                PASSWORD
              </label>
              <input
                type="password"
                name="password"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput5"
                placeholder="Password"
                required
              />
            </div>
            <div className="col">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput7"
                className="form-label mb-2"
              >
                CONFIRM PASSWORD
              </label>
              <input
                type="password"
                name="password2"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput7"
                placeholder="Confirm Password"
                required
              />
            </div>
            </div>
            <p className="text-light mt-2" style={{ fontSize: "12px" }}>
                * PASSWORD SHOULD BE MINIMUM 8 CHARACTERS IN COMBINATION OF CAPITAL, SMALL & SPECIAL CHARACTER
            </p>
          </div>

          <div
            onClick={handleSubmit}
            className="my-4 mt-5 flex align-items-center justify-content-center btnstyles"
          >
            <h1 className="text-white" style={{ fontSize: "20px" }}>
              REGISTER
            </h1>
          </div>
        </div>
      </div>

      <Modal
        open={isOtpModelOpen}
        onClose={handleOtpModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <h3 className="fs-3 mb-3" >Authenticate With OTP</h3>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
               Enter OTP:
              </label>
              <input
                type="number"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e)=>setReceivedOtp(e.target.value)}
                className="form-control"
                value={receivedOtp}
              />
            </div>
           
          

            
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleOTP}
              >
                Submit
              </Button>
            </div>
        </Box>
      </Modal>

      <Modal
        open={loading}
        onClose={handleloadingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className = "flex align-items-center" sx={style}>
                 
          <CircularProgress />

          <div>
            <p className="ms-2" style={{fontSize : '15px'}}>Registration in Progress ...</p>
          </div>
           
        </Box>
      </Modal>

    </React.Fragment>
  );
}

export default UserRegister;
