import React from "react";
import "./Outlets.css";

import event_not_found from "../assets/errors/no_event_listed.jpg";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { useEffect, useState } from "react";
import axios from "../api/axios";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BASE_URL } from "../constants";

const Allevents = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const musicCategory = state?.musicCategory;
  const outletId = state?.outletId;
  const artistId = state?.artistId;
  const { eventCategory } = params;
  const [allEvents, setAllEvents] = useState([]);
  const itemsPerPage = 16;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(allEvents.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentEvents = allEvents.slice(startIndex, endIndex);
  console.log("state->", state)
  console.log("music->", musicCategory)
  console.log("event category->", eventCategory)
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let isMounted = true;

    const getEventbyCategory = async () => {
      try {
        const response = await axios.get(`/events/${eventCategory}`, {});
        isMounted && setAllEvents(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    const getEventbyMusic = async (event_category) => {
      const convertedString = musicCategory?.replace(/[^a-zA-Z0-9/-]/g, '~').
        replace(/\//g, '_');
      console.log("string->", convertedString)
      try {
        const response = await axios.get(
          `events/${event_category}/${convertedString}`
        );
       
        isMounted && setAllEvents(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getEventbyOutletId = async (event_category) => {
      try {
        const response = await axios.get(
          `events/${event_category}/${outletId}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        isMounted && setAllEvents(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getEventbyArtistId = async (event_category) => {
      try {
        const response = await axios.get(
          `events/artist/${event_category}/${artistId}`
        );
        isMounted && setAllEvents(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    if (musicCategory && isMounted) {
      if (eventCategory === "upcoming_events") {
        getEventbyMusic("upcoming_eventsbymusic");
      } else if (eventCategory === "live_events") {
        getEventbyMusic("live_eventsbymusic");
      } else {
        getEventbyMusic("previous_eventsbymusic");
      }
    } else if (outletId && isMounted) {
      if (eventCategory === "upcoming_events") {
        getEventbyOutletId("upcoming_events");
      } else if (eventCategory === "live_events") {
        getEventbyOutletId("live_events");
      } else {
        getEventbyOutletId("previous_events");
      }
    } else if (artistId && isMounted) {
      if (eventCategory === "upcoming_events") {
        getEventbyArtistId("upcoming_events");
      } else if (eventCategory === "live_events") {
        getEventbyArtistId("live_events");
      } else {
        getEventbyArtistId("previous_events");
      }
    } else {
      isMounted && getEventbyCategory();
    }

    return () => {
      isMounted = false;
    };
  }, [eventCategory, musicCategory, outletId, artistId]);

  return (
    <div className="layout mb-5">
      <div className="mb-5  pb-10 ">
        <div className=" allevt_crds-main align-items-center justify-content-center">
          {currentEvents.length > 0 ? (
            currentEvents.map((event, index) => (
              <div
                onClick={() =>
                  navigate(
                    `/singleEvent/${event.event_id}/${event.event_name.replace(
                      / /g,
                      "_"
                    )}`
                  )
                }
                key={index}
                className="flex-column cursor-pointer align-items-center imagesinglecard allevent-crd flex-wrap"
              >
                <img
                  src={`${BASE_URL}${event.poster_url}`}
                  alt="one"
                  // style={{ height: "90%", width: "90%", objectFit: "cover" }}
                  className="all-evt-cards"
                />
                <div
                  style={{ height: "10%", width: "100%" }}
                  className="flex align-items-center  justify-center "
                >
                  <h1
                    className="mt-3 text-center font-semibold "
                    style={{ left: "50%", color: "white" }}
                  >
                    {event.event_name}
                  </h1>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-column  cursor-pointer imagesinglecard">
              <img
                src={event_not_found}
                alt="no event listed"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                className="eventimage"
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>

      {/* <div className="footer-overlay">
        <Footer />
      </div> */}
    </div>
  );
};

export default Allevents;
