import React from "react";

import "./Outlets.css";
import Footer from "../components/Footer";
import "./Outlets.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios, { axiosPrivate } from "../api/axios";

import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";

const PotraitExperience = () => {
  const { artist_id } = useParams();

  const [artistImages, setArtistImages] = useState([]);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex(
        (prevIndex) => (prevIndex + 1) % artistImages.length
      );
    }, 5000); // Change the interval (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [artistImages]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlideIndex((prevIndex) => (prevIndex + 1) % artistImages.length);
    }, 3000); // Adjust the interval according to your preference in milliseconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [artistImages.length]);

  useEffect(() => {
    let isMounted = true;

    const getartistImages = async () => {
      try {
        
        const response = await axiosPrivate.get(
          `images/portrait_images/${artist_id}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        isMounted && setArtistImages(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getartistImages();

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, []);

  return (
    <div className="layout mb-5">
      <div className="outletparent d-flex flex-wrap mb-5">
        <div className="outletleft d-flex align-items-center justify-content-center">
          <div>
            <h1 className="me-5 text-light fw-bold fs-3">PORTRAITS</h1>
          </div>
        </div>

        <div className="outletright">
          <Slider {...settings}>
            {artistImages?.map((artist, index) => (
              <div key={index} style={{ height: "500px", width: "500px" }}>
                <img
                  style={{
                    height: "500px",
                    width: "500px",
                    objectFit: "cover",
                  }}
                  src={artist.icon}
                  className="outletaccount"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className=" allevt_crds-main align-items-center justify-content-center flex-wrap">
        {artistImages.map((artist, index) => (
          <div
            key={index}
            className="flex-column  cursor-pointer align-items-center imagesinglecard allevent-crd"
            // style={{ height: "400px" }}
          >
            <img
              src={artist.icon}
              alt="one"
              // style={{ height: "90%", width: "300px" }}
              className="all-evt-cards"
              />
          </div>
        ))}
      </div>

      <div className="footer-overlay">
        <Footer />
      </div>
    </div>
  );
};

export default PotraitExperience;
