import React from "react";
import { useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useLocation} from "react-router-dom"
import "./Stories.css";

import { BASE_URL } from '../constants';


const Stories = () => {
  const [stories, setStories] = useState([]);
  const { state } = useLocation();
  const userRole = state?.userRole;
  const itemsPerPage = 5;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(stories.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentStories = stories.slice(startIndex, endIndex);
  const [storiesModal, setStoriesModal] = React.useState(false);
  const [picture, setPicture] = useState(null);
  const [storyId, setStoryId] = useState("");
  const [blog, setBlog] = useState("");
  const handleOpenstoriesModal = () => setStoriesModal(true);
  const handleClosestoriesModal = () => setStoriesModal(false);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "rgba(0,0,0,0.5)",
    boxShadow: 24,
    color: 'white',
    p: 4,
  };

  const getStories = async () =>{
    const result = await axiosPrivate.get("/stories");
    setStories(result.data);
  }

  useEffect(()=>{
    let isMounted = true;

    

   

    isMounted && getStories();

    return()=>{
      isMounted = false;
    }
  },[]);

  const handleEdit = (story) =>{
    setStoryId(story._id);
    setBlog(story.message);
    handleOpenstoriesModal();
  }

  const handleChangePicture = (e) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
 
    if (
      allowedTypes.includes(e.target.files[0].type) 
    ) {
      setPicture(e.target.files[0]);
    } else {
      alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
    }
  };

  const handleUpdateStory = async ()=>{
    try {
      
        const formData = new FormData();

        if(picture){
          formData.append("picture", picture);
        }

        if(blog){
          formData.append("message", blog);
        }

       
       

        const response = await axiosPrivate.put(
          `/stories/update/${storyId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        

        if (response.status === 200) {
          alert("Stories Updated Successfully");
          handleClosestoriesModal();
          getStories();
        } else {
          alert("Server Error");
        }
      
    } catch (error) {
      console.error("Error Updating Stories:", error.message);
      alert("An error occurred while Updating Stories");
    }
  }

  const handleDeleteStory = async (id)=>{
    try {
      
        const response = await axiosPrivate.delete(
          `/stories/delete/${id}`
        );

        

        if (response.status === 200) {
          alert("Stories Deleted Successfully");
          handleClosestoriesModal();
          getStories();
        } else {
          alert("Server Error");
        }
      
    } catch (error) {
      console.error("Error Updating Stories:", error.message);
      alert("An error occurred while Updating Stories");
    }
  }

 

  return (
    <div className="layout  mb-5 p-4 ">
        <h1 className="text-light text-center fs-2 font-semibold mb-5" >STORIES</h1>

        <div className="parent">

        {currentStories.map((story, index)=>(
          <div key = {index} className="box  rounded mb-5" style={{   overflow: 'hidden',  position : 'relative'}}>

            <div className="boxleft"  style={{ backgroundImage : `url(${BASE_URL}${story.image_url})`, backgroundSize:'cover'}}>

            </div>

            <div className="boxright mb-2 p-2" 
                style={{  backgroundColor: 'rgba(0,0,0,0.5)', overflowY: 'auto'}}>
              <p className="text-light" style={{whiteSpace: 'pre-wrap'}}>{story.message}</p>
            </div>


            {(userRole === 'superadmin' || userRole === 'admin2' ) &&
            
              <div className="border rounded flex" style = {{height:'40px', width : '110px', position : 'absolute', bottom :0, right : 0}}>

              <div onClick={()=>handleEdit(story)}  className="border flex justify-content-center align-items-center font-semibold" style={{height: '100%', width: '75px', backgroundColor: 'greenyellow', cursor: 'pointer'}}>Edit </div>

              <div onClick={()=>handleDeleteStory(story._id)} className="border flex justify-content-center align-items-center font-semibold" style={{height: '100%', width: '75px', backgroundColor: 'red', cursor: 'pointer', color : 'white'}}>Delete </div>
              </div>
            }
        </div>

        ))}
        </div>

        <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>

      <Modal
        open={storiesModal}
        // onClose={handleNightCubeSetUpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className = "border border-warning rounded" sx={style} style = {{width: '1000px', position : 'relative'}}>

        <CloseIcon
            className="closeButton"
            onClick={handleClosestoriesModal}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          />


          <h1 className="text-center text-light font-semibold" style={{fontSize: '22px'}}>Update Story</h1>
          <div className="mt-4">

              <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                ADD PICTURE
              </label>
              <input
                type="file"
                name="image_url"
                accept=".png, .jpg, .jpeg"
                onChange={handleChangePicture}
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
              />
            </div>


              <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                BLOG
              </label>
              <textarea
                rows="10"
                maxLength={8000}
                type="text"
                name="blog"
                placeholder="Write Blog"
                onChange={(e)=>setBlog(e.target.value)}
                className="form-control"
                value={blog}
              
              />

           
            </div>

          

       
        
        <div className="flex  mx-2 mt-4">
              <Button
              
              style={{ backgroundColor: "#007bff", color: "white" }}
              onClick={handleUpdateStory}
            >
              UPDATE
            </Button>

       


        </div>

          </div>
        </Box>
      </Modal>


        

      
      
    </div>
  );
};

export default Stories;
