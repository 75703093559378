import React, { useState, useEffect } from "react";
import "./Outlets.css";
import axios from "../api/axios";

import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";

function ArtistInsights() {
  const navigate = useNavigate();

  let artistId = localStorage.getItem("userId");

  const [form, setForm] = useState({
    artist_stage_name: "",
    artist_profile_icon: null,
    artist_intro_video: null,
    artist_category: "",
    music_operations: "",
    facebook_url: "",
    instragram_url: "",
    soundcloud_url: "",
    youtube_url: "",
    description: "",
    website: "",
    artist_profile_disclaimer : "",
  });

  useEffect(()=>{
    let isMounted = true;

    const getArtistData = async () => {
      const response = await axiosPrivate.get(`/artists/${artistId}`);
      let artistData = response.data;
      setForm({
        artist_stage_name: artistData.artist_stage_name,
        artist_profile_icon: null,
        artist_intro_video: null,
        artist_category: artistData.artist_category,
        music_operations: artistData.music_operations,
        facebook_url: artistData.facebook_url,
        instragram_url: artistData.instragram_url,
        soundcloud_url: artistData.soundcloud_url,
        youtube_url: artistData.youtube_url,
        description: artistData.description,
        website: artistData.website,
        artist_profile_disclaimer : artistData.artist_profile_disclaimer,
      })
    };

    isMounted && getArtistData();

    return()=>{
      isMounted = false;
    }


  },[])

  const handleChange = (e) => {
    ;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedVideoTypes = ["video/mp4", "video/webm"];

    if (e.target.type === "file") {
      if (
        allowedTypes.includes(e.target.files[0].type) ||
        allowedVideoTypes.includes(e.target.files[0].type)
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.files[0],
        });
      } else {
        alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetForm = () => {
    // Define your initial form state or an empty object
    const initialFormState = {
      artist_stage_name: "",
      artist_profile_icon: null,
      artist_intro_video: null,
      artist_category: "",
      music_operations: "",
      facebook_url: "",
      instragram_url: "",
      soundcloud_url: "",
      youtube_url: "",
      description: "",
      website: "",
    };

    // Update the state to reset the form
    setForm(initialFormState);
  };

  const submitServey = async () => {
    try {
      const userId = await localStorage.getItem("userId");
      const formData = new FormData();

      for (const key in form) {
        formData.append(key, form[key]);
      }

      const response = await axios.patch(
        `/artists/insights/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      

      if (response.status === 200) {
        alert("Insights updated successfully");
        // Assuming you have the `navigate` function from your router
        resetForm();
        navigate("/");
        window.location.reload();
      } else {
        alert("Failed to update insights");
      }
    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while updating insights");
    }
  };

  return (
    <div className="layout ">
      <div
        className="mx-auto mt-40 mb-5 "
        style={{ height: "500px", width: "500px" }}
      >
        <h1
          className="text-center"
          style={{
            fontSize: "30px",
            fontWeight: "500",
            color: "white",
            letterSpacing: "1px",
          }}
        >
          ARTIST INSIGHTS
        </h1>

        <div className="mt-20 mx-2">
          <label
            style={{ color: "white" }}
            className="form-label mb-2"
            for="exampleFormControlInput1"
          >
            STAGE NAME
          </label>
          <input
            type="text"
            name="artist_stage_name"
            placeholder="Enter your Artist Moniker"
            value={form.artist_stage_name}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            className="form-label mb-2"
            for="exampleFormControlInput1"
            
          >
            PROFILE IMAGE
          </label>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            name="artist_profile_icon"
            onChange={handleChange}
            className="form-control"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            className="form-label mb-2"
            for="exampleFormControlInput1"

          >
            INTRO VIDEO
          </label>
          <input
            type="file"
            accept="video/*"
            name="artist_intro_video"
            onChange={handleChange}
            className="form-control"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            DESCRIBE YOUR ARTIST CATEGORY
          </label>
          <select
            name="artist_category"
            value={form.artist_category}
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="ELECTRONIC MUSICIAN & PRODUCER">
              ELECTRONIC MUSICIAN & PRODUCER
            </option>
            <option value="DJ">DJ</option>
            <option value="KARAOKE">KARAOKE</option>
            <option value="LIVE BAND">LIVE BAND</option>
            <option value="VOCALIST & SINGER">VOCALIST & SINGER</option>
            <option value="ACOUSTIC">ACOUSTIC</option>
            <option value="METAL & TOOLS">METAL & TOOLS</option>
          </select>
        </div>

        <div className="mt-3  mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            SELECT YOUR MUSIC GENRE
          </label>
          <select
            name="music_operations"
            value={form.music_operations}
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="BOLLYWOOD (हिंदी)">BOLLYWOOD (हिंदी)</option>
            <option value="EDM">EDM</option>
            <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
            <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
            <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
            <option value="DEEP/HOUSE">DEEP/HOUSE</option>
            <option value="CHILL/HOUSE">CHILL/HOUSE</option>
            <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
            <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
            <option value="KANNADA (ಕನ್ನಡ)">KANNADA (ಕನ್ನಡ)</option>
          </select>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            Facebook
          </label>
          <input
            type="text"
            name="facebook_url"
            placeholder="Enter your facebook url"
            value={form.facebook_url}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            Instagram
          </label>
          <input
            type="text"
            name="instragram_url"
            placeholder="Enter your instagram url"
            value={form.instragram_url}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            SoundCloud
          </label>
          <input
            type="text"
            name="soundcloud_url"
            placeholder="Enter your SoundCloud url"
            value={form.soundcloud_url}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            YouTube
          </label>
          <input
            type="text"
            name="youtube_url"
            placeholder="Enter your YouTube url"
            value={form.youtube_url}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            Website
          </label>
          <input
            type="text"
            name="website"
            placeholder="Enter your Website url"
            value={form.website}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            DISCLAIMER
          </label>
          <textarea
            maxLength={250}
            className="form-control"
            name="artist_profile_disclaimer"
            value={form.artist_profile_disclaimer}
            onChange={handleChange}
            placeholder="Desclaimer"
            id="floatingTextarea2"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label  mb-2"
          >
            SHARE YOUR ARTIST JOURNEY
          </label>
          <textarea
            maxLength={250}
            className="form-control"
            name="description"
            value={form.description}
            onChange={handleChange}
            placeholder="Description About Yourself"
            id="floatingTextarea2"
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div
          onClick={submitServey}
          className="mx-2 mt-4 flex align-items-center justify-content-center btnstyles"
        >
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            SUBMIT
          </h1>
        </div>
      </div>
    </div>
  );
}

export default ArtistInsights;
