// RoleContext.js
import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [role, setRole] = useState(''); // Initial role is an empty string

  const setRoleValue = (newRole) => {
    setRole(newRole);
  };

  return (
    <UserContext.Provider value={{ role, setRoleValue }}>
      {children}
    </UserContext.Provider>
  );
};

export const useRole = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useRole must be used within a RoleProvider');
  }
  return context;
};
