import React from "react";
import "./Outlets.css";
import Footer from "../components/Footer";

import { useState, useEffect } from "react";
import axios from "../api/axios";

import { useNavigate } from "react-router-dom";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { BASE_URL } from '../constants';

const Contentsofshows = () => {
  const navigate = useNavigate();
  const [allEvents, setAllEvents] = useState([]);

  const itemsPerPage = 16;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(allEvents.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentEvents = allEvents.slice(startIndex, endIndex);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let isMounted = true;

    const getAllEvents = async () => {
      try {
        
        const response = await axios.get(`/events`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        isMounted && setAllEvents(response.data);
        
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getAllEvents();

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, []);

  return (
    <div className="layout mb-5">
      <div className="mb-5  pb-10 ">
      <div className=" allevt_crds-main align-items-center flex-wrap">
          {currentEvents.map((event, index) => (
            <div
              key={index}
              onClick={() => navigate(`/images/events/${event.event_id}/${event.event_name}`)}
              className="flex-column  cursor-pointer align-items-center imagesinglecard allevent-crd"
              // style={{ height: "350px", width: "350px" }}
            >
              <img
                src={`${BASE_URL}${event.poster_url}`} 
                alt="one"
                // style={{ height: "100%", width: "100%", objectFit: "cover" }}
                className="all-evt-cards"
                />
              <div
                style={{ height: "10%" }}
                className="flex align-items-center  justify-center "
                >
                <h1
                  className="mt-3 font-semibold"
                  style={{ left: "50%", color: "white" }}
                >
                  {event.event_name}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>

      <div className="footer-overlay">
        <Footer />
      </div>
    </div>
  );
};

export default Contentsofshows;
