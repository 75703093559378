import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import {useNavigate, useLocation} from "react-router-dom"

import { axiosPrivate } from "../api/axios";
import useLogout from "../hooks/useLogout";

import CircularProgress from '@mui/material/CircularProgress';

import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import AddIcon from '@mui/icons-material/Add';
import { RWebShare } from "react-web-share";




function Settings() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userName = localStorage.getItem("userName");
  const userId = state?.userId;
  const userRole = state?.userRole;

  const [isAskingHelp, setIsAskingHelp] = React.useState(false);

  const [isAccountModelOpen, setIsAccountModelOpen] = React.useState(false);
  const [isProfilePictureModelOpen, setIsProfilePictureModelOpen] = React.useState(false);
  

  const [isFeedbackModelOpen, setIsFeedbackModelOpen] = React.useState(false);

  const handleAccountModelOpen = () => setIsAccountModelOpen(true);
  const handleAccountModelClose = () => setIsAccountModelOpen(false);

  const handleProfilePictureModelOpen = () => setIsProfilePictureModelOpen(true);
  const handleProfilePictureModelClose = () => setIsProfilePictureModelOpen(false);

  const handleFeedbackModelOpen = () => setIsFeedbackModelOpen(true);
  const handleFeedbackModelClose = () => setIsFeedbackModelOpen(false);

  const [isOtpModelOpen, setIsOtpModelOpen] = React.useState(false);

  const handleOtpModelOpen = () => setIsOtpModelOpen(true);
  const handleOtpModelClose = () => setIsOtpModelOpen(false);

  const [isPasswordMatched, setIsPasswordMatched] = React.useState(false);

  const [otpSent, setOTPSent] = useState(false);

  const [rating, setRating] = useState(0);

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const [sentOtp, setSentOtp] = useState();
  const [receivedOtp, setReceivedOtp] = useState();

  const [loading, setLoading] = useState(false);

  const handleloadingOpen = () => setLoading(true);
  const handleloadingClose = () => setLoading(false);

  const [outletData, setOutletData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [artistData, setArtistData] = useState([]);

  const [admins, setAdmins] = useState([]);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePlusClick = () => {
    // Trigger click event of the hidden file input
    document.getElementById('fileInput').click();
  };

  const logout = useLogout();

  useEffect(() => {
    let isMounted = true;

    const getOutletData = async () => {
      try {
        let userId = localStorage.getItem("userId");
        const response = await axiosPrivate.get(`/outlets/${userId}`);
        setOutletData(response.data)
        setRating(response.data.rating);
      } catch (err) {
        console.error(err); 
      }
    };

    const getCustomerData = async () => {
      try {
        let userId = localStorage.getItem("userId");
        const response = await axiosPrivate.get(`/customers/${userId}`);
        setCustomerData(response.data)
      } catch (err) {
        console.error(err); 
      }
    };

    const getArtistData = async () => {
      try {
        let userId = localStorage.getItem("userId");
        const response = await axiosPrivate.get(`/artists/${userId}`);
        setArtistData(response.data);
        setRating(response.data.rating);
      } catch (err) {
        console.error(err); 
      }
    };

    const getAdmins = async () => {
      try {
        const response = await axiosPrivate.get(`/user/admins`, {
          // signal: controller.signal
        });
        setAdmins(response.data);
        
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getAdmins();

    if(userRole === 'outlet'){
      isMounted && getOutletData();
    }else if(userRole === 'artist'){
      isMounted && getArtistData();
    }else{
      isMounted && getCustomerData();
    }
   
  }, [userRole]);

  const handleLogout = async () => {
    await logout();
    navigate("/");
   
  };


  const [form, setForm] = useState({
    password: "",
    confirm_password: "",
  });

  function handlePassword(password, password2) {
    // Regular expressions to check for uppercase, lowercase, and special characters
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Check length
    if (password.length < 8) {
        alert("Password should be minimum of 8 characters");
        return false;
    }

    // Check for uppercase letter
    if (!uppercaseRegex.test(password)) {
        alert("Password should contain at least one uppercase letter");
        return false;
    }

    // Check for lowercase letter
    if (!lowercaseRegex.test(password)) {
        alert("Password should contain at least one lowercase letter");
        return false;
    }

    // Check for special character
    if (!specialRegex.test(password)) {
        alert("Password should contain at least one special character");
        return false;
    }

    if (password !== password2) {
      alert("Passwords do not match");
      return false;
  }

    // If all conditions are met
    return true;
}

  const handleOTP = async () =>{
    const receivedOtpNumber = Number(receivedOtp);
  
    // Trim whitespace if OTPs are strings
    
    if(sentOtp === receivedOtpNumber){
      
      handleOtpModelClose();

      if(handlePassword(form.password, form.confirm_password)){
        const response = await axiosPrivate.patch(`/user/password/${userId}`, {
          password: form.password 
       });
  
       if(response.status === 200){
        alert("Password Changed Successfullty, Please Login Again")
        handleLogout();
       }
      }

    


    }else{
      alert("OTP Invalid, Please try again ");
    }
  }

  const handleChange = (e) => {

    if(e.target.name === 'confirm_password'){

      if(e.target.value === form.password){
        setIsPasswordMatched(true);
      }else{
        setIsPasswordMatched(false);
      }



    }


    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendOTP = async () => {
    await sendOTPToBackend(customerData?.email);
  };

  const sendOTPToBackend = async (email) => {
    setLoading(true);
    const response = await axiosPrivate.post('/user/sendOtp', {
       email: email 
    });
    if (response.status === 200) {
      handleloadingClose();
      const otp = await response.data;

      setOTPSent(true);
      alert('OTP has been sent to your email.');
      setSentOtp(otp);
      handleOtpModelOpen();
      
    }else{
      alert('Network Error.');
    }
  };

  const handleSubmit = ()=>{
    if(
      !form.password ||
      !form.confirm_password 
      ){
        alert("Please Slect All FIelds")
      }else{

        if(form.password !== form.confirm_password){
          alert("Password Not Matched")
        }else{
          handleSendOTP();
          handleAccountModelClose();
        }


        
      }
  }

  useEffect(()=>{
    
  },[form]);

  const handleSendRequest = () =>{
    sendmessges();
  }


  const sendmessges = async () => {
    try { 
        
        const response = await axiosPrivate.post(
          `/inbox`,
          {
            "message_receivers": JSON.stringify(

              userRole==='outlet'?

              admins
              .filter(admin => admin.role !== "admin1")
              .map(admin => ({
                'id': admin.userId,
                'email_id': admin.email,
                'name': admin.first_name + ' ' + admin.last_name
              }))
              :

              admins
              .filter(admin => admin.role !== "admin2")
              .map(admin => ({
                'id': admin.userId,
                'email_id': admin.email,
                'name': admin.first_name + ' ' + admin.last_name
              }))
              
              
              ),
              "subject":  isAskingHelp?"HELP":"FEEDBACK",
              "message": isAskingHelp?`${userRole} with name ${userName} (${userId}) is Asking For Help\n\nMessage:\n${body}`: `${userRole} with name ${userName} (${userId}) is Providing Feedback\n\nMessage:\n${body}`,
              "category": isAskingHelp?"HELP":"FEEDBACK"

          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        
        alert("Message Sent Successfully, We Will Be In Touch With You Shortly.")

       
      
    } catch (error) {
      console.error("Error sending message:", error.message);
      handleFeedbackModelClose();
      alert("An error occurred while sending message");
    }
  };

  const handleUpdatePicture = async () => {
    const formData = new FormData();

    

    formData.append("customer_profile_image", image);
    formData.append("id", userId);

    if (userId && formData) {
      const response = await axiosPrivate.put(`/customers/updateProfilePic/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      

      if (response.status === 200) {
        alert("Profile Pic Updated Succesfully");
        // Assuming you have the `navigate` function from your router
        handleProfilePictureModelClose();
        window.location.reload();
      }
    } else {
      alert("Failed to Change Profile Pic");
    }
  };

 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius : '5px',
    p: 4,
    outline: 'none'
  };

 




  

  return (
    <div className="parent">
      <div style={{ width: "90%", margin: "auto", height: "100vh" }}>
        <div className="col border-0">
          <h1
            className="text-center p-5 text-light"
            style={{ fontSize: "30px", fontWeight: "600" }}
          >
            SETTINGS
          </h1>
          <div
            className="card  border border-warning"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="card-body flex flex-column justify-content-center p-5">

            {userRole === 'outlet'  &&
                <div className="mb-3">
                  <div className="mt-3">
              <p
                  className="card-title text-light "
                  style={{ width: "150px", fontWeight: "600" }}
                >
                  OFFICIAL DETAILS :
                </p>
              </div>

              <div className="mt-3 flex ms-2">
              <p
                  className=" text-light "
                  style={{ width: "80px", fontWeight: "600" }}
                >
                  CIN :
                </p>
                <p className="text-light"  style={{ width: "auto", fontWeight: "600", textTransform: 'uppercase' }}>
                  {outletData.company_identification_number}
                </p>
              </div>

              <div className="mt-3 flex ms-2">
              <p
                  className=" text-light "
                  style={{ width: "80px", fontWeight: "600" }}
                >
                  GST IN :
                </p>
                <p className="text-light"  style={{ width: "auto", fontWeight: "600", textTransform: 'uppercase' }}>
                  {outletData.gstin}
                </p>
              </div>

              <div className="mt-3 flex ms-2">
              <p
                  className=" text-light "
                  style={{ width: "80px", fontWeight: "600" }}
                >
                  GST % :
                </p>
                <p className="text-light"  style={{ width: "auto", fontWeight: "600", textTransform: 'uppercase' }}>
                  {outletData.gst_percentage} %
                </p>
              </div>
                </div>
              
              }


            
              <div className="d-flex justify-content-start mt-3">
                <p
                  className="card-title text-light "
                  style={{ width: "150px", fontWeight: "600" }}
                >
                  ACCOUNTS :
                </p>
                <p className="card-text text-light ms-2 cursor-pointer" onClick={handleAccountModelOpen} >CHANGE PASSWORD </p>


                 
              </div>
              {(userRole !== "oultet" || userRole !== 'artist') &&
                 <div className="d-flex justify-content-start mt-2">
                 <p
                   className="card-title text-light"
                   style={{ width: "150px" }}
                 ></p>
                 <p className="card-text text-light ms-2 cursor-pointer" onClick={handleProfilePictureModelOpen} >CHANGE PROFILE PICTURE</p>
               </div>
                }


              {(userRole !== "oultet" || userRole !== 'artist') && (
                <div>
                  <div className="d-flex justify-content-start mt-4">
                    <p
                      className="card-title text-light "
                      style={{ width: "150px", fontWeight: "600" }}
                    >
                      ATTRIBUTES :
                    </p>
                    <p onClick={()=>navigate("/underconstruction")} className="card-text text-light ms-2 cursor-pointer">RESERVED SHOWS</p>
                  </div>
                  <div className="d-flex justify-content-start mt-2">
                    <p
                      className="card-title text-light"
                      style={{ width: "150px" }}
                    ></p>
                    <p onClick={()=>navigate("/underconstruction")} className="card-text text-light ms-2 cursor-pointer">ATTENDED SHOWS</p>
                  </div>
                  <div className="d-flex justify-content-start mt-3">
                    <p
                      className="card-title text-light"
                      style={{ width: "150px" }}
                    ></p>
                 
                  </div>
                </div>
              )}

              {(userRole === "artist" || userRole === "outlet") && (
                <div className="d-flex justify-content-start align-items-center  mt-4">
                  <p
                    className="card-title text-light"
                    style={{ fontWeight: "600" }}
                  >
                    RATINGS :
                  </p>
                  <Rating className="mb-3 ms-3 border-light" color="red"   name="half-rating" size="large"  precision={0.5} value={rating} readOnly 
                  emptyIcon={<StarIcon style={{ opacity: 0.2, color: 'white' }} fontSize="inherit" />}
                  />
                  
                 
                </div>
              )}

              <div className="d-flex justify-content-start align-items-center mt-3">
                <p className=" text-light " style={{ fontWeight: "600" }}>
                  AUTHENTICATION :
                </p>
              </div>

              <div
                className="flex align-items-center mt-3 mb-3 "
                style={{ height: "30px" }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "30px",
                    borderRadius: "50%",
                    backgroundColor:  (outletData.authentication_status||artistData.authentication_status||customerData.authentication_status)?"green":'orange',
                  }}
                ></div>

                {((userRole === 'outlet' && outletData.authentication_status) || 
                (userRole === 'artist' && artistData.authentication_status)
                )?
                 <p className=" text-light ms-3 ">
                 Your Account is Authenticated
               </p>
                 :
                 customerData.authentication_status
                 ?
                 <p className=" text-light ms-3 ">
                 Your Account is Authenticated
               </p>
                  :

                 <p className=" text-light ms-3 ">
                 Your Account is Under Review
               </p>
                 }


               
              </div>

              <div className="d-flex justify-content-start mt-3">

                {((userRole !== "oultet" || userRole !== 'artist') || userRole === "artist") &&
                 <p
                 className="card-title text-light cursor-pointer"
                 style={{ fontWeight: "600" }}
                 onClick={handleFeedbackModelOpen}
               >
                 ASK FOR HELP / PROVIDE FEEDBACK
               </p>
                }

               {userRole === "outlet" &&
               <p
               onClick={handleFeedbackModelOpen}
               className="card-title text-light cursor-pointer"
               style={{ fontWeight: "600" }}
             >
               ASK FOR HELP / PROVIDE FEEDBACK
             </p>
               
               }


                

              
                
              </div>

              <RWebShare
                data={{
                    text: "Web Share - Nerolife",
                    url:  "https://nerolife.in",
                    title: "Nerolife",
                }}
                
            >
                 <div className="d-flex justify-content-start mt-3">
                <p
                  className="card-title text-light cursor-pointer"
                  style={{ fontWeight: "600" }}
                >
                  INVITE
                </p>
              </div>
            </RWebShare>

             

         

              <div className="d-flex justify-content-start mt-3">
                <p
                  className="card-title text-light cursor-pointer"
                  style={{ fontWeight: "600" }}
                  onClick={handleLogout}
                >
                  LOGOUT
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isAccountModelOpen}
        onClose={handleAccountModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <h3 className="fs-3 mb-3" >Manage Account</h3>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                New Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter New Password"
                onChange={handleChange}
                className="form-control"
                value={form.password}
              />
            </div>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={handleChange}
                className="form-control"
                value={form.confirm_password}
              />
            </div>

            {form.confirm_password && !isPasswordMatched && <p className="text-danger mx-2 mt-2">Password Not Matched</p>}
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
        </Box>
      </Modal>

       <Modal
        open={isOtpModelOpen}
        onClose={handleOtpModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <h3 className="fs-3 mb-3" >Authenticate With OTP</h3>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
               Enter OTP:
              </label>
              <input
                type="number"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e)=>setReceivedOtp(e.target.value)}
                className="form-control"
                value={receivedOtp}
              />
            </div>
           
          

            
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleOTP}
              >
                Submit
              </Button>
            </div>
        </Box>
      </Modal>

          <Modal
            open={isFeedbackModelOpen}
            onClose={handleFeedbackModelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style = {{width : '800px'}}>
            <h3 className="fs-3 mb-2 mx-2" >ASK FOR HELP / PROVIDE FEEDBACK</h3>

            <FormControl className="mx-2 mb-3 mt-2">
          <FormLabel id="demo-row-radio-buttons-group-label">How Can We Assist You Today ?</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="help" control={<Radio />} checked={isAskingHelp} onClick={()=>setIsAskingHelp(true)} label="Help" />
            <FormControlLabel value="feedback" control={<Radio />} checked={!isAskingHelp} onClick={()=>setIsAskingHelp(false)} label="Feedback" />
           
          </RadioGroup>
        </FormControl>

            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
               Message:
              </label>
              <textarea
                rows={5}
                maxLength={800}
                type="text"
                name="body"
                placeholder="Enter Your Message"
                onChange={(e)=>setBody(e.target.value)}
                className="form-control"
                value={body}
              />
            </div>
           
          

            
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSendRequest}
              >
                Submit
              </Button>
            </div>
        </Box>


      </Modal>

      <Modal
        open={loading}
        onClose={handleloadingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className = "flex align-items-center" sx={style}>
                 
          <CircularProgress />

          <div>
            <p className="ms-2" style={{fontSize : '15px'}}>Sending OTP ...</p>
          </div>
           
        </Box>
      </Modal>

      <Modal
            open={isProfilePictureModelOpen}
            onClose={handleProfilePictureModelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box  sx={style} style = {{width : '400px', backgroundColor : 'rgba(0,0,0,0.5)'}}>
            <h3 className="fs-5 mb-2 mx-2 text-center text-light" >CHANGE PROFILE PICTURE</h3>

            <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }} // Hide the file input
      />

            <div className="flex justify-content-center align-items-center" style={{width:'100%', height: '300px'}}>
            <div className="chsngingProfilePicture" style={{backgroundImage: imageUrl ? `url(${imageUrl})` : `url(${customerData.customer_profile_image})`,  backgroundSize: 'cover'}}>
           
                            <div className="profilePicPlus flex justify-content-center align-items-center cursor-pointer" onClick={handlePlusClick}>
                <AddIcon style={{fontSize : "40px"}} />
              </div>
                        
              </div>

             
            </div>

           
          
            
           
          

            
            <div className="mt-3 flex justify-content-center">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleUpdatePicture}
              >
                update
              </Button>
            </div>
        </Box>


      </Modal>


    </div>
  );
}

export default Settings;
