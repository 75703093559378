import React from 'react'
import construction_image from "../assets/errors/construction.jpg"
import Footer from '../components/Footer';

function ConstructionPage() {
  return (
    <div className='layout'>
<div className='parent flex align-items-center justify-content-center'>
      <img
        src={construction_image}
        alt="construction_image"
        style={{ height: "90%", width: "90%", objectFit: "cover" }}
       
      />
      
    </div>
     <div  className="footer-overlay">
     <Footer />
   </div>
    </div>
    
  )
}

export default ConstructionPage;
