import React from 'react'

import ReactBigCalendar from '../components/ReactBigCalendar'

function DummyCalendar() {
  return (
    <div>
      <ReactBigCalendar />
    </div>
  )
}

export default DummyCalendar
