import React from 'react'
import default_image from "../assets/default_images/default_image.jpg"

function DefaultNightcube() {
  return (
    <div className='parent flex justify-content-center'>
      <img
        src={default_image}
        alt="default_image"
        style={{ height: "95%", width: "95%", objectFit: "cover" }}
       
      />
    </div>
  )
}

export default DefaultNightcube
