import React, { useState } from "react";
import "./Userlogin.css";

import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

import { useParams, useNavigate, Navigate , useLocation} from "react-router-dom";
import useToggle from "../hooks/useToggle";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from "@mui/icons-material/Close";

function UsersLogin() {
  const location  = useLocation();
  const { role } = useParams();
  const [isOtpModelOpen, setIsOtpModelOpen] = React.useState(false);
  const [isAccountModelOpen, setIsAccountModelOpen] = React.useState(false);

  const [isPasswordModelOpen, setIsPasswordModelOpen] = React.useState(false);

  const handleAccountModelOpen = () => setIsAccountModelOpen(true);
  const handleAccountModelClose = () => setIsAccountModelOpen(false);

  const handleOtpModelOpen = () => setIsOtpModelOpen(true);
  const handleOtpModelClose = () => setIsOtpModelOpen(false);

  const handlePasswordModelOpen = () => setIsPasswordModelOpen(true);
  const handlePasswordModelClose = () => setIsPasswordModelOpen(false);

  const [isPasswordMatched, setIsPasswordMatched] = React.useState(false);

  const [otpSent, setOTPSent] = useState(false);


  const [receivedOtp, setReceivedOtp] = useState();
  const [sentOtp, setSentOtp] = useState();

  const [loading, setLoading] = useState(false);

  const handleloadingOpen = () => setLoading(true);
  const handleloadingClose = () => setLoading(false);


  
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {

    if(e.target.name === 'confirm_password'){

      if(e.target.value === form.password){
        setIsPasswordMatched(true);
      }else{
        setIsPasswordMatched(false);
      }

    }


    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const [check, toggleCheck] = useToggle("persist", false);

  const LOGIN_URL = "/user/login";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleLogin = async () => {
    try {
      if (!email || !password) {
        alert("please select all fields");
      } else {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({
            email: email,
            password: password,
            role: role,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              withCredentials: true,
            },
            method: "POST",
          }
        );


        const accessToken = response?.data?.accessToken;
        const refreshToken = response?.data?.refreshToken;
        const userName = response?.data?.userName;
        const userRole = response?.data?.role;
        //setRoleValue(userRole);
        localStorage.setItem("refreshToken", refreshToken);
       

        const userId = response?.data?.customer_id;
        localStorage.setItem("userId", userId);
        localStorage.setItem("userName", userName);

        setAuth({ userId, email, accessToken, userName, userRole });

        alert("Login Successful");

        if (userRole === "outlet") {
          // <Navigate to = "/outletProfile" state = {{from:location}}  replace />
          navigate("/outletProfile", { replace: true });
        } else if (userRole === "artist") {
         
          navigate("/artistProfile", { replace: true });
        } else {
         
          navigate("/profile",  { replace: true });
        }
      }
    } catch (err) {
      if (!err?.response) {
        alert("No Server Response");
      } else if (err.response?.status === 400) {
        alert("Missing username or password");
      } else if (err.response?.status === 401) {
        alert("Unauthorized");
      } else if (err.response?.status === 500){
        alert("Wrong login");
      } else {
        alert("Login Failed");
      }
    }
  };

  const handleOTP = async () =>{
    const receivedOtpNumber = Number(receivedOtp);
  
    // Trim whitespace if OTPs are strings
    
    if(sentOtp === receivedOtpNumber){
      
      handleOtpModelClose();

      alert("OTP is Valid ")
      handlePasswordModelOpen();


    }else{
      alert("OTP Invalid, Please try again ");
    }
  }

  const handleSendOTP = async () => {
    handleAccountModelClose();
    await sendOTPToBackend(form?.email);
  };

  const sendOTPToBackend = async (email) => {
    setLoading(true);
    try {
      const response = await axios.post('/user/sendOtp', {
        email: email
      });
  
      if (response.status === 200) {
        handleloadingClose();
        const otp = response.data;

        setOTPSent(true);
        alert('OTP has been sent to your email.');
        setSentOtp(otp);
        handleOtpModelOpen();
      } else if (response.status === 404) {
        // Handle case when email does not exist
        alert('This Email Does Not Exist With Us');
      } else {
        // Handle other status codes or errors
        alert('Error occurred while sending OTP.');
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error sending OTP:', error);
      if (error.response && error.response.status === 404) {
        alert('This Email Does Not Exist With Us');
      } else {
        alert('Network Error. Please Try Later');
      }
    } finally {
      setLoading(false);
    }
    
  };

  function handlePassword(password, password2) {
    // Regular expressions to check for uppercase, lowercase, and special characters
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Check length
    if (password.length < 8) {
        alert("Password should be minimum of 8 characters");
        return false;
    }

    // Check for uppercase letter
    if (!uppercaseRegex.test(password)) {
        alert("Password should contain at least one uppercase letter");
        return false;
    }

    // Check for lowercase letter
    if (!lowercaseRegex.test(password)) {
        alert("Password should contain at least one lowercase letter");
        return false;
    }

    // Check for special character
    if (!specialRegex.test(password)) {
        alert("Password should contain at least one special character");
        return false;
    }

    if (password !== password2) {
      alert("Passwords do not match");
      return false;
  }

    // If all conditions are met
    return true;
}

  const handleSubmit = async ()=>{
    if(
      !form.password ||
      !form.confirm_password 
      ){
        alert("Please Slect All FIelds")
      }else{

      if(handlePassword(form.password, form.confirm_password)){
        const response = await axios.patch(`/user/passwordbyEmail/${form.email}`, {
          password: form.password 
       });
  
       if(response.status === 200){
        alert("Password Changed Successfully")
  
       }
        handlePasswordModelClose();
      }

      


        
      }
  }

  return (
    <div className="layout">
      <div className="m-auto userloginsecondsection" >
        <h1
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "white",
            letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          SIGN-IN / SIGN-UP
        </h1>

        <div className="mt-20 mx-4">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            EMAIL ADDRESS
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter email"
          />
        </div>
        <div className="mx-4 mt-4 ">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2 text-light"
          >
            PASSWORD
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Password"
          />
        </div>
        <div className="mt-1 flex justify-content-end mx-4">
          <p className="text-light">Forgot Password ?  <span onClick={handleAccountModelOpen} className="font-semibold cursor-pointer">Click Here</span></p>
        </div>
        <div className="mx-4 mt-3 mb-4 persistCheck">
          <input
            type="checkbox"
            id="persist"
            onChange={toggleCheck}
            checked={check}
          />
          <label htmlFor="persist">Trust This Device</label>
        </div>

        <div
          onClick={handleLogin}
          className="mx-4 flex align-items-center justify-content-center btnstyles"
        >
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            LOGIN
          </h1>
        </div>
        <div
          onClick={() => navigate(`/register/${role}`)}
          className="mx-4 my-4 flex align-items-center justify-content-center btnstyles"
        >
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            REGISTER
          </h1>
        </div>

       
      </div>

      <Modal
        open={isAccountModelOpen}
        onClose={handleAccountModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className = "rounded" sx={style} style = {{position : 'relative'}}>
        <h3 className="fs-3 mb-3" >Forgot Password</h3>

        <CloseIcon
            className="cursor-pointer bg-danger"
            onClick={handleAccountModelClose}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          />

        <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                placeholder="Enter Your Email"
                onChange={handleChange}
                className="form-control"
                value={form.email}
              />
            </div>
            {/* <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                New Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter New Password"
                onChange={handleChange}
                className="form-control"
                value={form.password}
              />
            </div> */}
            {/* <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={handleChange}
                className="form-control"
                value={form.confirm_password}
              />
            </div> */}

            {form.confirm_password && !isPasswordMatched && <p className="text-danger mx-2 mt-2">Password Not Matched</p>}
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSendOTP}
              >
                Send OTP
              </Button>
            </div>
        </Box>
      </Modal>

      <Modal
        open={isPasswordModelOpen}
        onClose={handlePasswordModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className = "rounded" sx={style}>
        <h3 className="fs-3 mb-3" >Change Password</h3>
        
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                New Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Enter New Password"
                onChange={handleChange}
                className="form-control"
                value={form.password}
              />
            </div>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                onChange={handleChange}
                className="form-control"
                value={form.confirm_password}
              />
            </div>

            {form.confirm_password && !isPasswordMatched && <p className="text-danger mx-2 mt-2">Password Not Matched</p>}
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
        </Box>
      </Modal>


      <Modal
        open={isOtpModelOpen}
        onClose={handleOtpModelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <h3 className="fs-3 mb-3" >Authenticate With OTP</h3>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
               Enter OTP:
              </label>
              <input
                type="number"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e)=>setReceivedOtp(e.target.value)}
                className="form-control"
                value={receivedOtp}
              />
            </div>
           
          

            
            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleOTP}
              >
                Submit
              </Button>
            </div>
        </Box>
      </Modal>

      <Modal
        open={loading}
        onClose={handleloadingClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className = "flex align-items-center" sx={style}>
                 
          <CircularProgress />

          <div>
            <p className="ms-2" style={{fontSize : '15px'}}>Sending OTP ...</p>
          </div>
           
        </Box>
      </Modal>


    </div>
  );
}

export default UsersLogin;
