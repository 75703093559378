import React from "react";
import "./Artists.css";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import axios from "../api/axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

import { axiosPrivate } from "../api/axios";

import { useInView } from "react-intersection-observer";

import SearchIcon from '@mui/icons-material/Search';

import { BASE_URL } from '../constants';


const Artists = () => {
  const [allArtists, setAllArtists] = useState([]);
  const [filteredArtists, setFilteredAllArtists] = useState([]);
  const navigate = useNavigate();

  const [artistVideoIndex, setArtistVideoIndex] = useState(0);

  const [promotedArtists, setPromotedArtists] = useState([]);

  const [search, setSearch] = useState("");

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
  });

  const itemsPerPage = 15;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(filteredArtists.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentArtists = filteredArtists.slice(startIndex, endIndex);
  const handleChange = (event, value) => {
    setPage(value);
  };
  

 

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const getAllArtists = async () => {
    try {
      const response = await axios.get(`/artists`, {
      });
     console.log(response)
      setAllArtists(response.data);
      setFilteredAllArtists(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  

  useEffect(() => {
    let isMounted = true;

    const interval = setInterval(() => {
      setArtistVideoIndex(
        (prevIndex) => (prevIndex + 1) % 5
      );
    }, 5000); // Change the interval (in milliseconds) as needed

   

    const getPromotedArtists = async () => {
      const response = await axiosPrivate.get(`/artists/promoted`);

      setPromotedArtists(response.data);
    };

    isMounted && getAllArtists();
    isMounted && getPromotedArtists();

    return () => {
      isMounted = false;
      clearInterval(interval)
    };
  }, []);

  const handleSearchChange = (e) =>{
    let name = e.target.value;
    if(name.length > 0){
      setSearch(name.trim());
    }else{
      getAllArtists();
    }
  }

  const handleSearch = (search) =>{
    const filterArtist = (artist) => {
      return artist.filter(artist => 
        artist?.artist_stage_name && artist.artist_stage_name.toLowerCase().includes(search.toLowerCase())
      );
    };
  
    let filtered_artists = filterArtist(allArtists);
    setFilteredAllArtists(filtered_artists);
  
    
  }

  return (
    <div className="layout mb-5">

      <div className="firstwindow ">
        <div className="firstwindowleft">
          <h1
            ref={ref}
            className={`mainheading animated-element ${inView ? "animate" : ""}`} >
            ARTISTS TO WATCH
          </h1>
        </div>
        <div className="  flex justify-content-end firstwindowright" >
        {promotedArtists.map((artist, index) => (
            <video
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              key={index}
              autoPlay
              loop
              muted
              className={index === artistVideoIndex ? "visible" : "hidden"}
            >
              <source   src={`${BASE_URL}${artist.artist_intro_video}`}  type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
        </div>
      </div>

     

      {/* <div className=" flex  align-items-center justify-content-center ">
        <div className=" flex  justify-content-around  flex-wrap ">
          {currentArtists.map((artist, index) => (
            <div
              onClick={() =>
                navigate(`/singleartist/${artist.artist_id}/${artist.artist_stage_name}`, {
                  
                })
              }
              key={index}
              className="flex flex-column mt-5 ms-5 cursor-pointer"
              style={{ height: "350px", width: "350px" }}
            >
              <img
                src={`${BASE_URL}${artist.artist_profile_icon}`} 
                alt="not loaded"
                style={{ height: "90%", width: "90%", objectFit: "cover" }}
                className="eventimage"
              />
              <div
                style={{ height: "10%", width: "90%" }}
                className="flex align-items-center  justify-center "
              >
                <h1
                  className="mt-3 text-center font-semibold "
                  style={{ left: "50%", color: "white" }}
                >
                  {artist.artist_stage_name}
                </h1>
              </div>
            </div>
          ))}
        </div>
    </div> */}

      
      {/* =================new */}
      <div className="mb-5  pb-10 ">
      <div className=" allevt_crds-main align-items-center justify-content-center flex-wrap">
          {currentArtists.map((artist, index) => (
            <div
              onClick={() =>
                navigate(`/singleartist/${artist.artist_id}/${artist.artist_stage_name}`, {
                  
                })
              }
              key={index}
              className="flex-column  cursor-pointer align-items-center imagesinglecard allevent-crd"
              // style={{ height: "350px", width: "350px" }}
            >
              <img
                src={`${BASE_URL}${artist.artist_profile_icon}`} 
                alt="not loaded"
                // style={{ height: "90%", width: "90%", objectFit: "cover" }}
                className="all-evt-cards"
                />
              <div
                style={{ height: "10%", width: "90%" }}
                className="flex align-items-center  justify-center "
                >
                <h1
                  className="my-3 text-center font-semibold "
                  style={{ left: "50%", color: "white" }}
                >
                  {artist.artist_stage_name}
                </h1>
              </div>
            </div>
          ))}
        </div>
    </div>


    
      <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </div>

      <div style={{ marginTop: "50px" }} className="footer-overlay">
          <Footer />
        </div>
    </div>
  );
};

export default Artists;