import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import "./Outlets.css";

import { useNavigate } from "react-router-dom";

import { axiosPrivate } from "../api/axios";

const UPDATE_URL = "/customer";
function Userservey() {
  const navigate = useNavigate();


  const [form, setForm] = useState({
    music_preference: "",
    event_preference: "",
    music_platform: "",
    outing_frequency: "",
    communication_preference: "",
  });

  useEffect(() => {
    let isMounted = true;
    let customerId = localStorage.getItem("userId");
    const getUserData = async () => {
      try {
        const response = await axiosPrivate.get(`/customers/${customerId}`, {

        });
        let userData = response.data;
        setForm({
          music_preference: userData.music_preference,
          event_preference: userData.event_preference,
          music_platform: userData.music_platform,
          outing_frequency: userData.outing_frequency,
          communication_preference: userData.communication_preference,
        })

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };



    isMounted && getUserData();


    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const submitServey = async () => {
    try {
      const userId = await localStorage.getItem("userId");

      const response = await axios.patch(`/customers/${userId}`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      });



      if (response.status === 200) {
        alert("Preferences updated successfully");
        // Assuming you have the `navigate` function from your router
        navigate("/profile");
      } else {
        alert("Failed to update preferences");
      }
    } catch (error) {
      console.error("Error updating preferences:", error.message);
      alert("An error occurred while updating preferences");
    }
  };

  return (
    <div className="layout">
      <div className="m-auto" style={{ height: "500px", width: "500px" }}>
        <h1
          className="text-center"
          style={{
            fontSize: "30px",
            fontWeight: "500",
            color: "white",
            letterSpacing: "1px",
          }}
        >
          PREFERENCE SECTION
        </h1>

        <p className="text-center mt-1 mb-5 text-light" style={{ fontSize: '12px' }}>Tailor Experiences To Personal Tastes and Preferences</p>

        <div className="mt-10 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            SELECT CATEGORY OF MUSIC YOU LOVE
          </label>
          <select
            name="music_preference"
            value={form.music_preference}
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="BOLLYWOOD">BOLLYWOOD (हिंदी)</option>
            <option value="EDM">EDM</option>
            <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
            <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
            <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
            <option value="DEEP/HOUSE">DEEP/HOUSE</option>
            <option value="CHILL/HOUSE">CHILL/HOUSE</option>
            <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
            <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
            <option value="KANNADA">KANNADA (ಕನ್ನಡ)</option>
          </select>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}

            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            CHOOSE KIND OF EVENT YOU ATTEND
          </label>
          <select
            name="event_preference"
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
            value={form.event_preference}
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="LADIES NIGHT">LADIES NIGHT</option>
            <option value="BRUNCH">BRUNCH</option>
            <option value="FRIDAY NIGHT LIVE">FRIDAY NIGHT LIVE</option>
            <option value="SATURDAY NIGHT LIVE">SATURDAY NIGHT LIVE</option>
            <option value="SUNDAY NIGHT LIVE">SUNDAY NIGHT LIVE</option>
            <option value="COSTUME & THEMES">COSTUME & THEMES</option>
            <option value="CONCEPTS">CONCEPTS</option>
            <option value="HAPPY HOURS">HAPPY HOURS</option>
          </select>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}

            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            SELECT YOUR RADIO
          </label>
          <select
            name="music_platform"
            value={form.music_platform}
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="APPLE ITUNES">APPLE ITUNES</option>
            <option value="SPOTIFY">SPOTIFY</option>
            <option value="JIO SAAVAN">JIO SAAVAN</option>
            <option value="AMAZON MUSIC">AMAZON MUSIC</option>
            <option value="SOUNDCLOUD">SOUNDCLOUD</option>
            <option value="YOUTUBE MUSIC">YOUTUBE MUSIC</option>
            <option value="BEATPORT">BEATPORT</option>
          </select>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}

            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            HOW OFTEN YOU ATTEND EVENTS
          </label>
          <select
            name="outing_frequency"
            value={form.outing_frequency}
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="ONCE EVERY WEEK">ONCE EVERY WEEK</option>
            <option value="ONCE EVERY TWO WEEK">ONCE EVERY TWO WEEK</option>
            <option value="ONCE EVERY MONTH">ONCE EVERY MONTH</option>
          </select>
        </div>

        <div className="mt-3 mx-2 mb-5">
          <label
            style={{ color: "white" }}

            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            HOW WOULD YOU LIKE TO BE COMMUNICATED
          </label>
          <select
            name="communication_preference"
            value={form.communication_preference}
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="EMAIL">EMAIL</option>
            <option value="WHATSAPP">WHATSAPP</option>
            <option value="MESSAGES">MESSAGES</option>
          </select>
        </div>

        <div
          onClick={submitServey}
          className="mx-2 flex align-items-center justify-content-center btnstyles"
        >
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            SUBMIT
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Userservey;
