import React from "react";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { CheckCircle, Cancel } from '@mui/icons-material';

import { axiosPrivate } from "../api/axios";

import { useState, useEffect } from "react";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';







const ArtistFilter = () => {
  const [artistData, setArtistData] = useState([]);

  const today = new Date();

  
  const location = useLocation();
  const { userRole } = location.state;


  const  navigate  = useNavigate();

  const [row, setRow] = useState([]);

  const [mandetoryInfo, setMandetoryInfo] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);

  const [promoteLimit, setPromoteLimit] = useState(false);

  const handleAuthentication =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Authenticate This Artist ? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.patch(`/artists/authenticate/${id}`);
     alert("Artist Authenticated Succesfully");
  
     getArtistbyFilter();

    } 
  
  }

  useEffect(() => {
    let promote_count = 0;
    if(artistData.length > 0){
      // eslint-disable-next-line array-callback-return
      artistData.map((artist)=>{
        if(artist.promote){
          promote_count ++;
        }
      })

      if(promote_count === 5){
        setPromoteLimit(true);
      }else{
        setPromoteLimit(false);
      }
    }
  }, [artistData])

  const handleLimit =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Limit This Artist ? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.patch(`/artists/limit/${id}`);
     alert("Artist Limited Succesfully");
  
     getArtistbyFilter();
  
  
    } 
  
  }

  const handleDelete =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Delete This Artist ? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.delete(`/artists/${id}`);
     alert("Artist Deleted Succesfully");
  
     getArtistbyFilter();
  
  
    } 
  
  };

  const handlePromote =  async (id, status) =>{

    if(promoteLimit && status){
      alert("You Can Promote Upto 5");
    }else{
      let userConfirmed = window.confirm(`Are You Sure To ${status?'Promote':'Demote'} This Artist? `);
  
      if (userConfirmed) {
       const result = await axiosPrivate.patch(`/artists/handlePromote/${id}/${status}`);
  
       if(status){
        alert("Artist Promoted Succesfully");
       }else{
        alert("Artist Demoted Succesfully");
       }
      
       getArtistbyFilter();
      } 
    }


   
  
  }


  const columns = [
    { field: "id", headerName: "artist ID", width: 150 },
    {
      field: "first_name",
      // approved or not approved
      headerName: "First Name",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
      headerAlign: "center",
      align: 'center'
    },
  
    {
      field: "artist_stage_name",
      headerName: "Stage Name",
      width: 150,
      headerAlign: "center",
      align: 'center',
      renderCell: (params) => {
        const onClick = () => navigate(`/singleArtist/${params.id}/${params.row.artist_stage_name}`);
        return <div className="cursor-pointer" onClick={onClick}>{params.row.artist_stage_name}</div>;
      }
    },
  
    {
      field: "email",
      headerName: "Email",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "phone",
      headerName: "Contact",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "dob",
      headerName: "DOB",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "registration_date",
      headerName: "Registration Date",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    { 
      field: 'authentication_status',
      headerName: 'Authentication',
      width: 150,
      align: 'center',
      headerAlign: 'left',
      renderCell: (params) => {
        // Define color based on authentication status
        let color;
        switch (params.value) {
          case true:
            color = 'green';
            break;
          case false:
            color = 'orange';
            break;
          default:
            color = 'orange';
            break;
        }
  
        // Define icon based on authentication status
        let icon;
        switch (params.value) {
          case true:
            icon = <CheckCircle style={{ color }} />;
            break;
          case false:
            icon = <Cancel style={{ color }} />;
            break;
          default:
            icon = null;
            break;
        }
        return (
          <div  style={{ display: 'flex', width: '60px', height:'40px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: color, marginRight: 8 }}>
              {icon}
            </div>
           
          </div>
        );
      }
    },
  
   
  
    {
      field: "artist_category",
      headerName: "Category",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "music_operations",
      headerName: "Music Operation",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "membership_plan",
      headerName: "Membership",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    { 
      field: 'contents',
      headerName: 'Contents',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // Define color based on authentication status
       
        // Define icon based on authentication status
        
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  
              <Button
                 onClick={() =>
                  navigate(`/images/artists/${params.row.id}`)
                }
                className="me-2"
              >
                <ImageSearchIcon />
              </Button>
           
              <Button
                onClick={() =>
                navigate(`/videos/show_videos/${params.row.id}`)
              }
              >
                <PlayCircleIcon />
              </Button>
          
          </div>
        );
      }
    },
  
    { 
      field: 'controls',
      headerName: 'Controls',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // Define color based on authentication status
       
        // Define icon based on authentication status
        
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

               {!params.row.promote ?
                   <Button
                   onClick={() =>
                    handlePromote(params.row.id, true)
                  }
                  className="me-2"
                  style={{ backgroundColor: "greenYellow" }}
                >
                  Promote
                </Button>
              :

                  <Button
                  onClick={() =>
                  handlePromote(params.row.id, false)
                }
                className="me-2"
                style={{ backgroundColor: "orange" }}
              >
                Demote
              </Button>
              
              }
  
              {params.row.authentication_status ?
                <Button
                className="me-2"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={()=>handleLimit(params.row.id)}
                >
                  Limit
                </Button>

                :

                <Button
                className="me-2"
                  style={{ backgroundColor: "greenYellow", color: "black" }}
                  // onClick={()=>handleLimit(params.row.id)}
                  onClick = {()=>handleAuthentication(params.row.id)}
                >
                  <CheckCircleOutlineIcon />
                </Button>

              

              
              }

              {userRole === 'superadmin'  &&
               <Button
               style={{ backgroundColor: "red", color: "white" }}
               onClick = {()=>handleDelete(params.row.id)}
             >
               Delete
             </Button>
              }
          
          </div>
        );
      }
    },
  
  
  ];



  const [form, setForm] = useState({
    artist_category: "",
    music_operations: "",
    fromDate: null,
    endDate: null
  });

  useEffect(() => {
    form.fromDate = selectedDate;
  }, [selectedDate]);

  useEffect(() => {
    form.endDate = selectedDate2;
  }, [selectedDate2]);


  const getArtistbyFilter = async () =>{

    const formData = new FormData();

    for (const key in form) {
       
          formData.append(key, form[key]);
        
      }

    const result = await axiosPrivate.post("/artists/filter", formData);
   

    setForm({
      
      artist_category: "",
      music_operations: "",
      fromDate: null,
      endDate: null
    
    })
    setArtistData(result.data);
  }

  const handleSubmit = async () =>{

    if(
        form.artist_category.length === 0  &&
        form.music_operations.length === 0  &&
        form.fromDate === null &&
        form.endDate === null 


    ){
        setMandetoryInfo(true);
    }
    else{
        await getArtistbyFilter();
        setOpen(false);
    }
  }


  useEffect(()=>{
    let isMounted = true;

    const getArtists = async () =>{
        const result = await axiosPrivate.post("/artists/filter");
        setArtistData(result.data);
    }



    isMounted && getArtists();

    return()=>{
        isMounted = false;
    }
  }, []);

  useEffect(()=>{
    const rows = artistData.map(artist => ({
        id: artist.artist_id,
        first_name: artist.first_name,
        last_name: artist.last_name,
        artist_stage_name: artist.artist_stage_name,
        email: artist.email,
        phone: artist.phone,
        dob: new Date(artist.dob).getDate()+'/'+(new Date(artist.dob).getMonth()+1)+'/'+new Date(artist.dob).getFullYear(),
        registration_date: new Date(artist.createdAt).getDate()+'/'+(new Date(artist.createdAt).getMonth()+1)+'/'+new Date(artist.createdAt).getFullYear(),
        authentication_status: artist.authentication_status,
        artist_category: artist.artist_category,
        music_operations: artist.music_operations,
        membership_plan: artist.authentication_status ? 'Normal' :'TBA',
        promote : artist.promote
      }));

      setRow(rows);
  }, [artistData]);


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  }

  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  }

  return (
    <div className="parent">
      <div className="mainsection ">
        <div className="container-fluid text-center ">
          <div className="roas_section p-3" id="pos">
            <div className="flex cursor-pointer mb-3" onClick={handleOpen}>
              <div className="flex align-items-center">
                <p
                  className="text-center text-light   font-semibold"
                  style={{ fontSize: "20px" }}
                >
                  FILTER ARTISTS
                </p>

                <FilterAltIcon className="ms-2"  />
              </div>
            </div>

            <Box
              sx={{
                height: "auto",
                width: "100%",
              }}
            >
              <DataGrid
               columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                promote: false,
              }}
                rows={row}
                columns={columns}
                sx={{ backgroundColor: "white", fontSize: "17px" }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick

                components={{
                  Toolbar: () => (
                    
                    
                    <div>
                      {(userRole === "superadmin" ) &&
                       <GridToolbar />
                     }
                    </div>
                    
                   
                  ),
                }}
              />
            </Box>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="mb-2" style={{ fontSize: "17px" }}>
            Select By Category
          </h1>

          <div className=" p-3 flex justify-normal flex-wrap">

          <div className="mb-4 me-4">
          <label
            className=" mb-2 form-label"
            for="exampleFormControlInput1"
          >
            ARTIST CATEGORY
          </label>
          <select
            name="artist_category"
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="">
              Select
            </option>
            <option value="ELECTRONIC MUSICIAN & PRODUCER">
              ELECTRONIC MUSICIAN & PRODUCER
            </option>
            <option value="DJ">DJ</option>
            <option value="KARAOKE">KARAOKE</option>
            <option value="LIVE BAND">LIVE BAND</option>
            <option value="VOCALIST & SINGER">VOCALIST & SINGER</option>
            <option value="ACOUSTIC">ACOUSTIC</option>
            <option value="METAL & TOOLS">METAL & TOOLS</option>
          </select>
        </div>

        <div className="mb-4 me-4">
          <label
            className=" mb-2 form-label"
            for="exampleFormControlInput1"
          >
            MUSIC OPERATIONS
          </label>
          <select
            name="music_operations"
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="">Select</option>
            <option value="BOLLYWOOD (हिंदी)">BOLLYWOOD (हिंदी)</option>
            <option value="EDM">EDM</option>
            <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
            <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
            <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
            <option value="DEEP/HOUSE">DEEP/HOUSE</option>
            <option value="CHILL/HOUSE">CHILL/HOUSE</option>
            <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
            <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
            <option value="KANNADA (ಕನ್ನಡ)">KANNADA (ಕನ್ನಡ)</option>
          </select>
        </div>



            


          </div>

          <div >

          <h1 className="mb-2" style={{ fontSize: "17px" }}>
            Select By Date
          </h1>

            <div className="flex p-3 flex-wrap">
            <div className="mb-4 me-4" style={{ width: "200px" }}>
                <label
                  
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                 From Date
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mb-4 me-4" style={{ width: "200px" }}>
                <label
                  
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                 To Date
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate2}
                      onChange={handleDateChange2}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                      minDate={selectedDate}
                    //   maxDate={today}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
         
          </div>

          {mandetoryInfo &&
           <h1 className="text-danger ms-3">Please Select An Option To Progress</h1>
          }

         

          <div className="flex justify-content-end pe-1">
            <Button 
                onClick={handleSubmit}
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              SUBMIT
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ArtistFilter;
