
// import {useNavigate} from 'react-router-dom'
// import React, { useState, useRef, useEffect } from 'react';



// const Banner = ({ images, speed = 20000,  direction = 'left', height = '350px', width = '350px', margin = '0px', type }) => {
//     const sectionClassName = direction === 'left' ? 'section1' : 'section2';
//     const navigate = useNavigate();
//     const [scrollPosition, setScrollPosition] = useState(0);
//     const sectionRef = useRef(null);

   

//     const handleNavigation = (name, id) =>{
//       if(type === "images"){
//         navigate(`/images/outlets/${id}/${name.replace(/ /g, '_')}` )
//       }else if(type === "videos"){
//         navigate(`/videos/experience_videos/${id}/${name.replace(/ /g, '_')}` )
//       }else if(type === "event"){
//         navigate(`/singleEvent/${id}/${name.replace(/ /g, '_')}` )
//       }
//       else{
//         navigate(`/singleoutlet/${id}/${name.replace(/ /g, '_')}` )
//       }
//     }

   
    
    
//     return (
//       <div className="inner" >

//         <div className="wrapper">
//           <section ref={sectionRef} className={sectionClassName}  style={{ "--speed": `${speed}ms`,  "--direction": 'right' }}>
//             {images.map(({ id, image, name }) => (
//               <div className='flex flex-column'>
//                <div className="image " style = {{margin: margin}}  key={id}>
//                 <img 

//                 onClick={()=>handleNavigation(name, id)} 
                
//                 className="sliderimg" style = {{height: height, minWidth:width, objectFit: 'cover'}} src={image} alt={id} />
//               </div>
//               <div>
//                 <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
//               </div>
//               </div>
//             ))}
//           </section>

//           <section  className={sectionClassName} style={{ "--speed": `${speed}ms`,  "--direction": direction === 'right' ? 'reverse' : 'normal', }}>
//           {images.map(({ id, image, name }) => (
//               <div className='flex flex-column'>
//                <div className="image " style = {{margin: margin}}  key={id}>
//                 <img onClick={()=>handleNavigation(name, id)}   
                
//                 className="sliderimg" style = {{height: height, minWidth:width, objectFit: 'cover'}} src={image} alt={id} />
//               </div>
//               <div>
//                 <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
//               </div>
//               </div>
//             ))}

//           </section>

//           <section  className={sectionClassName} style={{ "--speed": `${speed}ms`,  "--direction": direction === 'right' ? 'reverse' : 'normal', }}>
//           {images.map(({ id, image, name }) => (
//               <div className='flex flex-column'>
//                <div className="image " style = {{margin: margin}}  key={id}>
//                 <img onClick={()=>handleNavigation(name, id)}   
                
//                 className="sliderimg" style = {{height: height, minWidth:width, objectFit: 'cover'}} src={image} alt={id} />
//               </div>
//               <div>
//                 <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
//               </div>
//               </div>
             
//             ))}
//           </section>
//         </div>
//       </div>
//     );
//   };

//   export { Banner };


import { useNavigate } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import { BASE_URL } from '../constants';

const Banner = ({ images, speed = 20000, direction = 'left', height = '300px', width = '300px', margin = '0px', type }) => {
    const sectionClassName = direction === 'left' ? 'section1' : 'section2';
    const navigate = useNavigate();
    const [startIndex, setStartIndex] = useState(0);
    const sectionRef = useRef(null);

    const handleNavigation = (name, id) => {
        if (type === "images") {
            navigate(`/images/outlets/${id}/${name.replace(/ /g, '_')}`)
        } else if (type === "videos") {
            navigate(`/videos/experience_videos/${id}/${name.replace(/ /g, '_')}`)
        } else if (type === "event") {
            navigate(`/singleEvent/${id}/${name.replace(/ /g, '_')}`)
        } else {
            navigate(`/singleoutlet/${id}/${name.replace(/ /g, '_')}`)
        }
    }

    const showNextImages = () => {
        if(startIndex + 5 < images.length){
            setStartIndex(startIndex + 1);
        }else{
            setStartIndex(0)
        }
        
    };

    const showPreviousImages = () => {
        if(startIndex > 1){
            setStartIndex(startIndex - 1);
        }
        
    };

    return (
        <div style={{width: '100%', overflow: 'hidden'}}>
            <div>
                <section className={sectionClassName} >
                    {images.slice(startIndex, startIndex + 5).map(({ id, image, name }) => (
                        <div className='cursor-pointer' key={id}>
                            <div className="image" style={{ margin: margin }}>
                            <img onClick={() => handleNavigation(name, id)}
                                className=""
                                style={{ height: height, width: width, objectFit: 'cover', backgroundColor: '#000' }} 
                                // style={{ height: height, width: width, objectFit: 'contain', backgroundColor: '#000' }} 

                                src={`${BASE_URL}${image}`}
                                alt={id} /> 
                            </div>
                            <div>
                                <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
                            </div>
                        </div>
                    ))}
                </section>
                
            </div>
            <div className='flex mt-5 justify-content-end align-items-center'  >
                <div className="prev text-light cursor-pointer" onClick={showPreviousImages}><SkipPreviousIcon style={{fontSize: '40px'}}/></div>
                <div className="next text-light cursor-pointer" onClick={showNextImages}><SkipNextIcon style={{fontSize: '40px'}}/></div>
            </div>
        </div>
    );
};

export { Banner };
