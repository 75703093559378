import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";

import { axiosPrivate } from "../api/axios";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

export default function ArtistCalendar(props) {
  const userId = localStorage.getItem("userId");
  const artistId = props.artistId;
  const artistName = props.artistName;

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const [form, setForm] = useState({
    event_name: "",
    outlet_name: "", //based on single outlet selected
    event_start_time: null, // timestamp
    event_category: "LADIES NIGHT", // dropdown
    event_address: "", //outlet address
    description: "",
    artist_lineup: [],
  });

  useEffect(() => {

    // Create a new artist object based on artistId and artistName
    const newArtist = { id: artistId, name: artistName };

  

    // Update the form's artist_lineup array with the new artist object

    setForm({
      ...form,
      artist_lineup: [newArtist],
    });
  }, [artistId, artistName]);

  useEffect(() => {
    // Convert the selectedDate to a JavaScript Date object
    const selectedDateObject = new Date(selectedDate);

    // Update form.event_start_time with the selected date
    setForm({
      ...form,
      event_start_time: selectedDateObject, // or use selectedDateObject.getTime() for timestamp
    });
  }, [selectedDate]);

  const navigate = useNavigate();
  const [eventsData, setEventsData] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formats = {
    eventTimeRangeFormat: () => null, // Hide event times
    dayFormat: "dddd", // Display full day names in the header
    monthHeaderFormat: "MMMM YYYY", // Display full month and year in the header
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getArtistEventsById = async (artist_id) => {
    try {
      const response = await axiosPrivate.get(`/calendars/artist/${artist_id}`, {
        // // signal: controller.signal
        // 'Access-Control-Allow-Origin' : true
      });
      // isMounted && setAllEvents(response.data);

      let events_data = response.data;

     
      const newArray = events_data.map((event) => ({
        id: event.event_id?event.event_id:"1",
        title: event.event_name,
        start: new Date(event.event_start_time),
        end: event.event_end_time?(new Date(event.event_end_time)):(new Date(event.event_start_time)),
      }));

      setEventsData(newArray);

     
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    let isMounted = true;
    let artist_profile_id = localStorage.getItem("userId");

    

    if (artistId) {
      isMounted && getArtistEventsById(artistId);
    } else if (artist_profile_id) {
      isMounted && getArtistEventsById(artist_profile_id);
    }

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, [artistId]);

  const handleSelect = (range) => {
    const selected_date = range.start;
    const formatted_date = selected_date.toLocaleDateString(); // Convert the Date object to a string
    setSelectedDate(formatted_date);
    if(userId === artistId){
      setOpen(true);
    }
    
  };

  const getEventStyle = (event, start, end, isSelected) => {
    // const backgroundColor = event.booked ? 'red' : 'blue'; // Change the colors as needed
    return {
      style: {
        backgroundColor: "#FFD700",
        height: "100%",
        color:'black'
      },
    };
  };

  const handleChange = (e) => {
 
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (
        !form.event_name ||
        !form.outlet_name ||
        !form.event_category ||
        !form.event_address ||
        !form.description
      ) {
        alert("Please Select All Fields");
      } else {
        const formData = new FormData();

        for (const key in form) {
          if (key === "artist_lineup") {
            // If the property is 'artist_lineup', stringify and append it
            formData.append(key, JSON.stringify({id:artistId, name: artistName}));
          } else {
            // For other properties, directly append to FormData
            formData.append(key, form[key]);
          }
        }

        const response = await axiosPrivate.post(`/calendars`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

     
        if (response.status === 201) {
          alert("Calendar Update Successful");
          // Assuming you have the `navigate` function from your router
          //   resetForm();

          handleClose();
          getArtistEventsById(artistId);
        } else {
          alert("Failed to Update Calendar");
        }
      }
    } catch (error) {
      console.error("Error Updating Calendar:", error.message);
      alert("An error occurred while updating Calendar");
    }
  };

  const handleEventRoute = (event) =>{
    if((event.id).startsWith("NE")){
      navigate(`/singleevent/${event.id}/${event.title}`)
    }
   
  }

  return (
    <div>
      <Calendar
        views={["agenda", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ height: "500px", color:'white' }}
        onSelectEvent={(event) => handleEventRoute(event)}
        eventPropGetter={getEventStyle}
        formats={formats}
        onSelectSlot={handleSelect}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <h3 className="fs-3 mb-3">My Calendar</h3>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                EVENT NAME
              </label>
              <input
                type="text"
                name="event_name"
                placeholder="Enter Event Name"
                onChange={handleChange}
                className="form-control"
                value={form.event_name}
              />
            </div>

            <div className="mt-3 mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
              >
                EVENT CATEGORY
              </label>
              <select
                name="event_category"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.event_category}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="LADIES NIGHT">LADIES NIGHT</option>
                <option value="BRUNCH">BRUNCH</option>
                <option value="FRIDAY NIGHT LIVE">FRIDAY NIGHT LIVE</option>
                <option value="SATURDAY NIGHT LIVE">SATURDAY NIGHT LIVE</option>
                <option value="SUNDAY NIGHT LIVE">SUNDAY NIGHT LIVE</option>
                <option value="COSTUME & THEMES">COSTUME & THEMES</option>
                <option value="CONCEPTS">CONCEPTS</option>
                <option value="HAPPY HOURS">HAPPY HOURS</option>
              </select>
            </div>

            <div className=" mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className=" text-dark mb-2"
              >
                OUTLET NAME
              </label>
              <input
                type="text"
                name="outlet_name"
                placeholder="Enter Outlet Name"
                onChange={handleChange}
                className="form-control"
                value={form.outlet_name}
              />
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                OUTLET ADDRESS
              </label>
              <textarea
                maxLength={250}
                value={form.event_address}
                className="form-control"
                name="event_address"
                onChange={handleChange}
                placeholder="Enter your Outlet Address"
                id="floatingTextarea2"
                style={{ height: "100px" }}
              ></textarea>
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
              >
                DESCRIPTION
              </label>
              <textarea
                maxLength={250}
                className="form-control"
                name="description"
                onChange={handleChange}
                placeholder="Description About your Event"
                id="floatingTextarea2"
                style={{ height: "100px" }}
                value={form.description}
              ></textarea>
            </div>

            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
