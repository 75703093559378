import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import events from "./events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import { axiosPrivate } from "../api/axios";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

export default function ReactBigCalendar(props) {
  const outletId = props.outletId;

  const navigate = useNavigate();
  const [eventsData, setEventsData] = useState([]);

  const formats = {
    eventTimeRangeFormat: () => null, // Hide event times
    dayFormat: "dddd", // Display full day names in the header
    monthHeaderFormat: "MMMM YYYY", // Display full month and year in the header
  };

  useEffect(() => {
    let isMounted = true;
    let outlet_profile_id = localStorage.getItem("userId");

    const getOutletEventsById = async (outlet_id) => {
      try {
        const response = await axiosPrivate.get(`/events/outlet/${outlet_id}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        // isMounted && setAllEvents(response.data);

        let events_data = response.data;

      

        const newArray = events_data.map((event) => ({
          id: event.event_id,
          title: event.event_name,
          start: new Date(event.event_start_time),
          end: new Date(event.event_end_time),
        }));

        setEventsData(newArray);

      
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    if (outletId) {
      isMounted && getOutletEventsById(outletId);
    } else if (outlet_profile_id) {
      isMounted && getOutletEventsById(outlet_profile_id);
    }

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, [outletId]);

  const getEventStyle = (event, start, end, isSelected) => {
    // const backgroundColor = event.booked ? 'red' : 'blue'; // Change the colors as needed
    return {
      style: {
        backgroundColor: "#FFD700",
        height: "100%",
        color:'black'
      },
    };
  };

  return (
    <div>
      <Calendar
        views={["agenda", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ height: "500px", color : 'white' }}
        onSelectEvent={(event) => navigate(`/singleevent/${event.id}/${event.title}`)}
        eventPropGetter={getEventStyle}
        // startAccessor={(event)=>startAccessor(event)}
        // endAccessor={(event)=>endAccessor(event)}
        formats={formats}
      />
    </div>
  );
}
