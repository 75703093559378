import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import "./NightCubeSystems.css";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import { useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";

const OutletMangement = () => {
  const [expanded, setExpanded] = useState(false);

  const [outletData, setOutletData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getOutletbyCategory = async () => {
      try {
        const response = await axiosPrivate.get(
          `/outlets/category/outlet_category`
        );

        setOutletData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getOutletbyCategory();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="parent">
      <div className="mainsection content">
        <div className="container-fluid text-center">
          <div className="row mt-5">
            <div
              className="col d-flex flex-column justify-content-start align-items-center "
              style={{ height: "400px", width: "33%" }}
            >
              <h3
                style={{ fontSize: "20px", fontWeight: "600" }}
                className="text-center"
              >
                ALL OUTLETS
              </h3>
              <div
                className="maincircle d-flex justify-content-center align-items-center mx-0"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                  style={{ width: "100%" }}
                >
                  <PieChart
                    series={[
                      {
                        data: outletData.map((item, index) => ({
                          id: index,
                          value: item.count,
                          label: item.category.toUpperCase(),
                          color:
                            item.category === "NIGHTCLUB"
                              ? "#FAF700"
                              : item.category === "LOUNGE"
                              ? "#00EBFA"
                              : item.category === "ROOFTOP CAFE AND BAR"
                              ? "#FF3600"
                              : item.category === "RESTO CAFE AND BAR"
                              ? "#FFFFFF"
                              : item.category === "PUB & BREWERY"
                              ? "#3396B8"
                              : item.category === "POOLSIDE"
                              ? "#00FC7E"
                              : item.category === "OPEN AIR"
                              ? "#FE0041"
                              : "#01950F",
                        })),
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mainsectionend ">
            <div className=" flex justify-content-end">
              <div
                className=" flex align-items-center p-3 justify-content-between "
                style={{ height: "50px", width: "250px" }}
              >
                <h1>EXPAND DISTRIBUTION</h1>

                {expanded ? (
                  <KeyboardDoubleArrowUpIcon
                    onClick={() => setExpanded(false)}
                    className="cursor-pointer"
                    style={{ fontSize: "30" }}
                  />
                ) : (
                  <KeyboardDoubleArrowDownIcon
                    onClick={() => setExpanded(true)}
                    className="cursor-pointer"
                    style={{ fontSize: "30" }}
                  />
                )}
              </div>
            </div>

            {expanded && (
              <div
              //    id="panelsStayOpen-collapseThree"
              //    className="accordion-collapse collapse"
              >
                <div className="accordion-body p-5">
                  <div className="row custom-pie-chart-container">
                    <div
                      className="col d-flex flex-column justify-content-center align-items-center"
                      style={{ height: "550px" }}
                    >
                      <div
                        className="maincircle d-flex justify-content-center"
                        style={{ height: "80%", width: "100%" }}
                      >
                        <div className="piechart d-flex align-items-center">
                          <PieChart
                            series={[
                              {
                                data: outletData.map((item, index) => ({
                                  id: index,
                                  value: item.count,

                                  color:
                                      item.category === "NIGHTCLUB"
                                        ? "#FAF700"
                                        : item.category === "LOUNGE"
                                        ? "#00EBFA"
                                        : item.category === "ROOFTOP CAFE AND BAR"
                                        ? "#FF3600"
                                        : item.category === "RESTO CAFE AND BAR"
                                        ? "#FFFFFF"
                                        : item.category === "PUB & BREWERY"
                                        ? "#3396B8"
                                        : item.category === "POOLSIDE"
                                        ? "#00FC7E"
                                        : item.category === "OPEN AIR"
                                        ? "#FE0041"
                                        : "#01950F",
                                })),
                              },
                            ]}
                            width={250}
                            height={300}
                          />
                        </div>

                        <div className="piecalculations d-flex align-items-center">
                          <BarChart
                            xAxis={[
                              {
                                id: "barCategories",
                                data: outletData.map((item, index) =>
                                  item.category.toUpperCase()
                                ), // Assuming you want to use index as category
                                scaleType: "band",
                              },
                            ]}
                            series={[
                              {
                                data: outletData.map((item) => item.count),
                              },
                            ]}
                            width={1200}
                            height={500}
                          />
                        </div>
                      </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FAF700",
                            }}
                          ></div>
                          <h1>NIGHT CLUB</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00EBFA",
                            }}
                          ></div>
                          <h1>LOUNGE</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FF3600",
                            }}
                          ></div>
                          <h1>ROOFTOP BAR</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FFFFFF",
                            }}
                          ></div>
                          <h1>RESTO CAFE</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#3396B8",
                            }}
                          ></div>
                          <h1>PUB & BREWERY</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>POOL SIDE</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FE0041",
                            }}
                          ></div>
                          <h1>OPEN AIR</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#01950F",
                            }}
                          ></div>
                          <h1>CONCERT HALL</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutletMangement;
