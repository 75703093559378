// constants.js
const domain = window.location.hostname;
const port = window.location.port; // For Prod
const protocol = window.location.protocol;


// const port = 8000; //For Local

const domainWithPort = port ? `${domain}:${port}` : domain;

export const BASE_URL = `${protocol}//${domainWithPort}`;
