import React from "react";
import { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Link, useNavigate } from "react-router-dom";
import "./NightCubeSystems.css";


import { axiosPrivate } from "../api/axios";
import { FaRoadSpikes } from "react-icons/fa6";

const LinkInput = ({ value, onChange, onBlur }) => {
  const [link, setLink] = useState(value);

  const handleChange = (e) => {
    setLink(e.target.value);
    onChange(e.target.value);
  };

  return (
    <input
      type="text"
      value={link}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder="Add Link"
      style={{ width: "100%", height: "35px", color: "black", padding: "3px" }}
    />
  );
};

const NightcubeROAS = () => {
  const [updatedData, setUpdatedData] = useState([]);

  const [facebookCtr, setFacebookCtr] = useState("");
  const [facebookBudget, setFacebookBudget] = useState("");
  const [facebookConversion, setFacebookConversion] = useState("");
  const [platformLinkRow, setPlatformLinkRow] = useState([]);
  const [agencyLinkRow, setAgencyLinkRow] = useState([]);

  const [platform, setPlatform] = useState([]);
  const [agency, setAgency] = useState([]);

  const [updatedLinks, setUpdatedLinks] = useState([]);

  const [totalAgencyConversion, setTotalAgencyConversion] = useState(0);
  const [totalPlatformConversion, setTotalPlatformConversion] = useState(0);

  
  useEffect(() => {
    let isMounted = true;

    const getRoasData = async (type) =>{
      const response = await axiosPrivate.get(`/roas/type/${type}`);
      let roas_data = response.data;

      if(type === "Platform"){
        setPlatform(roas_data);
      }else{
        setAgency(roas_data);
      }
      
    }

    isMounted && getRoasData("Platform");
    isMounted && getRoasData("Agency");

    return()=>{
      isMounted = false;
    }
  
  }, [])




  useEffect(() => {
   
    const rows = agency.map((roas, index) => ({
      id:index+1,
      name:roas.name,
      platform: roas.platform,
      link: roas.link
    }));

    const totalConversion = agency.reduce((acc, curr) => acc + ((curr.ctr/curr.budget)*100) , 0);

   

    setTotalAgencyConversion(totalConversion);

  
    setAgencyLinkRow(rows);
   

  }, [agency])

  

  useEffect(() => {
    const rows = platform.map((roas, index) => ({
      id:index+1,
      name:roas.name,
      platform: roas.platform,
      link: roas.link
    }));

    setPlatformLinkRow(rows);

    const totalConversion = platform.reduce((acc, curr) => acc + ((curr.ctr/curr.budget)*100) , 0);

    setTotalPlatformConversion(totalConversion);


  }, [platform]);





  

  const columns = [
    { field: "id", headerName: "SL.NO.", width: 90 },
    {
      field: "platform",
      headerName: "PLATFORM",
      width: 150,
      editable: true,
    },
    {
      field: "link",
      headerName: "LINK",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <LinkInput
            value={params.value}
            onChange={(newValue) => {
              // Implement your logic to update the link value here
            
            }}
            onBlur={() => {
              // Handle onBlur if needed
            }}
          />
        );
      },
    },
  ];

  const rows = [
    { id: 1, platform: "FACEBOOK", link: "" },
    { id: 2, platform: "INSTAGRAM", link: "" },
    { id: 3, platform: "GOOGLE", link: "" },
    { id: 4, platform: "X (TWITTER)", link: "" },
    { id: 5, platform: "WHATSAPP", link: "" },
  ];

  function roundToNearest(num) {
    const epsilon = 0.0001; // Small value to ensure correct rounding
    const adjustedNum = num - epsilon;
    if(adjustedNum)
      return Math.ceil(adjustedNum);
    return "N/A"
  }

  



  const handleUpdate = (id, type, category) => {
    const updatedRoas = updatedLinks.find(roa => roa.event_id === id);
    if (!updatedRoas) {
      console.error('Error: Updated Roas not found');
      return;
    }
  
    axiosPrivate.patch(`/roas/update/${id}`, updatedRoas)
      .then(response => {
        // If update is successful, update state to reflect the change
        if (type === 'Platform') {
          if (category === 'link') {
            setPlatform(prevRoas => prevRoas.map(roa => roa.event_id === id ? { ...roa, link: updatedRoas.link } : roa))
          } else if (category === 'ctr') {
            setPlatform(prevRoas => prevRoas.map(roa => roa.event_id === id ? { ...roa, ctr: updatedRoas.ctr } : roa))
          } else {
            setPlatform(prevRoas => prevRoas.map(roa => roa.event_id === id ? { ...roa, budget: updatedRoas.budget } : roa))
          }
        } else {
          if (category === 'link') {
            setAgency(prevRoas => prevRoas.map(roa => roa.event_id === id ? { ...roa, link: updatedRoas.link } : roa))
          } else if (category === 'ctr') {
            setAgency(prevRoas => prevRoas.map(roa => roa.event_id === id ? { ...roa, ctr: updatedRoas.ctr } : roa))
          } else {
            setAgency(prevRoas => prevRoas.map(roa => roa.event_id === id ? { ...roa, budget: updatedRoas.budget } : roa))
          }
        }
  
        alert("Update Successful");
  
      })
      .catch(error => {
        console.error('Error updating link:', error);
      });
  };
  

  const handleInputChange = (roas, value, category) => {
    setUpdatedLinks(prevLinks => {
      const index = prevLinks.findIndex(item => item.event_id === roas.event_id);
      if (index !== -1) {
        // If the same object exists, update it
        if(category === 'link')
          return prevLinks.map(item => (item.event_id === roas.event_id ? { ...item, link: value } : item));
        else if(category === 'ctr')
          return prevLinks.map(item => (item.event_id === roas.event_id ? { ...item, ctr: value } : item));
        else{
          return prevLinks.map(item => (item.event_id === roas.event_id ? { ...item, budget: value } : item));
        }
      } else {
        // If a new object, add it to the array

        if(category === 'link'){
        return [
          ...prevLinks,
          {
            _id: roas.event_id,
            [roas.event_id] : value,
            name: roas.name,
            type: roas.type,
            link: value,
            ctr: roas.ctr,
            budget: roas.budget
          }
        ]
      }
        else if(category === 'ctr'){
        return [
          ...prevLinks,
          {
            _id: roas.event_id,
            [roas.event_id] : value,
            name: roas.name,
            type: roas.type,
            link: roas.link,
            ctr: value,
            budget: roas.budget
          }
        ]
      }
        else{
          return [
            ...prevLinks,
            {
              _id: roas.event_id,
              [roas.event_id] : value,
              name: roas.name,
              type: roas.type,
              link: roas.link,
              ctr: roas.ctr,
              budget: value
            }
          ];
        }


        
      }
    });
  };

  const handleInputChange2 = (roas, newCTR) => {
    setUpdatedLinks(prevLinks => {
      const index = prevLinks.findIndex(item => item.event_id === roas.event_id);
      if (index !== -1) {
        // If the same object exists, update it
        return prevLinks.map(item => (item.event_id === roas.event_id ? { ...item, ctr: newCTR } : item));
      } else {
        // If a new object, add it to the array
        return [
          ...prevLinks,
          {
            _id: roas.event_id,
            [roas.event_id] : newCTR,
            name: roas.name,
            type: roas.type,
            link: roas.link,
            ctr: newCTR,
            budget: roas.budget
          }
        ];
      }
    });
  };

  

  return (
    <div className="parent">
      <div className="mainsection content">
        <div className="container-fluid text-center">
          <div className="roas_section p-2" id="revenueros">
            <div className="row">
              <h2
                className="text-center text-decoration-underline p-3 font-semibold"
                style={{ fontSize: "20px" }}
              >
                ROAS : LINKS
              </h2>

              <div
                className="col  d-flex flex-column justify-content-center align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >

                  <PieChart
                    series={[
                      {
                        data: platform.map((item, index) => ({
                          id: index,
                          value: ((item.ctr / item.budget)*100/totalPlatformConversion)*100 ,
                          label: item.name.toUpperCase(),

                          color:
                          item.name.toUpperCase() === "FACEBOOK"
                            ? "#FAF700"
                            :  item.name.toUpperCase() === "INSTAGRAM"
                            ? "#00EBFA"
                            :  item.name.toUpperCase() === "GOOGLE"
                            ? "#FF3600"
                            :  item.name.toUpperCase() === "X (TWITTER)"
                            ? "#FFFFFF"
                            : "#3396B8"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />


                   
                  </div>
                </div>

                <h4 className="text-center font-semibold">SOCIAL LINKS</h4>
              </div>

              <div
                className="col  d-flex flex-column justify-content-center  align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                    <PieChart
                    series={[
                      {
                        data: agency.map((item, index) => ({
                          id: index,
                          value: ((item.ctr / item.budget) * 100 / totalAgencyConversion) * 100,
                          label: item.name.toUpperCase(),

                          color:
                          index === 0
                            ? "#228ee6"
                            :  index === 1
                            ? "#f7e814"
                            :  index === 2
                            ? "#1aed64"
                            :  index === 3
                            ? "#00EBFA"
                            : "red"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>
                <h4 className="text-center font-semibold">AGENCY LINKS</h4>
              </div>

            

              <div className="transparent-table d-flex justify-content-around">
                <div className="flex flex-column" >
                  <table className="table me-5">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">PLATFORM</th>
                        <th scope="col">LINK</th>
                         <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {platform.map((roas, index)=>(
                        <tr key = {index} >
                        <th scope="row pt-3">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="Add Link"
                            name = {roas.name}
                            
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                            defaultValue={roas.link} 
                            onChange={e => handleInputChange(roas, e.target.value, "link")}
                
                          ></input>
                        </td>
                        <td>
                        <button onClick={() => handleUpdate(roas.event_id, roas.type, "link")} className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}

                    
                    </tbody>
                  </table>

                  
                </div>

                <div className="flex flex-column" >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">AGENCY</th>
                        <th scope="col">LINK</th>
                         <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                    {agency.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row pt-3">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="Add Link"
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}

                            defaultValue={roas.link} 
                            onChange={e => handleInputChange(roas, e.target.value, "link")}
                           
                          ></input>
                        </td>
                        <td>
                        <button onClick={() => handleUpdate(roas.event_id, roas.type, "link")} className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                 
                </div>
              </div>
            </div>
          </div>

          <div className="roas_section p-2" id="revenueros">
            <div className="row">
              <h2
                className="text-center text-decoration-underline p-3 font-semibold"
                style={{ fontSize: "20px" }}
              >
                ROAS : CLICK THROUGH RATE
              </h2>
              <div
                className="col d-flex flex-column justify-content-center align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                    <PieChart
                    series={[
                      {
                        data: platform.map((item, index) => ({
                          id: index,
                          value: item.ctr,
                          label: item.name.toUpperCase(),

                          color:
                          item.name.toUpperCase() === "FACEBOOK"
                            ? "#FAF700"
                            :  item.name.toUpperCase() === "INSTAGRAM"
                            ? "#00EBFA"
                            :  item.name.toUpperCase() === "GOOGLE"
                            ? "#FF3600"
                            :  item.name.toUpperCase() === "X (TWITTER)"
                            ? "#FFFFFF"
                            : "#3396B8"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>
                <h4 className="text-center font-semibold">SOCIAL CTRS</h4>
              </div>

              <div
                className="col  d-flex flex-column justify-content-center  align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                     <PieChart
                    series={[
                      {
                        data: agency.map((item, index) => ({
                          id: index,
                          value: item.ctr,
                          label: item.name.toUpperCase(),

                          color:
                          index === 0
                            ? "#228ee6"
                            :  index === 1
                            ? "#f7e814"
                            :  index === 2
                            ? "#1aed64"
                            :  index === 3
                            ? "#00EBFA"
                            : "red"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>
                <h4 className="text-center font-semibold">AGENCY CTRS</h4>
              </div>

              <div className="transparent-table d-flex justify-content-around">
                <div className="flex flex-column" >
                  <table className="table me-5">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">PLATFORM</th>
                        <th scope="col">CLICK THROUGH RATE</th>
                        <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                    {platform.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="Input CTR"
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                            defaultValue={roas.ctr} 
                            onChange={e => handleInputChange(roas, e.target.value, "ctr")}
                          ></input>
                        </td>
                        <td>
                        <button onClick={() => handleUpdate(roas.event_id, roas.type, "ctr")} className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>

                  
                </div>

                <div className="flex flex-column" >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">AGENCY</th>
                        <th scope="col">CLICK THROUGH RATE</th>
                        <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                    {agency.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="Input CTR"
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                            defaultValue={roas.ctr} 
                            onChange={e => handleInputChange(roas, e.target.value, "ctr")}
                          ></input>
                        </td>
                        <td>
                        <button onClick={() => handleUpdate(roas.event_id, roas.type, "ctr")} className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="roas_section p-2" id="revenueros">
            <div className="row">
              <h2
                className="text-center text-decoration-underline p-3 font-semibold"
                style={{ fontSize: "20px" }}
              >
                ROAS : BUDGETS
              </h2>
              <div
                className="col d-flex flex-column justify-content-center align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                     <PieChart
                    series={[
                      {
                        data: platform.map((item, index) => ({
                          id: index,
                          value: item.budget,
                          label: item.name.toUpperCase(),

                          color:
                          item.name.toUpperCase() === "FACEBOOK"
                            ? "#FAF700"
                            :  item.name.toUpperCase() === "INSTAGRAM"
                            ? "#00EBFA"
                            :  item.name.toUpperCase() === "GOOGLE"
                            ? "#FF3600"
                            :  item.name.toUpperCase() === "X (TWITTER)"
                            ? "#FFFFFF"
                            : "#3396B8"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>
                <h4 className="text-center font-semibold">SOCIAL BUDGETS</h4>
              </div>

              <div
                className="col  d-flex flex-column justify-content-center  align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                     <PieChart
                    series={[
                      {
                        data: agency.map((item, index) => ({
                          id: index,
                          value: item.budget,
                          label: item.name.toUpperCase(),

                          color:
                          index === 0
                            ? "#228ee6"
                            :  index === 1
                            ? "#f7e814"
                            :  index === 2
                            ? "#1aed64"
                            :  index === 3
                            ? "#00EBFA"
                            : "red"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>
                <h4 className="text-center font-semibold">AGENCY BUDGETS</h4>
              </div>

              <div className="transparent-table d-flex justify-content-around">
                <div className="flex flex-column" >
                  <table className="table me-5">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">PLATFORM</th>
                        <th scope="col">ALLOCATED BUDGET</th>
                        <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                    {platform.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="Enter Budget"
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                            defaultValue={roas.budget} 
                            onChange={e => handleInputChange(roas, e.target.value, "budget")}
                          ></input>
                        </td>
                        <td>
                        <button onClick={() => handleUpdate(roas.event_id, roas.type, "budget")} className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>

                  
                </div>

                <div className="flex flex-column" >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">AGENCY</th>
                        <th scope="col">ALLOCATED BUDGET</th>
                        <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                    {agency.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="Enter Budget"
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                            defaultValue={roas.budget} 
                            onChange={e => handleInputChange(roas, e.target.value, "budget")}
                          ></input>
                        </td>
                        <td>
                        <button onClick={() => handleUpdate(roas.event_id, roas.type, "budget")} className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="roas_section p-2" id="revenueros">
            <div className="row">
              <h2
                className="text-center text-decoration-underline p-3 font-semibold"
                style={{ fontSize: "20px" }}
              >
                ROAS : CONVERSIONS
              </h2>
              <div
                className="col d-flex flex-column justify-content-center align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                      <PieChart
                    series={[
                      {
                        data: platform.map((item, index) => ({
                          id: index,
                          value: ((item.ctr / item.budget) * 100 / totalPlatformConversion) * 100,
                          label: item.name.toUpperCase(),

                          color:
                          item.name.toUpperCase() === "FACEBOOK"
                            ? "#FAF700"
                            :  item.name.toUpperCase() === "INSTAGRAM"
                            ? "#00EBFA"
                            :  item.name.toUpperCase() === "GOOGLE"
                            ? "#FF3600"
                            :  item.name.toUpperCase() === "X (TWITTER)"
                            ? "#FFFFFF"
                            : "#3396B8"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>

                <h4 className="text-center font-semibold">
                  SOCIAL CONVERSIONS
                </h4>
              </div>

              <div
                className="col  d-flex flex-column justify-content-center  align-items-center"
                style={{ height: "400px", width: "50%", margin: "50px" }}
              >
                <div
                  className="maincircle d-flex justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%" }}
                  >
                     <PieChart
                    series={[
                      {
                        data: agency.map((item, index) => ({
                          id: index,
                          value: ((item.ctr / item.budget) * 100 / totalAgencyConversion) * 100,
                          label: item.name.toUpperCase(),

                          color:
                          index === 0
                            ? "#228ee6"
                            :  index === 1
                            ? "#f7e814"
                            :  index === 2
                            ? "#1aed64"
                            :  index === 3
                            ? "#00EBFA"
                            : "red"
                        })),

                        highlightScope:{
                          faded: "global",
                          highlighted: "item",
                        },
                        faded:{
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        }
                      },
                    ]}
                    width={790}
                    height={250}
                    margin={{ right: 300 }}
                    
                  />
                  </div>
                </div>
                <h4 className="text-center font-semibold">
                  AGENCY CONVERSIONS
                </h4>
              </div>

              <div className="transparent-table d-flex justify-content-around">
                <div className="flex flex-column" >
                  <table className="table me-5">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">PLATFORM</th>
                        <th scope="col">CONVERSION</th>
                        <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {platform.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                           disabled
                            type="text"
                            placeholder="Enter Budget"
                            value={((roas.ctr / roas.budget)*100/totalPlatformConversion)*100 }
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                          ></input>
                        </td>
                        <td>
                        <button className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>

                  
                </div>

                <div className="flex flex-column" >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">SL NO</th>
                        <th scope="col">AGENCY</th>
                        <th scope="col">CONVERSION</th>
                        <th scope="col">CONTROL</th>
                      </tr>
                    </thead>
                    <tbody>
                    {agency.map((roas, index)=>(
                        <tr key = {index}>
                        <th scope="row">{index+1}</th>
                        <td className="pt-3">{roas.name}</td>
                        <td>
                          <input
                            type="text"
                            disabled
                            placeholder="Enter Budget"
                            value={((roas.ctr / roas.budget)*100/totalAgencyConversion)*100 }
                            style={{
                              width: "100%",
                              height: "35px",
                              color: "black",
                              padding: "3px",
                            }}
                          ></input>
                        </td>
                        <td>
                        <button className="btn text-light border">UPDATE</button>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </table>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NightcubeROAS;
