import React from "react";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { CheckCircle, Cancel, PartyModeSharp } from '@mui/icons-material';

import { axiosPrivate } from "../api/axios";

import { useState, useEffect } from "react";

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const EventsFilter = () => {
  const [eventsData, setEventsData] = useState([]);

  const navigate = useNavigate();
  const today = new Date();

  const location = useLocation();
  const { userRole } = location.state;

  const [row, setRow] = useState([]);

  const [mandetoryInfo, setMandetoryInfo] = useState(false);

  const [promoteLimit, setPromoteLimit] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);

  const [form, setForm] = useState({
    event_category: "",
    music_category: "",
    fromDate: null,
    endDate: null
  });

  useEffect(() => {
    form.fromDate = selectedDate;
  }, [selectedDate]);

  useEffect(() => {
    form.endDate = selectedDate2;
  }, [selectedDate2]);



  useEffect(() => {
    let promote_count = 0;
    if(eventsData.length > 0){
      // eslint-disable-next-line array-callback-return
      eventsData.map((event)=>{
        if(event.promote){
          promote_count ++;
        }
      })

      if(promote_count === 5){
        setPromoteLimit(true);
      }else{
        setPromoteLimit(false);
      }
    }
  }, [eventsData])

  const handleDelete =  async (id) =>{
    let userConfirmed = window.confirm("Are You Sure To Delete This Event? ");
  
    if (userConfirmed) {
     const result = await axiosPrivate.delete(`/events/${id}`);
     alert("Event Deleted Succesfully");
  
     getEventsbyFilter();
  
  
    } 
  
  }

  const handlePromote =  async (id, status) =>{

    if(promoteLimit && status){
      alert("You Can Promote Upto 5");
    }else{
      let userConfirmed = window.confirm(`Are You Sure To ${status?'Promote':'Demote'} This Event? `);
  
      if (userConfirmed) {
       const result = await axiosPrivate.patch(`/events/handlePromote/${id}/${status}`);
  
       if(status){
        alert("Event Promoted Succesfully");
       }else{
        alert("Event Demoted Succesfully");
       }
      
       getEventsbyFilter();
      } 
    }


   
  
  }

  
const columns = [
  { field: "id", headerName: "Event ID", width: 150 },
  {
    field: "event_name",
    // approved or not approved
    headerName: "Event Name",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "outlet_id",
    // approved or not approved
    headerName: "Outlet Id",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "outlet_name",
    headerName: "Outlet Name",
    width: 150,
    headerAlign: "center",
    align: 'center',
    renderCell: (params) => {
      const onClick = () => navigate(`/singleOutlet/${params.row.outlet_id}/${params.row.outlet_name}`);
      return <div className="cursor-pointer" onClick={onClick}>{params.row.outlet_name}</div>;
    }
  },

  {
    field: "event_start_time",
    headerName: "Start Time",
    width: 200,
    align: "center",
    headerAlign: "center",
  },

  {
    field: "event_end_time",
    headerName: "End Time",
    width: 200,
    align: "center",
    headerAlign: "center",
  },

  {
    field: 'promote',
    width: 0, // Set width to 0 to hide the column
    hide: true, // Hide the column from the DataGrid
    headerClassName: 'hidden-column', // CSS class to hide header
    cellClassName: 'hidden-column', // CSS class to hide cell
    renderHeader: () => null, // Render nothing for header
    renderCell: () => null // Render nothing for cell
  },

  
  {
    field: "event_created_time",
    headerName: "Posted On",
    width: 200,
    align: "center",
    headerAlign: "center",
  },

  {
    field: "event_address",
    headerName: "Address",
    width: 150,
    align: "center",
    headerAlign: "center",
  },


  {
    field: "event_price",
    headerName: "Price",
    width: 150,
    align: "center",
    headerAlign: "center",
  },

  {
    field: "music_category",
    headerName: "Music Category",
    width: 250,
    align: "center",
    headerAlign: "center",
  },

  {
    field: 'promote',
    width: 0, // Set width to 0 to hide the column
    hide: true, // Hide the column from the DataGrid
    headerClassName: 'hidden-column', // CSS class to hide header
    cellClassName: 'hidden-column', // CSS class to hide cell
    renderHeader: () => null, // Render nothing for header
    renderCell: () => null // Render nothing for cell
  },

  { 
    field: 'contents',
    headerName: 'Contents',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      // Define color based on authentication status
     
      // Define icon based on authentication status
      
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <Button
               onClick={() =>
                navigate(`/images/events/${params.row.id}`)
              }
              className="me-2"
            >
              <ImageSearchIcon />
            </Button>
         
            <Button
              onClick={() =>
              navigate(`/videos/event_videos/${params.row.id}`)
            }
            >
              <PlayCircleIcon />
            </Button>
        
        </div>
      );
    }
  },



 

  { 
    field: 'controls',
    headerName: 'Controls',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      // Define color based on authentication status
     
      // Define icon based on authentication status
      
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              
              {!params.row.promote ?
                   <Button
                   onClick={() =>
                    handlePromote(params.row.id, true)
                  }
                  className="me-2"
                  style={{ backgroundColor: "greenYellow" }}
                >
                  Promote
                </Button>
              :

                  <Button
                  onClick={() =>
                  handlePromote(params.row.id, false)
                }
                className="me-2"
                style={{ backgroundColor: "orange" }}
              >
                Demote
              </Button>
              
              }

          

            <Button
               onClick={() =>
                navigate("/update-event", {
                  state: { eventId: params.row.id , userRole: userRole},
                })
              }
              className="me-2"
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              Edit
            </Button>
         
            {userRole === 'superadmin'  &&
               <Button
               style={{ backgroundColor: "red", color: "white" }}
               onClick = {()=>handleDelete(params.row.id)}
             >
               Delete
             </Button>
              }
        
        </div>
      );
    }
  },


];

  const getEventsbyFilter = async () =>{

    const formData = new FormData();

    for (const key in form) {
      formData.append(key, form[key]);
    }

    const result = await axiosPrivate.post("/events/filter", formData);
    
    setEventsData(result.data);
  }

  const handleSubmit = async () =>{

    if(
        form.event_category.length === 0  &&
        form.music_category.length === 0  &&
        form.fromDate === null &&
        form.endDate === null 

    ){
        setMandetoryInfo(true);
    }
    else{
        await getEventsbyFilter();
        setOpen(false);
    }
  }


  useEffect(()=>{
    let isMounted = true;

    const getEvents = async () =>{
        
        const result = await axiosPrivate.get("/events");
        
        setEventsData(result.data);
    }





    isMounted && getEvents();

    return()=>{
        isMounted = false;
    }
  }, []);

  function convertToLocalTime(timeString) {
    // Convert the string to a JavaScript Date object
    var startDate = new Date(timeString);

    // Convert to local time by adding the UTC offset
    var localTime = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);

    // Extract hours and minutes
    var hours = localTime.getHours();
    var minutes = localTime.getMinutes();

    // Determine AM or PM
    var amOrPm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 12-hour clock format, so convert 0 to 12

    // Format time
    var formattedTime = hours + ":" + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

    return formattedTime;
  }

  useEffect(()=>{
    const rows = eventsData.map(event => ({
        id: event.event_id,
        event_name: event.event_name,
        outlet_id: event.outlet_id,
        outlet_name: event.outlet_name,
        event_start_time: new Date(event.event_start_time).getDate()+'/'+(new Date(event.event_start_time).getMonth()+1)+'/'+new Date(event.event_start_time).getFullYear()+"  "+convertToLocalTime(event.event_start_time),
        event_end_time: new Date(event.event_end_time).getDate()+'/'+(new Date(event.event_end_time).getMonth()+1)+'/'+new Date(event.event_end_time).getFullYear()+"  "+convertToLocalTime(event.event_end_time),
        event_created_time: new Date(event.createdAt).getDate()+'/'+(new Date(event.createdAt).getMonth()+1)+'/'+new Date(event.createdAt).getFullYear()+"  "+convertToLocalTime(event.createdAt),
        event_address: event.event_address,
        description: event.description,
        event_price: event.event_price,
        music_category: event.music_category,
        promote: event.promote
      
      }));

      setRow(rows);
  }, [eventsData]);


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  }

  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  }

  return (
    <div className="parent">
      <div className="mainsection ">
        <div className="container-fluid text-center ">
          <div className="roas_section p-3" id="pos">
            <div className="flex cursor-pointer mb-3" onClick={handleOpen}>
              <div className="flex align-items-center">
                <p
                  className="text-center text-light   font-semibold"
                  style={{ fontSize: "20px" }}
                >
                  FILTER EVENTS
                </p>

                <FilterAltIcon className="ms-2"  />
              </div>
            </div>

            <Box
              sx={{
                height: "auto",
                width: "100%",
              }}
            >
              <DataGrid
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  promote: false,
                  outlet_id : false
                }}
                rows={row}
                columns={columns}
                sx={{ backgroundColor: "white", fontSize: "17px" }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick

                components={{
                  Toolbar: () => (
                    
                    
                    <div>
                      {(userRole === "superadmin") &&
                       <GridToolbar />
                     }
                    </div>
                    
                   
                  ),
                }}
              />
            </Box>


          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="mb-2" style={{ fontSize: "17px" }}>
            Select By Category
          </h1>

          <div className=" p-3 flex justify-normal flex-wrap">
         

           
            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label
                
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
              >
                MUSIC CATEGORY
              </label>
              <select
                name="music_category"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
                value={form.music_category}
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="BOLLYWOOD (हिंदी)">BOLLYWOOD (हिंदी)</option>
                <option value="EDM">EDM</option>
                <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
                <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
                <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
                <option value="DEEP/HOUSE">DEEP/HOUSE</option>
                <option value="CHILL/HOUSE">CHILL/HOUSE</option>
                <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
                <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
                <option value="KANNADA (ಕನ್ನಡ)">KANNADA (ಕನ್ನಡ)</option>
              </select>
            </div>

            <div className="mb-4 me-4" style={{ width: "200px" }}>
              <label
                
                for="exampleFormControlInput1"
                className="form-label mb-2 text-dark"
                value={form.event_category}
              >
                EVENT CATEGORY
              </label>
              <select
                name="event_category"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="">Select</option>
                <option value="LADIES NIGHT">LADIES NIGHT</option>
                <option value="BRUNCH">BRUNCH</option>
                <option value="FRIDAY NIGHT LIVE">FRIDAY NIGHT LIVE</option>
                <option value="SATURDAY NIGHT LIVE">SATURDAY NIGHT LIVE</option>
                <option value="SUNDAY NIGHT LIVE">SUNDAY NIGHT LIVE</option>
                <option value="COSTUME & THEMES">COSTUME & THEMES</option>
                <option value="CONCEPTS">CONCEPTS</option>
                <option value="HAPPY HOURS">HAPPY HOURS</option>
              </select>
            </div>


          </div>

          <div >

          <h1 className="mb-2" style={{ fontSize: "17px" }}>
            Select By Date
          </h1>

            <div className="flex p-3 flex-wrap">
            <div className="mb-4 me-4" style={{ width: "200px" }}>
                <label
                  
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                 From Date
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="mb-4 me-4" style={{ width: "200px" }}>
                <label
                  
                  htmlFor="exampleFormControlInput6"
                  className="form-label mb-2"
                >
                 To Date
                </label>
                <div className="bg-light rounded">
                  <LocalizationProvider
                    className="bg-light"
                    dateAdapter={AdapterDayjs}
                    // style={{ width: "100%" }}
                  >
                    <DatePicker
                      className="w-full container-fluid p-0"
                      label={"Select Date"}
                      id="exampleFormControlInput6"
                      value={selectedDate2}
                      onChange={handleDateChange2}
                      format="DD/MM/YYYY"
                      views={["year", "month", "day"]}
                      required
                      minDate={selectedDate}
                    //   maxDate={today}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
         
          </div>

          {mandetoryInfo &&
           <h1 className="text-danger ms-3">Please Select An Option To Progress</h1>
          }

         

          <div className="flex justify-content-end pe-1">
            <Button 
              onClick={handleSubmit}
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              SUBMIT
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EventsFilter;
