import React from "react";
import event_image_img from "../assets/default_gallery/user.jpg";
import outlet_image_img from "../assets/default_gallery/outlet.jpg";
import artist_video_img from "../assets/default_gallery/artist.jpg";
import Footer from "../components/Footer";
import "./Videocontent.css";
import { Link, useNavigate } from "react-router-dom";

const Content = () => {
  const navigate = useNavigate();
  return (
    <div className="layout mb-5 ">
      <div className="mb-5 ">
        <h1 className="videotext text-center text-light fw-bold">IMAGES, PORTRAITS & AMBIENCE</h1>
        <div className=" allevt_crds-main gap-0 align-items-center justify-content-center">
        <div className=" vvideosection1"  >
            <img src={event_image_img} alt="one" className="threeeventimage" />
            <div className=" eventsectionlefttext">
              <h1 className="image_video_button border-warning"
                onClick={() => navigate("/contentsofshows")} >
                EVENTS
              </h1>
            </div>
          </div>

         <div  className=" vvideosection2">
            <img src={ outlet_image_img} alt="one" className="threeeventimage" />
            <div  className="eventsectionlefttext" >
              <h1 className="image_video_button border-warning"  
                  onClick={() => navigate("/outletcontents")}>
                OUTLETS
              </h1>
            </div>
          </div>


          <div  className=" vvideosection3">
            <img src={artist_video_img} alt="one" className="threeeventimage" />
            <div className="eventsectionlefttext" >
              <h1 className="image_video_button border-warning"
                onClick={() => navigate("/artistcontents")} >
                ARTISTS
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-overlay">
          <Footer />
        </div>
    </div>
  );
};

export default Content;
