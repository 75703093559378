import React from "react";

import nerolifeLogo from "../assets/logos/logo.png";

import GroupAddIcon from "@mui/icons-material/GroupAdd";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect } from "react";

import axios from "../api/axios";

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";

import "../assets/Header.css";

import Avatar from "@mui/material/Avatar";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { IoClose } from "react-icons/io5";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";

import SearchIcon from "@mui/icons-material/Search";

import CircularProgress from "@mui/material/CircularProgress";

import { BASE_URL } from "../constants";

function Header({ userId, userRole, logedIn }) {
  const navigate = useNavigate();

  const auth = useAuth();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [adminopen, setAdminOpen] = React.useState(false);

  const handleadminOpen = () => setAdminOpen(true);
  const handleadminClose = () => setAdminOpen(false);

  const [searchingByContact, setSearchingByContact] = React.useState(false);
  const [searchingByEmail, setSearchingByEmail] = React.useState(false);

  const [searchingByTransactionId, setSearchingByTransactionId] =
    React.useState(false);

  const [searchButtonClicked, setSearchButtonClicked] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState("");

  const [isOtpModelOpen, setIsOtpModelOpen] = React.useState(false);

  const handleOtpModelOpen = () => setIsOtpModelOpen(true);
  const handleOtpModelClose = () => setIsOtpModelOpen(false);

  const [artistData, setArtistData] = useState({});
  const [outletData, setoutletData] = useState({});
  const [customerData, setCustomerData] = useState({});

  const [userData, setUserData] = useState({});

  const [initial, setInitial] = useState();

  const [nightcubeModal, setnightcubeModal] = React.useState(false);

  const handleNightCubeOpen = () => setnightcubeModal(true);
  const handleNightCubeClose = () => setnightcubeModal(false);

  const [outletModal, setOutletModal] = React.useState(false);

  const handleOutletOpen = () => setOutletModal(true);
  const handleOutletClose = () => setOutletModal(false);

  const [contact, setContact] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [sentOtp, setSentOtp] = useState();
  const [receivedOtp, setReceivedOtp] = useState();

  const [loading, setLoading] = useState(false);

  const handleloadingOpen = () => setLoading(true);
  const handleloadingClose = () => setLoading(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "rgba(0,0,0,0.5)",
    boxShadow: 24,
    color: "white",
    p: 4,
  };

  const logout = useLogout();

  const refreshToken = localStorage.getItem("refreshToken");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let isMounted = true;

    const getArtistData = async () => {
      try {
        const response = await axios.get(`/artists/${userId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });

        setArtistData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    const getOutletData = async () => {
      try {
        const response = await axios.get(`/outlets/${userId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });

        setoutletData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    const getCustomerData = async () => {
      try {
        if (userRole !== "artist" || userRole !== "outlet") {
          const response = await axios.get(`/customers/${userId}`, {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          });

          setCustomerData(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (isMounted && userRole) {
      userRole === "artist"
        ? getArtistData()
        : userRole === "outlet"
        ? getOutletData()
        : getCustomerData();
    }
  }, [userId, userRole]);

  const handleLogout = async () => {
    await logout();
    // setLogedIn(false);
    navigate("/");
    setInitial("");
    setAnchorEl(null);
  };

  const handlenightCubeRoute = async () => {
    if (userRole === "user" || userRole === "artist") {
      handleOutletOpen();
    } else if (userRole === "outlet" && !outletData.authentication_status) {
      handleOutletOpen();
    } else {
      if (userRole === "superadmin" || userRole === "outlet") {
        navigate("/nightcube");
      } else {
        let nightcubeAccess = localStorage.getItem("NightcubeAccess");
        if (nightcubeAccess) {
          navigate("/nightcube");
        } else {
          if (logedIn) {
            handleSendOTP();
          } else {
            handleOutletOpen();
          }
        }
      }
    }
  };

  const axiosPrivate = useAxiosPrivate();

  const location = useLocation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleContactClicked = () => {
    setSearchingByContact(true);
    setSearchingByEmail(false);
  };

  const handleEmailClicked = () => {
    setSearchingByEmail(true);
    setSearchingByContact(false);
  };

  const handleSearchbyEmail = async () => {
    setSearchButtonClicked(true);
    try {
      const result = await axiosPrivate.get(`/user/email/${email}`);
      if (result.status === 200) {
        setUserData(result.data);
      } else if (result.status === 400) {
        setUserData({});
      } else {
        // Handle other types of errors here
      }
    } catch (err) {
      console.error(err);
      setUserData({});
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const handleSearchbyContact = async () => {
    setSearchButtonClicked(true);
    try {
      const result = await axiosPrivate.get(`/user/phone/${contact}`);
      if (result.status === 200) {
        setUserData(result.data);
      } else if (result.status === 400) {
        setUserData({});
      } else {
      }
    } catch (err) {
      console.error(err);
      setUserData({});
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getInitials = async () => {
      let userName = localStorage.getItem("userName");
      if (userName) {
        const words = userName.split(" ");
        const firstTwoWords = words.slice(0, 2);
        const initials = firstTwoWords.map((word) => word[0]).join("");

        setInitial(initials);

        return true;
      }
    };

    if (isMounted && (userRole !== "outlet" || userRole !== "artist")) {
      getInitials();
    }
    return () => {
      isMounted = false;
    };
  }, [userRole, initial, userId]);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar() {
    return {
      sx: {
        bgcolor: "red",
        width: 35,
        height: 35,
      },
      children: `${initial ? initial[0] : "Y"}${initial ? initial[1] : "N"}`,
      //  children: `AN`,
    };
  }

  const handleModalClosed = () => {
    setSearchButtonClicked(false);
    handleClose();
  };

  const handleAdmin = async (role) => {
    setSelectedRole(role);
    let userConfirmed = window.confirm(
      `Are You Sure You Want To ${
        role === "user" ? "Remove" : "Define"
      } This Admin? `
    );

    if (userConfirmed) {
      handleSendOTP();
    }
  };

  const handleOTP = async () => {
    const receivedOtpNumber = Number(receivedOtp);

    if (sentOtp === receivedOtpNumber) {
      setReceivedOtp(0);
      if (adminopen) {
        const result = await axiosPrivate.patch(
          `/user/admin/${userData.email}/${selectedRole}`
        );

        if (result.status === 200) {
          alert(
            `Administrative Status Updated : ${
              selectedRole === "admin1"
                ? "UEA Admin"
                : selectedRole === "admin2"
                ? "OPS Admin"
                : "Admin Removed"
            }`
          );
          handleOtpModelClose();
        }
      } else {
        navigate("/nightcube");
        localStorage.setItem("NightcubeAccess", true);
        handleOtpModelClose();
      }
    } else {
      alert("OTP Invalid, Please try again ");
    }
  };

  const handleSendOTP = async () => {
    await sendOTPToBackend("sys.nightcube.org@gmail.com");
  };

  const sendOTPToBackend = async (email) => {
    setLoading(true);
    const response = await axiosPrivate.post("/user/sendOtp", {
      email: email,
    });
    if (response.status === 200) {
      handleloadingClose();
      const otp = await response.data;

      alert("OTP has been sent to your email.");
      setSentOtp(otp);
      handleOtpModelOpen();
    } else {
      alert("Network Error.");
    }
  };

  return (
    <React.Fragment>
      <div className="header" style={{padding:"0px 10px",background:"black", zIndex:"999", position:"sticky"}}>
        <nav>
          <div className="d-flex justify-content-between align-items-center">
            
            <div onClick={() => navigate("/")} 
                className="headerleft d-flex justify-content-start align-items-center cursor-pointer" >
              <img alt="logo" src={nerolifeLogo} height="60px" width="60px" />
              <h4 className="text-light">NEROLIFE</h4>
            </div>

            <div>
              <div className="rightsidelinks">
                <ul className="nav justify-content-end d-flex align-items-center flex-wrap">
                  <li className="nav-item">
                    <a className="nav-link text-light cursor-pointer"
                      onClick={() => navigate("/events")} >
                      EVENTS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-light cursor-pointer"
                      onClick={() => navigate("/outlets")} >
                      OUTLETS
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link text-light cursor-pointer"
                      onClick={() => navigate("/artists")} >
                      ARTISTS
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link text-light cursor-pointer"
                      onClick={handlenightCubeRoute}  >
                      NIGHTCUBE
                    </a>
                  </li>

                  <li className="nav-item">
                    <div className="">
                      <a className="dropdown-toggle text-decoration-none text-light mx-3 gallerybtn"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"  >
                        GALLERY
                      </a>
                      <ul className="dropdown-menu"
                        style={{ backgroundColor: "rgba(255, 255, 255, 0)" }} >
                        <li className="nav-item">
                          <a className="nav-link text-light cursor-pointer"
                            onClick={() => navigate("/images")} >
                            IMAGES
                          </a>
                        </li>

                        <li className="nav-item">
                          <a className="nav-link text-light cursor-pointer"
                            onClick={() => navigate("/videos")} >
                            VIDEOS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-item me-3">
                    <a
                      className="nav-link text-light cursor-pointer"
                      onClick={() =>
                        navigate("/stories", { state: { userRole: userRole } })
                      } >
                      STORIES
                    </a>
                  </li>

                  <li>
                    {logedIn ? (
                      <div className="menu-items ">
                        <Tooltip title="Account settings">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{}}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined} >
                            {userRole === "artist" ? (
                              <Avatar
                                alt={artistData?.artist_name}
                                src={`${BASE_URL}${artistData.artist_profile_icon}`}
                              />
                            ) : userRole === "outlet" ? (
                              <Avatar
                                alt={outletData?.artist_name}
                                src={`${BASE_URL}${outletData.outlet_icon}`}
                              />
                            ) : customerData.authentication_status &&
                              customerData?.customer_profile_image ? (
                              <Avatar
                                src={`${BASE_URL}${customerData?.customer_profile_image}`}
                              />
                            ) : (
                              <Avatar {...stringAvatar()} />
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="flex align-items-center mr-3">
                        <a
                          onClick={() => navigate("/login")}
                          className="menu-items cursor-pointer" >
                          <GroupAddIcon
                            style={{ color: "white" }}
                          ></GroupAddIcon>
                        </a>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
              <div className="headerright">
                <a className="btn btn-sm btn-light"
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions"
                  aria-controls="offcanvasWithBothOptions" >
                  {/* <img src="/images/menu.png"  height="20px" width="20px"/> */}
                  <MenuIcon />
                </a>

                <div style={{ backgroundColor: 
                    "#060606ba",
                    width:"80%"
                    // "rgba(255, 255, 255, 0.5)" 
                  }}
                  className="offcanvas offcanvas-start"
                  data-bs-scroll="true"
                  tabIndex="-1"
                  id="offcanvasWithBothOptions"
                  aria-labelledby="offcanvasWithBothOptionsLabel">
                  <div className="offcanvas-header">
                    <button
                      type="button"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">
                      <IoClose  style={{color:"white", fontSize:"35px", margin:"10px 0px"}}/>
                    </button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="nav flex-column ">
                      <li className="nav-item">
                        <a className="nav-link text-light fw-bold"
                          href="/events" >
                          EVENTS
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-light fw-bold"
                          // onClick={() => navigate("/outlets")}
                          href="/outlets">
                          OUTLETS
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-light  fw-bold"
                          // onClick={() => navigate("/artists")}
                          href="/artists" >
                          ARTISTS
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-light fw-bold"
                          onClick={handlenightCubeRoute} >
                          NIGHTCUBE
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className="nav-link text-light fw-bold"
                          onClick={() => navigate("/outlets")}
                        >
                          OUTLETS
                        </a>
                      </li> */}
                      <li className="nav-item">
                        <div className="nav-link mt-1 mb-1 mobile-gallery">
                          <a className="dropdown-toggle text-decoration-none text-light fw-bold"
                            data-bs-toggle="dropdown"
                            aria-expanded="false" >
                            GALLERY
                          </a>
                          <ul className="dropdown-menu"
                            style={{
                              backgroundColor: "rgba(255, 255, 255, 0.8)",
                            }} >
                            <li className="nav-item">
                              <a className="nav-link text-dark fw-bold"
                                // onClick={() => navigate("/images")}
                                href="/images" >
                                IMAGES
                              </a>
                            </li>

                            <li className="nav-item">
                              <a className="nav-link text-dark fw-bold"
                                // onClick={() => navigate("/videos")}
                                href="/videos" >
                                VIDEOS
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link text-light fw-bold"
                          onClick={() =>
                            navigate("/stories", { state: { userRole: userRole } })}
                           >
                          STORIES
                        </a>
                      </li>
                      <li className="nav-item mt-1">
                        {logedIn ? (
                          <div className="nav-link menu-items ">
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{}}
                                aria-controls={open ? "account-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined} >
                                {userRole === "artist" ? (
                                  <Avatar
                                    alt={artistData?.artist_name}
                                    src={`${BASE_URL}${artistData.artist_profile_icon}`}
                                  /> 
                                ) : userRole === "outlet" ? (
                                  <Avatar
                                    alt={outletData?.artist_name}
                                    src={`${BASE_URL}${outletData.outlet_icon}`}
                                  />
                                ) : customerData.authentication_status &&
                                  customerData?.customer_profile_image ? (
                                  <Avatar
                                    src={`${BASE_URL}${customerData?.customer_profile_image}`}
                                  />
                                ) : (
                                  <Avatar {...stringAvatar()} />
                                )}
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="nav-link menu-items ">
                            <a
                              onClick={() => navigate("/login")}
                              className="cursor-pointer" >
                              <GroupAddIcon
                                style={{ color: "white" }}
                              ></GroupAddIcon>
                            </a>
                          </div> 
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="text-warning"></hr>
        </nav>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() =>
              userRole === "outlet"
                ? navigate("/outletProfile")
                : userRole === "artist"
                ? navigate("/artistProfile")
                : navigate("/profile")
            }
          >
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>

          {userRole === "superadmin" && (
            <MenuItem onClick={handleadminOpen}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="small" />
              </ListItemIcon>
              Manage Admins
            </MenuItem>
          )}

          {userRole === "superadmin" && (
            <MenuItem onClick={() => navigate("/underconstruction")}>
              <ListItemIcon>
                <SupervisorAccountIcon fontSize="small" />
              </ListItemIcon>
              Manage Partners
            </MenuItem>
          )}

          {userRole === "outlet" && (
            <MenuItem onClick={() => navigate("/underconstruction")}>
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="small" />
              </ListItemIcon>
              Manage Officials
            </MenuItem>
          )}

          <MenuItem
            onClick={() =>
              navigate("/settings", {
                state: { userId: userId, userRole: userRole },
              })
            }
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>

        <Modal
          open={nightcubeModal}
          onClose={handleNightCubeClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="border border-warning rounded" sx={style}>
            <h1
              className="text-center text-danger font-semibold"
              style={{ fontSize: "30px" }}
            >
              ERROR - 401
            </h1>
            <div className="mt-4">
              <h2 style={{ fontSize: "25px" }}>
                Access Restricted To Administrative Only
              </h2>
            </div>
          </Box>
        </Modal>

        <Modal
          open={outletModal}
          onClose={handleOutletClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="border border-warning rounded" sx={style}>
            <div className="mt-2">
              <h2 style={{ fontSize: "17px" }}>
                We are sorry, but it seems that you're not currently eligible to
                access this feature. <br />
                <br /> If you have any questions or need assistance, feel free
                to reach out to our support team for more information.
              </h2>
            </div>
          </Box>
        </Modal>

        <Modal
          open={adminopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="border-warning rounded"
            sx={style}
            style={{ position: "relative" }}
          >
            {userRole === "outlet" ? (
              <h1 className="mx-2" style={{ fontSize: "17px" }}>
                ENTER STAFF OFFICIAL DETAILS
              </h1>
            ) : (
              <h1 className="mx-2" style={{ fontSize: "17px" }}>
                ENTER ADMIN DETAILS
              </h1>
            )}

            <CloseIcon
              className="closeButton"
              onClick={handleadminClose}
              style={{ position: "absolute", top: "5px", right: "5px" }}
            />

            {searchingByTransactionId ? (
              <div className="mt-4 mx-2" style={{ width: "30%" }}>
                <label
                  style={{ color: "white" }}
                  for="exampleFormControlInput1"
                  className="form-label mb-2"
                >
                  TRANSACTION ID:
                </label>
                <div className="input-group mb-3">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="transaction_id"
                    placeholder="Transaction Id"
                    //  onChange={(e)=>setContact(e.target.value)}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={userData.transaction_id}
                    style={{ backgroundColor: "#d1cdcd" }}
                  />
                  <span
                    //  onClick={handleSearchbyContact}
                    className="input-group-text cursor-pointer"
                    id="basic-addon2"
                  >
                    <SearchIcon />
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex  mt-3 mx-2 " style={{ height: "50px" }}>
                <Button
                  className="rounded me-3"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={handleContactClicked}
                >
                  Search By Contact
                </Button>
                <Button
                  className="rounded"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={handleEmailClicked}
                >
                  Search By Email
                </Button>
              </div>
            )}

            {searchingByContact && (
              <div className="mt-4 mx-2" style={{ width: "60%" }}>
                <label
                  style={{ color: "white" }}
                  for="exampleFormControlInput1"
                  className="form-label mb-2"
                >
                  ENTER CONTACT NUMBER:
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    placeholder="Contact Number"
                    onChange={(e) => setContact(e.target.value)}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={contact}
                  />
                  <span
                    onClick={handleSearchbyContact}
                    className="input-group-text cursor-pointer"
                    id="basic-addon2"
                  >
                    <SearchIcon />
                  </span>
                </div>
              </div>
            )}

            {searchingByEmail && (
              <div className="mt-4 mx-2" style={{ width: "60%" }}>
                <label
                  style={{ color: "white" }}
                  for="exampleFormControlInput1"
                  className="form-label mb-2"
                >
                  ENTER EMAIL ID:
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email ID"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <span
                    onClick={handleSearchbyEmail}
                    className="input-group-text cursor-pointer"
                    id="basic-addon2"
                  >
                    <SearchIcon />
                  </span>
                </div>
              </div>
            )}

            {searchButtonClicked && (
              <div>
                <div className="flex justify-content-between">
                  <div style={{ width: "90%" }}>
                    <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        VALIDITY:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          style={{ backgroundColor: "#d1cdcd" }}
                          type="text"
                          disabled
                          className="form-control"
                          name="validity"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={
                            Object.keys(userData).length > 0
                              ? "Valid"
                              : "Invalid"
                          }
                        />
                      </div>
                    </div>

                    {Object.keys(userData).length > 0 && (
                      <div>
                        <div className="mt-3 mx-2">
                          <label
                            style={{ color: "white" }}
                            for="exampleFormControlInput1"
                            className="form-label mb-2"
                          >
                            CUSTOMER NAME:
                          </label>
                          <div className="input-group mb-3">
                            <input
                              style={{ backgroundColor: "#d1cdcd" }}
                              type="text"
                              disabled
                              className="form-control"
                              name="customer_name"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={
                                userData.first_name + " " + userData.last_name
                              }
                            />
                          </div>
                        </div>

                        <div className="mt-3 mx-2">
                          <label
                            style={{ color: "white" }}
                            for="exampleFormControlInput1"
                            className="form-label mb-2"
                          >
                            CONTACT:
                          </label>
                          <div className="input-group mb-3">
                            <input
                              style={{ backgroundColor: "#d1cdcd" }}
                              type="text"
                              disabled
                              className="form-control"
                              name="contact"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={userData.phone}
                            />
                          </div>
                        </div>

                        {!searchingByTransactionId && (
                          <div>
                            <div className="mt-3 mx-2">
                              <label
                                style={{ color: "white" }}
                                for="exampleFormControlInput1"
                                className="form-label mb-2"
                              >
                                EMAIL ID:
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  style={{ backgroundColor: "#d1cdcd" }}
                                  type="text"
                                  disabled
                                  className="form-control"
                                  name="email"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                  value={userData.email}
                                />
                              </div>
                            </div>

                            <div className="mt-3 mx-2">
                              <label
                                style={{ color: "white" }}
                                for="exampleFormControlInput1"
                                className="form-label mb-2"
                              >
                                GENDER:
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  style={{ backgroundColor: "#d1cdcd" }}
                                  type="text"
                                  disabled
                                  className="form-control"
                                  name="gender"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                  value={userData.gender}
                                />
                              </div>
                            </div>

                            <div className="mt-3 mx-2">
                              <label
                                style={{ color: "white" }}
                                for="exampleFormControlInput1"
                                className="form-label mb-1"
                              >
                                DEFINE ROLE:
                              </label>

                              {userRole !== "outlet" ? (
                                <div
                                  className=" flex  mt-1"
                                  style={{ height: "50px" }}
                                >
                                  {userData.role === "admin1" && (
                                    <div>
                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("admin2")}
                                      >
                                        ops admin
                                      </Button>

                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("user")}
                                      >
                                        Remove Admin
                                      </Button>
                                    </div>
                                  )}

                                  {userData.role === "admin2" && (
                                    <div>
                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("admin1")}
                                      >
                                        uea admin
                                      </Button>

                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("user")}
                                      >
                                        Remove Admin
                                      </Button>
                                    </div>
                                  )}

                                  {userData.role === "user" && (
                                    <div>
                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("admin1")}
                                      >
                                        uea admin
                                      </Button>

                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("admin2")}
                                      >
                                        ops admin
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className=" flex  mt-1"
                                  style={{ height: "50px" }}
                                >
                                  {userData.role === "official1" && (
                                    <div>
                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("official2")}
                                      >
                                        official 1
                                      </Button>

                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("user")}
                                      >
                                        Remove Admin
                                      </Button>
                                    </div>
                                  )}

                                  {userData.role === "official2" && (
                                    <div>
                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("official1")}
                                      >
                                        official 1
                                      </Button>

                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("user")}
                                      >
                                        Remove Admin
                                      </Button>
                                    </div>
                                  )}

                                  {userData.role === "user" && (
                                    <div>
                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("official1")}
                                      >
                                        official 1
                                      </Button>

                                      <Button
                                        className="rounded me-3 "
                                        style={{
                                          backgroundColor: "#007bff",
                                          fontWeight: "600",
                                          color: "white",
                                        }}
                                        onClick={() => handleAdmin("official2")}
                                      >
                                        official 2
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {Object.keys(userData).length === 0 && (
                  <div className="mx-2 mt-3">
                    <h1 style={{ fontSize: "17px" }}>USER NOT FOUND</h1>
                    <p className="mt-2 ">
                      It Appears To Be This USER Is Not Available In The System
                      due to USER Account Pending. Kindly Activate Account.
                    </p>

                    <Button
                      className="rounded mt-3"
                      style={{ backgroundColor: "#007bff", color: "white" }}
                      onClick={() => navigate("/nightcube/survey-form")}
                    >
                      Add Customer
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Box>
        </Modal>

        <Modal
          open={isOtpModelOpen}
          onClose={handleOtpModelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h3 className="fs-3 mb-3">Authenticate With OTP</h3>
            <div className=" mx-2 mb-3">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label text-dark mb-2"
              >
                Enter OTP:
              </label>
              <input
                type="number"
                name="otp"
                placeholder="Enter OTP"
                onChange={(e) => setReceivedOtp(e.target.value)}
                className="form-control"
                value={receivedOtp}
              />
            </div>

            <div className="mt-3 flex justify-content-end">
              <Button
                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleOTP}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={loading}
          onClose={handleloadingClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="flex align-items-center" sx={style}>
            <CircularProgress />

            <div>
              <p className="ms-2" style={{ fontSize: "15px" }}>
                Sending OTP ...
              </p>
            </div>
          </Box>
        </Modal>
      </div>

    </React.Fragment>
  );
}

export default Header;

// const Header = () => {
//   const [isMenuOpen, setMenuOpen] = useState(false);

//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const toggleMenu = () => {
//     setMenuOpen(!isMenuOpen);
//   };

//   return (
//     <nav className="bg-transparent py-4">
//       <div className="container mx-auto flex  justify-between">
//         <div className="flex ">
//           <img src={nerolifeLogo} alt="Logo" className="h-8" />
//           <span className="text-white ml-2 text-lg font-semibold">
//             NEROLIFE
//           </span>
//         </div>

//         <div className="hidden md:flex space-x-4">
//           <div className="p-2">
//             <a href="/" className="menu-items">
//               HOME
//             </a>
//           </div>

//           <div className="p-2">
//             <a href="/events" className="menu-items">
//               EVENTS
//             </a>
//           </div>

//           <div className="p-2">
//             <a href="/outlets" className="menu-items">
//               OUTLETS
//             </a>
//           </div>
//           <div className="p-2">
//             <a href="/artists" className="menu-items">
//               ARTISTS
//             </a>
//           </div>
//           <div className="p-2">
//             <a href="/nightcube" className="menu-items">
//               NIGHTCUBE
//             </a>
//           </div>
//           <div className="p-2">
//             <button
//               className="menu-items"
//               onClick={toggleDropdown}
//               clbuttonssName="menu-items"
//             >
//               GALLERY
//             </button>
//             {isDropdownOpen && (
//   <div className='p-2'>
//   <button onClick={toggleDropdown}  className='menu-items'>GALLERY</button>
//   {isDropdownOpen && (
//   <div className="dropdown-content">
//     {/* Your dropdown items go here */}
//     <div className="border ms-2 flex align-items-center justify-content-center gallerybuttons cursor-pointer" >
//     <a href="/events"  className='menu-items'>IMAGES</a>
//     </div>
//     <div>
//       <a className="menu-items" href="/videos">
//         videos
//       </a>
//     </div>
//   </div>
// )}
// </div>
//             )}
//           </div>
//           <div className="p-2">
//             <a href="/partners" className="menu-items">
//               STORIES
//             </a>
//           </div>
//           <div className="m-2">
//             <a href="/login" className="menu-items">
//               <GroupAddIcon style={{ color: "white" }}></GroupAddIcon>
//             </a>
//           </div>
//         </div>

//         {/* Mobile Menu Icon */}
//         <div className="md:hidden">
//           <button
//             onClick={toggleMenu}
//             className="text-white focus:outline-none"
//           >
//             <svg
//               className="h-6 w-6"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="https://www.w3.org/2000/svg"
//             >
//               {isMenuOpen ? (
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M6 18L18 6M6 6l12 12"
//                 ></path>
//               ) : (
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M4 6h16M4 12h16m-7 6h7"
//                 ></path>
//               )}
//             </svg>
//           </button>
//         </div>
//       </div>

//       {/* Mobile Menu */}
//       {isMenuOpen && (
//         <div className="md:hidden bg-gray-800 p-4 absolute top-16 right-0 left-0 z-10">
//           <a href="#" className="block text-white py-2">
//             Home
//           </a>
//           <a href="#" className="block text-white py-2">
//             Login
//           </a>
//           <a href="#" className="block text-white py-2">
//             Register
//           </a>
//           <a href="#" className="block text-white py-2">
//             Events
//           </a>
//           <a href="#" className="block text-white py-2">
//             Logout
//           </a>
//         </div>
//       )}
//     </nav>
//   );
// };

// export default Header;
