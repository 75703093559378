import React from "react";

import "./Outlets.css";
import Footer from "../components/Footer";

import { useInView } from "react-intersection-observer";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios, { axiosPrivate } from "../api/axios";

import Box from "@mui/material/Box";

import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";

import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import useAuth from "../hooks/useAuth";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import { BASE_URL } from "../constants";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";


const Eventsimages = () => {
  const { id, type } = useParams();

  const [filteredOutlet, setFilteredOutlet] = useState([]);

  const [allImages, setAllImages] = useState([]);

  const [userRole, setUserRole] = useState("");

  const [open, setOpen] = React.useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const [imageId, setImageId] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { auth } = useAuth();

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const itemsPerPage = 15;
  const [page, setPage] = React.useState(1);
  const totalPages = Math.ceil(allImages.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOutlet = allImages.slice(startIndex, endIndex);
  const handleChange1 = (event, value) => {
    setPage(value);
  };

  
  const getallImages = async (type) => {
    try {
      const response = await axios.get(`images/${type}/${id}`, {});
      console.log(response.data)
      setAllImages(response.data);
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleChange = (e) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (allowedTypes.includes(e.target.files[0].type)) {
      setSelectedImage(e.target.files[0]);
    } else {
      alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        let userId = await localStorage.getItem("userId");
        console.log("-------->",userId)
        if (!userId) return;
        const response = await axiosPrivate.get(`/user/${userId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setUserRole(response.data.role);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getUserData();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (type === "events") {
      isMounted && getallImages("event_images");
    } else if (type === "outlets") {
      isMounted && getallImages("ambience_images");
    } else {
      isMounted && getallImages("portrait_images");
    }

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, [auth.userRole, type]);

  const handleDelete = async (id) => {
    let deleteConfirmed = window.confirm(
      "Are You Sure To Delete This Image ? "
    );

    if (deleteConfirmed) {
      const result = await axiosPrivate.delete(`/images/delete/${id}`);

      alert("Image Deleted Successfully");

      if (type === "events") {
        getallImages("event_images");
      } else if (type === "outlets") {
        getallImages("ambience_images");
      } else {
        getallImages("portrait_images");
      }
    }
  };

  const handleReplace = (id) => {
    setImageId(id);
    handleOpen();
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("icon", selectedImage);

    if (!selectedImage) {
      alert("Please Select Image to Proceed");
      return;
    }

    const response = await axiosPrivate.put(
      `/images/replace/${imageId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      alert("Image Replaced Successfully");
      // Assuming you have the `navigate` function from your router
      //   resetForm();

      setOpen(false);

      if (type === "events") {
        getallImages("event_images");
      } else if (type === "outlets") {
        getallImages("ambience_images");
      } else {
        getallImages("portrait_images");
      }
    } else {
      alert("Failed to Replace Images");
    }
  };

  return (
    <div className="layout mb-5">
   

      <div className="row justify-between">
        <div className="col-md-5">
          <div className="flex align-items-center justify-content-center p-5">
            <div>
              {type === "events" ? (
                <h1
                  ref={ref}
                  className={`animated-element ${
                    inView ? "animate" : ""
                  }  text-light fw-bold fs-3 mt-2 mb-2`}
                  style={{padding:"200px"}}
                >
                  IMAGES
                </h1>
              ) : type === "outlets" ? (
                <h1
                  ref={ref}
                  className={`animated-element ${
                    inView ? "animate" : ""
                  } text-light fw-bold fs-3 mt-2 mb-2`}
                  style={{padding:"200px"}}
                >
                  AMBIENCE
                </h1>
              ) : (
                <h1
                  ref={ref}
                  className={`animated-element ${
                    inView ? "animate" : ""
                  }  text-light fw-bold fs-3 mt-2 mb-2`}
                  style={{padding:"200px"}}
                >
                  PORTRAITS
                </h1>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <Slider {...settings}>
            {allImages?.map((image, index) => (
              <div key={index} style={{ height: "", width: "100%" }}>
                <img
                  src={`${BASE_URL}${image.icon}`}
                  alt=""
                  className="img-abmience-portraits"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="row justify-content-center mt-5 mb-4">
        {allImages.map((image, index) => (
          <div key={index} className="col-md-3 position-relative mb-4">
            <img
              src={`${BASE_URL}${image.icon}`}
              alt="one"
              className="img-fluid"
            />

            {userRole === "superadmin" && (
              <div
                className="d-flex"
                style={{
                  height: "40px",
                  width: "80px",
                  position: "absolute",
                  bottom: "15px",
                  right: "0",
                }}
              >
                <div
                  onClick={() => handleReplace(image._id)}
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "100%",
                    width: "40px",
                    backgroundColor: "greenyellow",
                    cursor: "pointer",
                  }}
                >
                  <PublishedWithChangesIcon />
                </div>

                <div
                  onClick={() => handleDelete(image._id)}
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "100%",
                    width: "40px",
                    backgroundColor: "black",
                    cursor: "pointer",
                  }}
                >
                  <DeleteForeverIcon style={{ color: "white" }} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            sx={{
              "& .MuiPaginationItem-root": { color: "white", fontSize: "17px" },
            }}
            count={totalPages}
            page={page}
            onChange={handleChange1}
          />
        </Stack>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="mx-auto  ">
            <div className="mt-3 mx-2 mb-3">
              <label for="exampleFormControlInput1" className="form-label mb-2">
                Select Image To Replace
              </label>
              <input
                type="file"
                multiple
                name="poster_url"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                required
              />
            </div>

            <div
              onClick={handleSubmit}
              className="mx-2 mt-4 flex align-items-center justify-content-center btnstyles"
            >
              <h1 className="text-white" style={{ fontSize: "20px" }}>
                {" "}
                SUBMIT{" "}
              </h1>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Eventsimages;
