import "./App.css";
import Layout from "./components/Layout";
import Homepage from "./components/Homepage";
import Events from "./pages/Events";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { useMode } from "./theme";
import Allevents from "./pages/Allevents";
import Outlets from "./pages/Outlets";
import Login from "./pages/Login";
import Artists from "./pages/Artists";
import Singleevent from "./pages/Singleevent";
import Content from "./pages/Content";
import Contentsofshows from "./pages/Contentsofshows";
import Eventsimages from "./pages/Eventsimages";
import Outletcontent from "./pages/Outletcontent";
import Outletcontent2 from "./pages/Outletcontent2";
import Artistcontent from "./pages/Artistcontent";
import AmbienceExperience from "./pages/AmbienceExperience";
import PotraitExperience from "./pages/PotraitExperience";
import Videocontent from "./pages/Videocontent";
import Shows from "./pages/Shows";
import Artistcontent2 from "./pages/Artistcontent2";
import Eventsvideos from "./pages/Eventsvideos";
import ArtistVideos from "./pages/ArtistVideos";
import OutletVideos from "./pages/OutletVideos";
import UsersLogin from "./pages/UsersLogin";
import UserRegister from "./pages/UserRegister";
import OutletRegister from "./pages/OutletRegister";
import OutletsInsights from "./pages/OutletsInsights";
import SingleOutlet from "./pages/SingleOutlet";
import Settings from "./pages/Settings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CompanyProfile from "./pages/CompanyProfile";
import SingleArtist from "./pages/SingleArtist";

import PersistLogin from "./components/PersistLogin";
import UserProfilePage from "./pages/UserProfilePage";
import RequireAuth from "./components/RequireAuth";
import { useEffect, useState } from "react";

import Userservey from "./pages/Userservey";

import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import Header from "./components/Header";

import backgroundVideo from "./assets/background/home_bg.mp4";

import CustomerFilter from "./pages/CustomerFilter";
import EventsFilter from "./pages/EventsFilter";

import OutletsFilter from "./pages/OutletsFilter";
import ArtistFilter from "./pages/ArtistFilter";

import ArtistInsights from "./pages/ArtistInsights";
import OutletProfile from "./pages/OutletProfile";
import ArtistProfile from "./pages/ArtistProfile";
import AddEvent from "./pages/Addevent";

import TermsandCondition from "./pages/TermsandCondition";
import AboutUS from "./pages/AboutUs";

import NightcubeSystems from "./pages/NightcubeSystems";
import EventManagement from "./pages/EventManagement";
// import PreLanding from './components/Prelanding';

import OutletMangement from "./pages/OutletManagement";

import CustomerMangement from "./pages/CustomerMangement";
import ArtistMangement from "./pages/ArtistMangement";

import DefaultNightcube from "./pages/DefaultNightcube";

import DummyCalendar from "./pages/DummyCalendar";

import NightcubeROAS from "./pages/NightcubeROAS";
import NightcubePOS from "./pages/NightcubePOS";

import Gallery from "./pages/Gallery";

import SurveyForm from "./pages/SurveyForm";

import NightcubeInbox from "./pages/NightcubeInbox";
import axios, { axiosPrivate } from "./api/axios";

import Stories from "./pages/Stories";

import ConstructionPage from "./pages/ConstructionPage";
import PageNotFound from "./pages/PageNotFound";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

function App() {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate();
  const refreshToken = localStorage.getItem("refreshToken");

  const [isLogedIn, setIsLogedIn] = useState(false);

  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const persist = localStorage.getItem("persist");

    const handleBeforeUnload = (event) => {
      if (!persist || persist === "false") {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
      }
    };

    if (refreshToken) {
      setIsLogedIn(true);
    } else {
      setIsLogedIn(false);
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [refreshToken]);

  useEffect(() => {
    let isMounted = true;

    const getUserData = async () => {
      try {
        let userId = localStorage.getItem("userId");
        if (!userId) return;
        const response = await axios.get(`/user/${userId}`);

        if (userId !== null) {
          setUserId(userId);
          setUserRole(response.data.role);
        }
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && isLogedIn && getUserData();

    return () => {
      isMounted = false;
    };
  }, [refreshToken, isLogedIn]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="background-video-container">
        <video
          autoPlay
          muted
          loop
          className="w-full h-full object-cover absolute top-0 left-0 z-[-1] background-video"
        >
          <source
            src={backgroundVideo} // Replace with your video file path
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div>
          <Header userId={userId} userRole={userRole} logedIn={isLogedIn} />
        </div>

        <div className="flex-1">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route element={<Header />} />
              <Route path="/" element={<Homepage />} />
              <Route path="/homepage" element={<Homepage />} />
              <Route path="/login"  element={<Login />}
              />
              <Route path="/outlets" element={<Outlets />} />
              <Route path="/events" element={<Events />} />
              <Route
                path="/allevents/:eventCategory/:musicCategory?"
                element={<Allevents />}
              />
              <Route path="/upcommingevents" element={<Homepage />} />
              <Route path="/liveevents" element={<Homepage />} />
              <Route path="/prevevents" element={<Homepage />} />
              <Route path="/artists" element={<Artists />} />
              <Route
                path="/singleevent/:event_id/:event_name"
                element={<Singleevent />}
              />
              <Route
                path="/singleoutlet/:id/:name"
                element={<SingleOutlet />}
              />
              <Route
                path="/singleartist/:id/:name"
                element={<SingleArtist />}
              />
              <Route path="/login/:role" element={<UsersLogin />} />
              <Route path="/register/:role" element={<UserRegister />} />
              <Route path="/user-servey" element={<Userservey />} />
              <Route path="/artist-insights" element={<ArtistInsights />} />
              <Route path="/outlet-insights" element={<OutletsInsights />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/outletprofile" element={<OutletProfile />} />
              <Route path="/artistProfile" element={<ArtistProfile />} />
              <Route path="/add-event" element={<AddEvent />} />
              <Route path="/update-event" element={<AddEvent />} />

              <Route
                path="/terms-and-condition"
                element={<TermsandCondition />}
              />

              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/company-profile" element={<CompanyProfile />} />
              <Route path="/aboutus" element={<AboutUS />} />

              <Route path="/outlet-register" element={<OutletRegister />} />

              <Route path="/videos" element={<Videocontent />} />
              <Route path="/images" element={<Content />} />

              <Route path="/contentsofshows" element={<Contentsofshows />} />
              <Route path="/contentsofshows2" element={<Shows />} />

              <Route path="/stories" element={<Stories />} />

              <Route
                path="/eventsimages/:event_id"
                element={<Eventsimages />}
              />

              <Route
                path="/images/:type/:id/:name"
                element={<Eventsimages />}
              />

              <Route path="videos/:type/:id/:name" element={<Eventsvideos />} />

              <Route
                path="/ambienceexperience/:outlet_id"
                element={<AmbienceExperience />}
              />
              <Route
                path="/outletvideos/:outlet_id"
                element={<OutletVideos />}
              />

              <Route
                path="/potraitexperience/:artist_id"
                element={<PotraitExperience />}
              />
              <Route
                path="/artistvideos/:artist_id"
                element={<ArtistVideos />}
              />

              <Route path="/outletcontents" element={<Outletcontent />} />
              <Route path="/outletcontents2" element={<Outletcontent2 />} />
              <Route path="/artistcontents" element={<Artistcontent />} />
              <Route path="/artistcontents2" element={<Artistcontent2 />} />

              <Route path="/underconstruction" element={<ConstructionPage />} />

              <Route path="/calendar" element={<DummyCalendar />} />
              <Route path="/gallery/:type/:id/:name" element={<Gallery />} />

              <Route element={<PersistLogin />}>
                <Route element={<RequireAuth />}>
                  <Route path="/profile" element={<UserProfilePage />} />

                  <Route path="/nightcube" element={<NightcubeSystems />}>
                    {/* Child routes */}
                    {/* <Route index element={<CustomerMangement />} />  */}
                    <Route index element={<DefaultNightcube />} />

                    <Route
                      path="nightcube-inbox"
                      element={<NightcubeInbox />}
                    />

                    <Route
                      path="nightcube-customers"
                      element={<CustomerMangement />}
                    />
                    <Route
                      path="nightcube-events"
                      element={<EventManagement />}
                    />
                    <Route
                      path="nightcube-outlets"
                      element={<OutletMangement />}
                    />
                    <Route
                      path="nightcube-customer"
                      element={<CustomerMangement />}
                    />
                    <Route
                      path="nightcube-artist"
                      element={<ArtistMangement />}
                    />

                    <Route
                      path="customer-filter"
                      element={<CustomerFilter />}
                    />

                    <Route path="events-filter" element={<EventsFilter />} />

                    <Route path="outlets-filter" element={<OutletsFilter />} />

                    <Route path="artists-filter" element={<ArtistFilter />} />

                    <Route path="survey-form" element={<SurveyForm />} />

                    <Route path="nightcube-ROAS" element={<NightcubeROAS />} />

                    <Route path="nightcube-POS" element={<NightcubePOS />} />

                    {/* Default route */}
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
