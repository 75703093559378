import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { axiosPrivate } from "../api/axios";
import default_event from "../assets/errors/no_event_listed.jpg";
import Footer from "../components/Footer";
import { BASE_URL } from "../constants";
import { useRole } from "../context/UserContext";
import useAuth from "../hooks/useAuth";
import "./Events.css";

const Events = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { role, setRoleValue } = useRole();
  const [upComingEvents, setUpComingEvents] = useState();
  const [liveEvents, setLiveEvents] = useState();
  const [previousEvents, setPreviousEvents] = useState();
  const [eventVideoIndex, seteventVideoIndex] = useState(0);
  const [promotedEvents, setPromotedEvents] = useState([]);
  const [ref, inView] = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
  });

  useEffect(() => {
    const interval = setInterval(() => {
      seteventVideoIndex(
        (prevIndex) => (prevIndex + 1) % promotedEvents.length
      );
    }, 5000);

    return () => {
      clearInterval(interval);
      seteventVideoIndex(0);
    };
  }, [promotedEvents]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  useEffect(() => {
    let isMounted = true;

    const getEventbyCategory = async (eventCategory) => {
      try {
        const response = await axiosPrivate.get(`/events/${eventCategory}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        console.log(response.data)
        // isMounted && setAllEvents(response.data);
        eventCategory === "upcoming_events"
          ? setUpComingEvents(response.data)
          : eventCategory === "live_events"
            ? setLiveEvents(response.data)
            : setPreviousEvents(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getPromotedEvents = async () => {
      const response = await axiosPrivate.get(`/events/promoted`);

      setPromotedEvents(response.data);
    };

    getEventbyCategory("upcoming_events");
    getEventbyCategory("live_events");
    getEventbyCategory("previous_events");
    getPromotedEvents();

    return () => {
      isMounted = false;

      // controller.abort();
    };
  }, []);

  return (
    <div className="layout ">
      <div className="firstwindow">
        <div className="  firstwindowleft">
          <h1 ref={ref}
            className={`mainheading animated-element ${inView ? "animate" : ""
              }`} >
            TOP SHOWS OF THE WEEK
          </h1>
        </div>

        <div className="  flex justify-content-end firstwindowright">
          {promotedEvents.map((event, index) => (
            <video
              style={{ width: "100%", height: "100%", objectFit: "cover", aspectRatio: 16/9}}
              key={index}
              autoPlay
              loop
              muted
              className={index === eventVideoIndex ? "visible" : "hidden"} >
              <source
                src={`${BASE_URL}${event.intro_video}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ))}
        </div>
      </div>

      {/* <div className="mainsection  ">
        <div className="flex justify-content-between  flex-wrap ">
          {upComingEvents?.length > 0 ? (
            <div
              onClick={() => navigate("/allevents/upcoming_events")}
              className="imagesinglecard   cursor-pointer"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <Slider {...settings} className="myslider">
                {upComingEvents?.map((event, index) => (
                  <div key={index} className="event-cardsss">
                    <img
                      src={`${BASE_URL}${event.poster_url}`}
                      alt={event.event_name}
                      className="event-card-imgs"
                    />
                  </div>
                ))}
              </Slider>

              <div className="card-body m-1 mb-2">
                <h5
                  className="card-title text-center text-light"
                  style={{ fontWeight: "600" }}
                >
                  COMING SOON
                </h5>
              </div>
            </div>
          ) : (
            <div
              className="cursor-pointer imagesinglecard flex flex-column justify-content-center align-items-center border "
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <div className="event-cardsss">
                <img
                  src={default_event}
                  alt="default_event"
                  className="event-card-imgs"
                />
              </div>

              <div className="card-body m-1  mb-2">
                <h5
                  className="card-title text-center text-light"
                  style={{ fontWeight: "600" }}
                >
                  COMING SOON
                </h5>
              </div>
            </div>
          )}

          {liveEvents?.length > 0 ? (
            <div
              className="imagesinglecard cursor-pointer border "
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
              onClick={() => navigate("/allevents/live_events")}
            >
              <Slider {...settings}>
                {liveEvents?.map((event, index) => (
                  <div
                    key={index}
                    className="event-cardsss"
                  >
                    <img
                      src={`${BASE_URL}${event.poster_url}`}
                      alt={event.event_name}
                      className="event-card-imgs"
                    />
                  </div>
                ))}
              </Slider>

              <div
                className="card-body flex justify-content-center align-items-start mt-2 mb-2"
                style={{ height: "10%" }}
              >
                <h5
                  className=" text-center text-light livenowtext"
                  style={{ fontWeight: "600" }}
                >
                  LIVE NOW
                </h5>
                <CircularProgress
                  className="ms-2"
                  size={18}
                  sx={{ color: "green" }}
                  thickness={4}
                />
              </div>
            </div>
          ) : (
            <div
              className="imagesinglecard  cursor-pointer flex flex-column justify-content-center align-items-center"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <div
                className="event-cardsss"
              >
                <img
                  src={default_event}
                  alt="default_image"
                  className="event-card-imgs"
                />
              </div>

              <div
                className="card-body flex justify-content-between  m-2 mb-2"
                style={{ height: "10%" }}
              >
                <h5
                  className=" card-title text-center text-light"
                  style={{ fontWeight: "600" }}
                >
                  LIVE NOW
                </h5>
                <CircularProgress
                  className="ms-2"
                  size={18}
                  sx={{ color: "green" }}
                  thickness={4}
                />
              </div>
            </div>
          )}

          {previousEvents?.length > 0 ? (
            <div
              onClick={() => navigate("/allevents/previous_events")}
              className="imagesinglecard cursor-pointer "
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <Slider {...settings} className="">
                {previousEvents?.map((event, index) => (
                  <div
                    key={index}
                    className="event-cardsss"
                  >
                    <img
                      src={`${BASE_URL}${event?.poster_url}`}
                      alt={event?.event_name}
                      className="event-card-imgs"
                    />
                  </div>
                ))}
              </Slider>
              <div className="card-body  m-2  mb-2" style={{ height: "10%" }}>
                <h5
                  className="card-title text-center text-light"
                  style={{ fontWeight: "600" }}
                >
                  PREVIOUS SHOWS
                </h5>
              </div>
            </div>
          ) : (
            <div
              className="imagesinglecard cursor-pointer flex flex-column justify-content-center align-items-center"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <div
                className="event-cardsss"
              >
                <img
                  src={default_event}
                  alt="default_event"
                  className="event-card-imgs"
                />
              </div>
              <div className="card-body  m-2  mb-2" style={{ height: "10%" }}>
                <h5
                  className="card-title text-center text-light"
                  style={{ fontWeight: "600" }}
                >
                  PREVIOUS SHOWS
                </h5>
              </div>
            </div>
          )}
        </div>
      </div> */}

      {/* New Design */}
      <div className="mainsection  ">
        <div className="flex justify-content-between  flex-wrap ">
          {upComingEvents?.length > 0 ? (
            <div onClick={() => navigate("/allevents/upcoming_events")}
              className="flex-column  cursor-pointer align-items-center imagesinglecard"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}>
              <Slider {...settings} className="">
                {upComingEvents?.map((event, index) => (
                  <div key={index}>
                    <img src={`${BASE_URL}${event.poster_url}`}
                      alt={event.event_name}
                      className="all-evt-cards" />
                  </div>
                ))}
              </Slider>

              <div style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center " >
                <h1 className="text-center font-semibold mt-2"
                  style={{ left: "50%", color: "white" }} >
                  COMING SOON{" "}
                </h1>
              </div>
            </div>
          ) : (
            <div className="flex flex-column  cursor-pointer imagesinglecard"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }} >
              <img src={default_event}
                alt="default_event"
                className="eventimage" />

              <div style={{ height: "10%", width: "100%"}}
                className="flex align-items-center  justify-center mt-2 mb-2 " >
                <h1 className="text-center font-semibold  mt-2"
                  style={{ left: "50%", color: "white" }} >
                  COMING SOON
                </h1>
              </div>
            </div>
          )}

          {liveEvents?.length > 0 ? (
            <div className="flex-column  cursor-pointer align-items-center imagesinglecard"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
              onClick={() => navigate("/allevents/live_events")}  >
              <Slider {...settings}>
                {liveEvents?.map((event, index) => (
                  <div key={index}  >
                    <img
                      src={`${BASE_URL}${event.poster_url}`}
                      // src="./assets/default_images/default_image.jpg"
                      alt={event.event_name}
                      className="all-evt-cards" />
                  </div>
                ))}
              </Slider>
              <div style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center " >
                <h1 className="text-center font-semibold  mt-2"
                  style={{ left: "50%", color: "white" }} >
                  LIVE NOW
                </h1>
                <CircularProgress
                  className="ms-2"
                  size={18}
                  sx={{ color: "green" }}
                  thickness={4} />
              </div>
            </div>
          ) : (
            <div
              className="flex flex-column  cursor-pointer imagesinglecard"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <img
                src={default_event}
                // src="../assets/default_image.jpg"
                alt="default_image"
                className="eventimage"
              />

              <div
                style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center mt-2 mb-2 "
              >
                <h1
                  className="text-center font-semibold  mt-2"
                  style={{ left: "50%", color: "white" }}
                >
                  LIVE NOW
                </h1>
                <CircularProgress
                  className="ms-2"
                  size={18}
                  sx={{ color: "green" }}
                  thickness={4}
                />
              </div>
            </div>
          )}

          {previousEvents?.length > 0 ? (
            <div
              onClick={() => navigate("/allevents/previous_events")}
              className="flex-column  cursor-pointer align-items-center imagesinglecard"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <Slider {...settings} className="">
                {previousEvents?.map((event, index) => (
                  <div
                    key={index}
                  >
                    <img
                      src={`${BASE_URL}${event?.poster_url}`}
                      alt={event?.event_name}
                      className="all-evt-cards"
                    />
                  </div>
                ))}
              </Slider>
              <div
                style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center "
              >
                <h1
                  className="text-center font-semibold  mt-2"
                  style={{ left: "50%", color: "white" }}
                >
                  PREVIOUS SHOWS
                </h1>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-column  cursor-pointer imagesinglecard"
              style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}
            >
              <img
                src={default_event}
                alt="default_event"
                className="eventimage"
              />

              <div
                style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center "
              >
                <h1
                  className="text-center font-semibold  mt-2"
                  style={{ left: "50%", color: "white" }}
                >
                  PREVIOUS SHOWS
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mobile-gap"></div>


      <div style={{ marginTop: "50px" }} className="footer-overlay">
        <Footer />
      </div>
    </div>
  );
};
export default Events;
