import React, { useEffect, useState } from "react";

import "./Outlets.css";

import EditNoteIcon from "@mui/icons-material/EditNote";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TuneIcon from "@mui/icons-material/Tune";
import { axiosPrivate } from "../api/axios";
import "../assets/SingleOutlet.css";

import ReactBigCalendar from "../components/ReactBigCalendar";
// import ReactCalendar from "../components/ReactCalendar";
// import FullCalendarComponent from "../components/FullCalendar";

import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";

import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import { formatUrl } from '../utils/urls';

import { BASE_URL } from "../constants";

const OutletProfile = () => {
  const [outletData, setOutletData] = useState({});
  const [artistData, setArtistData] = useState([]);

  // resident artists saved by outlet
  const [residentArtists, setResidentArtists] = useState([]);
  // choosed artists
  const [selectedArtists, setSelectedArtists] = useState([]); // Create state for selected artists
  //availaible artist = artitstdata- booked artist - resident artist
  const [availableArtists, setAvailableArtists] = useState([]);

  const navigate = useNavigate();

  const [videoUrl, setVideoUrl] = useState("");

  //shown in the select field
  const [personName, setPersonName] = React.useState([]);

  const [loading, setLoading] = useState(false);

  const [rating, setRating] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCalendarOpen = () => setIsCalendarOpen(true);
  const handleCalendarClose = () => setIsCalendarOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "rgba(0,0,0,0.5)",
    border: "1px solid yellow",
    boxShadow: 24,
    p: 4,
  };

  //  artist management section

  const handleChange2 = (event, newValue) => {
    if (residentArtists.length + newValue.length > 1) {
      alert("You Can Select only one Artist");
      return;
    }

    const artistsWithOutletInfo = newValue.map((selectedArtist) => ({
      artist_id: selectedArtist.artist_id,
      artist_name: selectedArtist.artist_stage_name,
      outlet_id: outletData.outlet_id,
      outlet_name: outletData.outlet_name,
    }));

    // Filter out existing artists from selectedArtists
    const filteredSelectedArtists = selectedArtists.filter(
      (artist) =>
        !artistsWithOutletInfo.some(
          (newArtist) => newArtist.artist_id === artist.artist_id
        )
    );

    // Concatenate the filtered array with the new artists
    setSelectedArtists([...filteredSelectedArtists, ...artistsWithOutletInfo]);

    if (Array.isArray(newValue)) {
      setPersonName(newValue);
    }
  };

  const handleRemoveArtist = (artistToRemove) => {
    // Filter based on artist_id in selectedArtists
    const updatedSelectedArtists = selectedArtists.filter(
      (artist) => artist.artist_id !== artistToRemove.artist_id
    );
    setSelectedArtists(updatedSelectedArtists);

    // Filter based on artist_id in personName
    const updatedPersonName = personName.filter(
      (artist) => artist.artist_id !== artistToRemove.artist_id
    );
    setPersonName(updatedPersonName);
  };

  const getResidentArtists = async () => {
    try {
      let outletId = localStorage.getItem("userId");
      const response = await axiosPrivate.get(
        `/artistoutletsync/getResidentArtists/${outletId}`,
        {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        }
      );
      setResidentArtists(response.data);
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const deleteResidentArtists = async (artistId) => {
    try {
      let outletId = localStorage.getItem("userId");
      const response = await axiosPrivate.delete(
        `/artistoutletsync/deleteLinkage/${artistId}/${outletId}`,
        {}
      );
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    } finally {
      getResidentArtists();
    }
  };

  const postResidentArtists = async () => {
    try {
      if (residentArtists.length === 3) {
        alert("You Can Not Add More Than 3");
      } else {
        if (selectedArtists.length > 0) {
          setLoading(true);
          const response = await axiosPrivate.post(
            `/artistoutletsync`,
            selectedArtists,
            {
              "Content-Type": "application/json",
            }
          );

          setSelectedArtists([]);
          setPersonName([]);

          alert("Resident Updated Succesfully");
        }
      }
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    } finally {
      getResidentArtists();
      setLoading(false); // Set loading back to false when the request completes
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getAvailaibleArtists = async () => {
      try {
        const response = await axiosPrivate.get(
          `/artistoutletsync/getLinkedArtists`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );

        let bookedArtist = response.data;

        let available_artists = artistData.filter(
          (artist) =>
            !bookedArtist.some(
              (booked) => booked.artist_id === artist.artist_id
            )
        );

        let availaible_artist_without_residents = available_artists.filter(
          (artist) =>
            !residentArtists.some(
              (residents) => residents.artist_id === artist.artist_id
            )
        );

        // const availaible_artists = bookedArtist.filter((artist1) => !artistData.some((artist2) => artist1.artist_id === artist2.artist_id));

        setAvailableArtists(availaible_artist_without_residents);

        // setAvailableArtists()
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getAvailaibleArtists();

    return () => {
      isMounted = false;
    };
  }, [artistData, residentArtists]);

  const getOutletData = async () => {
    try {
      let outletId = localStorage.getItem("userId");
      const response = await axiosPrivate.get(`/outlets/${outletId}`, {
        // // signal: controller.signal
        // 'Access-Control-Allow-Origin' : true
      });
      console.log("opentime->", response.data)
      setOutletData(response.data);
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  //for outlet profile section
  useEffect(() => {
    let isMounted = true;

    const getArtistData = async () => {
      try {
        const response = await axiosPrivate.get(`/artists`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setArtistData(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getOutletData();
    isMounted && getArtistData();
    isMounted && getResidentArtists();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setVideoUrl(outletData?.intro_video);
    console.log("videourl-->", outletData?.intro_video)
    setRating(outletData?.rating);
  }, [outletData]);

  const formatTime = (time) => {
    // Splitting the time into hours and minutes

    if (!time) return "";

    const [hours, minutes] = time.split(":");

    // Converting hours and minutes to numbers
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);

    // Determining the period (AM or PM)
    const period = hoursInt >= 12 ? "PM" : "AM";

    // Converting hours to 12-hour format
    const formattedHours = (hoursInt % 12 || 12).toString().padStart(2, "0");

    // Returning the formatted time with AM/PM
    return `${formattedHours}:${minutesInt
      .toString()
      .padStart(2, "0")} ${period}`;
  };
  const handleWebsiteClick = () => {
    if (outletData?.website) {
      window.open(outletData.website, '_blank');
    }
  }
  return (
    <div className="layout mb-5">
      <div className="outletparent d-flex flex-wrap mb-5 eachartistparent">
        <div className="eachartistmainsection">
          <div className="mainsection text-light d-flex flex-column mb-5">
            <div className="videosection position-relative overflow-hidden ">
              {videoUrl ? (
                <video
                  autoPlay
                  muted
                  loop
                  className="w-full h-full object-cover max-h-100  z-[-1] background-video2"
                  style={{ width: "100%" }}
                  key="new-video"
                >
                  <source src={`${BASE_URL}${videoUrl}`} type="video/mp4" />
                </video>
              ) : (
                <p>No video available.</p>
              )}
            </div>

            <div
              className="videosectionmiddle"
              style={{
                // minHeight: "500px",
                // maxHeight: "auto",
                marginTop: "180px",
              }}
            >
              {/* <div className="insitesections row row-cols-1 row-cols-md-3 g-4 mb-3">
                <div className="col  p-0 mb-3">
                  <h1
                    className=" insitetext text-center ps-5 pe-5 mt-2 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    INSIGHTS
                  </h1>
                  <div
                    className="card  flex flex-column justify-content-center"
                    style={{
                      backgroundColor: "rgb(186 186 186 / 8%)",
                      position: "relative",
                      height: "90%",
                    }}
                  >
                    <div
                      onClick={() => navigate("/outlet-insights")}
                      className="flex border rounded justify-content-around align-items-center cursor-pointer"
                      style={{
                        height: "50px",
                        width: "80px",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      <button style={{ color: "white" }}>EDIT</button>
                      <EditNoteIcon style={{ color: "white" }} />
                    </div>

                    <div className="card-body flex flex-column justify-content-center dnfvxdb paadingsss">
                      <div
                        className=" flex align-items-center"
                        style={{ height: "auto", padding: "10px 0px" }}
                      >
                        <h5
                          className=" text-light font-semibold"
                          style={{ minWidth: "150px" }}
                        >
                          CATEGORY :
                        </h5>
                        <h5 className=" text-light ">
                          {outletData?.outlet_category}
                        </h5>
                      </div>

                      <div
                        className=" flex align-items-center "
                        style={{ height: "auto", padding: "10px 0px" }}
                      >
                        <h5
                          className=" text-light font-semibold"
                          style={{ minWidth: "150px" }}
                        >
                          OPEN TIME :
                        </h5>
                        <h5 className=" text-light ">
                          {outletData && formatTime(outletData?.opening_time)}
                        </h5>
                      </div>

                      <div
                        className=" flex align-items-center "
                        style={{ height: "auto", padding: "10px 0px" }}
                      >
                        <h5
                          className=" text-light font-semibold"
                          style={{ minWidth: "150px" }}
                        >
                          CLOSE TIME :
                        </h5>
                        <h5 className=" text-light ">
                          {outletData && formatTime(outletData?.closing_time)}
                        </h5>
                      </div>

                      <div
                        className=" flex align-items-center "
                        style={{ height: "auto", padding: "10px 0px" }}
                      >
                        <h5
                          className=" text-light font-semibold"
                          style={{ minWidth: "150px" }}
                        >
                          WEBSITE :
                        </h5>
                        <h5 className=" text-light ">{outletData?.website}</h5>
                      </div>

                      <div
                        className=" flex align-items-center "
                        style={{ height: "auto", padding: "10px 0px" }}
                      >
                        <h5
                          className=" text-light font-semibold"
                          style={{ minWidth: "150px" }}
                        >
                          ADDRESS :
                        </h5>
                        <div className="flex justify-content-between  w-100">
                          <h5 className=" text-light ">
                            {outletData?.address}
                          </h5>
                        </div>
                      </div>

                      <div
                        className=" flex align-items-center "
                        style={{ height: "auto", padding: "10px 0px" }}
                      >
                        <h5
                          className=" text-light font-semibold"
                          style={{ minWidth: "150px" }}
                        >
                          RESIDENT ARTISTS :
                        </h5>
                        <div className="flex align-items-center  w-100">
                          {residentArtists?.map((artist, index) => (
                            <div
                              onClick={() =>
                                navigate(
                                  `/singleartist/${artist.artist_id}/${artist.artist_name}`
                                )
                              }
                              key={index}
                              className=" p-2 border rounded me-3 cursor-pointer"
                            >
                              <p className="text-warning font-semibold">
                                {artist.artist_name}
                              </p>
                            </div>
                          ))}
                          <TuneIcon
                            onClick={handleOpen}
                            className="ms-3  cursor-pointer"
                            style={{ color: "white" }}
                          ></TuneIcon>
                        </div>
                      </div>

                      <div className="mt-4  flex justify-evenly">
                        {outletData.facebook_url && (
                          <FacebookIcon
                            onClick={() =>
                              window.open(outletData.facebook_url, "_blank")
                            }
                            style={{ color: "white", cursor: "pointer" }}
                          />
                        )}

                        {outletData.instragram_url && (
                          <InstagramIcon
                            onClick={() =>
                              window.open(outletData.instragram_url, "_blank")
                            }
                            style={{ color: "white", cursor: "pointer" }}
                          />
                        )}

                        {outletData.google_url && (
                          <GoogleIcon
                            onClick={() =>
                              window.open(outletData.google_url, "_blank")
                            }
                            style={{ color: "white", cursor: "pointer" }}
                          />
                        )}

                        {outletData.map && (
                          <LocationOnIcon
                            onClick={() =>
                              window.open(outletData.map, "_blank")
                            }
                            style={{ color: "white", cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col border-0 p-0  mb-3">
                  <h1
                    className="insitetext text-center pe-5 ps-5 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    SHOWS
                  </h1>
                  <div
                    className="card "
                    style={{
                      backgroundColor: "rgb(186 186 186 / 8%)",
                      height: "90%",
                    }}
                  >
                    <div className="card-body flex flex-column justify-content-center paadingsss">
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <h5
                          onClick={() =>
                            navigate("/add-event", {
                              state: {
                                userRole: "outlet",
                                email: outletData.email,
                              },
                            })
                          }
                          className="card-title text-light cursor-pointer font-semibold"
                        >
                          ADD NEW EVENT
                        </h5>
                      </div>

                      <div
                        onClick={() =>
                          navigate("/allevents/upcoming_events", {
                            state: { outletId: outletData.outlet_id },
                          })
                        }
                        className="d-flex justify-content-start align-items-center mb-3"
                      >
                        <h5 className="card-title text-light cursor-pointer font-semibold">
                          UPCOMING SHOWS
                        </h5>
                      </div>

                      <div
                        onClick={() =>
                          navigate("/allevents/live_events", {
                            state: { outletId: outletData.outlet_id },
                          })
                        }
                        className="d-flex justify-content-start align-items-center mb-3"
                      >
                        <h5 className="card-title text-light cursor-pointer font-semibold">
                          LIVE SHOWS
                        </h5>
                      </div>

                      <div
                        onClick={() =>
                          navigate("/allevents/previous_events", {
                            state: { outletId: outletData.outlet_id },
                          })
                        }
                        className="d-flex justify-content-start align-items-center mb-3"
                      >
                        <h5 className="card-title text-light cursor-pointer font-semibold">
                          PREVIOUS SHOWS
                        </h5>
                      </div>

                      <div
                        onClick={handleCalendarOpen}
                        className="d-flex justify-content-start align-items-center mb-3 "
                      >
                        <h5 className="card-title text-light cursor-pointer font-semibold">
                          CALENDER
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col border-0 p-0  mb-3">
                  <h1
                    className="insitetext text-center pe-5 ps-5 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    GALLERY
                  </h1>
                  <div
                    className="card "
                    style={{
                      backgroundColor: "rgb(186 186 186 / 8%)",
                      height: "90%",
                    }}
                  >
                    <div className="card-body flex flex-column justify-content-center paadingsss">
                      <div
                        onClick={() =>
                          navigate(
                            `/gallery/outlet/${outletData.outlet_id}/${outletData.outlet_name}`
                          )
                        }
                        className="d-flex justify-content-start align-items-center mb-3"
                      >
                        <h5 className="card-title text-light cursor-pointer font-semibold">
                          ADD TO ALBUM
                        </h5>
                      </div>

                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <h5
                          onClick={() =>
                            navigate(
                              `/images/outlets/${outletData.outlet_id}/${outletData.outlet_name}`
                            )
                          }
                          className="card-title text-light cursor-pointer font-semibold"
                        >
                          AMBIENCE
                        </h5>
                      </div>

                      <div
                        onClick={() =>
                          navigate(
                            `/videos/experience_videos/${outletData.outlet_id}/${outletData.outlet_name}`
                          )
                        }
                        className="d-flex justify-content-start align-items-center mb-3"
                      >
                        <h5 className="card-title text-light cursor-pointer font-semibold">
                          EXPERIENCE
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="singleevent-second-banner mt-10">
              <div className="blocks mb-2">
                <div className="blocks-content" >
                  {" "}
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    INSIGHTS
                  </h1>

                  <div>

                    <div
                      onClick={() => navigate("/outlet-insights")}
                      className="flex border rounded justify-content-around align-items-center cursor-pointer"
                      style={{
                        height: "auto",
                        width: "85px",
                        position: "relative",
                        top: "-3rem",
                        right: "0px",
                        float: "right"
                      }}
                    >
                      <button style={{ color: "white" }}>EDIT</button>
                      <EditNoteIcon style={{ color: "white" }} />
                    </div>

                    <div
                      className=" flex align-items-center"
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        CATEGORY :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData?.outlet_category}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        OPEN TIME :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData && formatTime(outletData?.opening_time)}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        CLOSE TIME :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData && formatTime(outletData?.closing_time)}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        WEBSITE :
                      </h5>
                      <h5 className=" text-light " >
                        {outletData?.website ? (
                          <a href={formatUrl(outletData.website)} target="_blank">{outletData.website}</a>
                        ) : (
                          <span>No website available</span> // You can change this to whatever fallback you prefer
                        )}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        ADDRESS :
                      </h5>
                      <div className="flex justify-content-between  w-100">
                        <h5 className=" text-light ">
                          {outletData?.address}
                        </h5>
                      </div>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        RESIDENT ARTISTS :
                      </h5>
                      <div className="flex align-items-center  w-100">
                        {residentArtists?.map((artist, index) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/singleartist/${artist.artist_id}/${artist.artist_name}`
                              )
                            }
                            key={index}
                            className=" p-2 border rounded me-3 cursor-pointer"
                          >
                            <p className="text-warning font-semibold">
                              {artist.artist_name}
                            </p>
                          </div>
                        ))}
                        <TuneIcon
                          onClick={handleOpen}
                          className="ms-3  cursor-pointer"
                          style={{ color: "white" }}
                        ></TuneIcon>
                      </div>
                    </div>

                    <div className="mt-4  flex justify-evenly">
                      {outletData.facebook_url && (
                        <FacebookIcon
                          onClick={() =>
                            window.open(outletData.facebook_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {outletData.instragram_url && (
                        <InstagramIcon
                          onClick={() =>
                            window.open(outletData.instragram_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {outletData.google_url && (
                        <GoogleIcon
                          onClick={() =>
                            window.open(outletData.google_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {outletData.map && (
                        <LocationOnIcon
                          onClick={() =>
                            window.open(outletData.map, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blocks mb-2">
                <div className="blocks-content">
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    SHOWS
                  </h1>
                  <div>
                    <div className="d-flex justify-content-start align-items-center mb-3">
                      <h5
                        onClick={() =>
                          navigate("/add-event", {
                            state: {
                              userRole: "outlet",
                              email: outletData.email,
                            },
                          })
                        }
                        className="card-title text-light cursor-pointer font-semibold"
                      >
                        ADD NEW EVENT
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/upcoming_events", {
                          state: { outletId: outletData.outlet_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        UPCOMING SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/live_events", {
                          state: { outletId: outletData.outlet_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        LIVE SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/previous_events", {
                          state: { outletId: outletData.outlet_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        PREVIOUS SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={handleCalendarOpen}
                      className="d-flex justify-content-start align-items-center mb-3 "
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        CALENDER
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blocks mb-2 ">
                <div className="blocks-content" >
                  <h1 className="insitetext text-center pe-5 ps-5 text-light font-semibold"
                    style={{ fontSize: "17px" }} >
                    GALLERY
                  </h1>

                  <div className="">
                    <div
                      onClick={() =>
                        navigate(
                          `/gallery/outlet/${outletData.outlet_id}/${outletData.outlet_name}`
                        )
                      }
                      className="d-flex justify-content-start  mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        ADD TO ALBUM
                      </h5>
                    </div>

                    <div className="d-flex justify-content-start  mb-3">
                      <h5
                        onClick={() =>
                          navigate(
                            `/images/outlets/${outletData.outlet_id}/${outletData.outlet_name}`
                          )
                        }
                        className="card-title text-light cursor-pointer font-semibold"
                      >
                        AMBIENCE
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate(
                          `/videos/experience_videos/${outletData.outlet_id}/${outletData.outlet_name}`
                        )
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        EXPERIENCE
                      </h5>
                    </div>

                  </div>
                </div>
              </div>
            </div>



            <div className="mt-10">
              <div
                className="card blocks-content">
                <div className="card-body p-0">
                  <h5 className="card-title  text-light mb-3 font-semibold" style={{ fontSize: "17px" }} >
                    ABOUT:
                  </h5>
                  <p
                    className="card-text text-light mb-2"
                  // style={{ whiteSpace: "pre" }}
                  >
                    {outletData?.description}
                  </p>
                </div>
              </div>
            </div>

            <div className="videosectiondisclaimer2 mb-5 mt-10">
              <div
                className="card blocks-content" >
                <div className="card-body p-0">
                  <h5
                    className="card-title  text-light mb-3 font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    DISCLAIMER
                  </h5>

                  <p className="text-light" style={{ whiteSpace: "pre" }}>
                    {outletData.outlet_profile_rules_and_regulation}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <img src={one} alt="some outlet" style={{objectFit:"cover"}} className="mainartistimg"/> */}

          {/* <div className="artistpicturebottom flex flex-column align-items-center">
            <img
             src={`${BASE_URL}${outletData?.outlet_icon}`} 
             
              alt="some outlet"
              className="avatarimg"
              style={{ objectFit: "cover" }}
            />
            <div
              className="border border-warning p-2 rounded"
              style={{ width: "150px" }}
            >
              <h2 className="text-center" style={{ color: "white" }}>
                {outletData?.outlet_name}
              </h2>
            </div>
          </div>

          <div className="artiststars mt-3">
            <div className="flex flex-column align-items-center " >
              <Rating className="mb-3 border-light" color="red"   name="half-rating" size="large"  precision={0.5} value={rating} readOnly 

                emptyIcon={<StarIcon style={{ opacity: 0.2, color: 'white' }} fontSize="inherit" />}
              />

            
            </div>
          </div> */}

          <div
            className="bottom-section mb-5"
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              right: "0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            <div className="artistpicturebottom  ">
              <img
                src={`${BASE_URL}${outletData?.outlet_icon}`}
                alt="some outlet"
                className="avatarimg"

              />

              <div
                className="border border-warning p-2 rounded mt-3"
              // style={{ width: "150px" }}
              >
                <h2 className="text-center" style={{ color: "white" }}>
                  {outletData?.outlet_name}
                </h2>
              </div>
            </div>

            <div className="artiststars mt-3">
              <div className="flex flex-column align-items-center ">
                <Rating
                  className="mb-3 border-light"
                  color="red"
                  name="half-rating"
                  size="large"
                  precision={0.5}
                  value={rating}
                  readOnly
                  emptyIcon={
                    <StarIcon
                      style={{ opacity: 0.2, color: "white" }}
                      fontSize="inherit"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ position: "relative" }}>
          <CloseIcon
            className="closeButton"
            onClick={handleClose}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          />

          <div className="mt-3 mx-2 ">
            <label
              style={{ color: "black" }}
              for="artist-section"
              className="form-label mb-2"
            >
              SELECT ARTISTS
            </label>
            <Autocomplete
              className="rounded"
              multiple
              id="artist-section"
              style={{ backgroundColor: "white" }}
              options={availableArtists}
              value={personName}
              onChange={handleChange2}
              isOptionEqualToValue={(option, value) =>
                option.artist_id === value.artist_id
              }
              getOptionLabel={(option) => option.artist_stage_name} // Specify how to display the option label
              isOptionDisabled={(option) =>
                residentArtists?.some(
                  (residentArtist) =>
                    residentArtist.artist_id === option.artist_id
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search..."
                  style={{ color: "white" }}
                />
              )}
              // renderOption={(option, state) => (
              //   <li
              //     {...state}
              //     style={{
              //       cursor: residentArtists.some((residentArtist) => residentArtist.artist_id === option.artist_id)
              //         ? 'not-allowed'
              //         : 'pointer',
              //     }}
              //   >
              //     { option.artist_stage_name}
              //   </li>
              // )}

              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option.artist_stage_name}
                    {...getTagProps({ index })}
                    onDelete={() => handleRemoveArtist(option)}
                    deleteIcon={
                      <IconButton size="small" color="error">
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                ))
              }
            />
          </div>

          <h5 className=" text-dark ms-2 mt-4 mb-2" style={{ width: "150px" }}>
            RESIDENT ARTISTS :
          </h5>

          <div className="d-flex align-items-center justify-content-around mb-3 ms-2">
            {loading ? (
              <p>Loading...</p>
            ) : residentArtists ? (
              residentArtists?.map((artist, index) => (
                <div
                  className="border flex justify-between  p-2 rounded me-1"
                  style={{ width: "100px" }}
                >
                  <p className="text-dark">{artist.artist_name}</p>
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={() => deleteResidentArtists(artist.artist_id)}
                  />
                </div>
              ))
            ) : null}
          </div>

          <div className="flex justify-content-end pe-1">
            <Button
              onClick={postResidentArtists}
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              UPDATE
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isCalendarOpen}
        onClose={handleCalendarClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <ReactBigCalendar outletId={outletData.outlet_id} />

          {/* <ReactCalendar /> */}
        </Box>
      </Modal>

      {/* <div className="footer-overlay">
        <Footer />
      </div> */}
    </div>
  );
};

export default OutletProfile;
