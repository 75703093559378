import {useNavigate} from 'react-router-dom'
import React, { useRef} from 'react';

import { BASE_URL } from '../constants';



const HomeBanner = ({ images, speed = 20000,  direction = 'left', height = '300px', width = '300px', margin = '0.8rem', type }) => {
    const sectionClassName = direction === 'left' ? 'section1' : 'section2';
    const navigate = useNavigate();
    const sectionRef = useRef(null);

    const handleNavigation = (name, id) =>{
      if(type === "images"){
        navigate(`/images/outlets/${id}/${name.replace(/ /g, '_')}` )
      }else if(type === "videos"){
        navigate(`/videos/experience_videos/${id}/${name.replace(/ /g, '_')}` )
      }else if(type === "event"){
        navigate(`/singleEvent/${id}/${name.replace(/ /g, '_')}` )
      }
      else{
        navigate(`/singleoutlet/${id}/${name.replace(/ /g, '_')}` )
      }
    }

   
    
    
    return (
      <div className="inner" >

        <div className="wrapper">
          <section ref={sectionRef} className={sectionClassName}  style={{ "--speed": `${speed}ms`,  "--direction": 'right' }}>
            {images.map(({ id, image, name }) => (
              <div className='flex flex-column' key={id}>
               <div className="image " style =  {{margin: margin}} >
                <img 

                onClick={()=>handleNavigation(name, id)} 
                
                className="sliderimg" style = {{height: height, minWidth:width, objectFit: 'cover'}}  src={`${BASE_URL}${image}`}  alt={id} />
              </div>
              <div>
                <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
              </div>
              </div>
            ))}
          </section>

          {/* <section  className={sectionClassName} style={{ "--speed": `${speed}ms`,  "--direction": direction === 'right' ? 'reverse' : 'normal', }}>
          {images.map(({ id, image, name }) => (
              <div className='flex flex-column' key={id}>
               <div className="image " style = {{margin: margin}}  >
                <img onClick={()=>handleNavigation(name, id)}   
                
                className="sliderimg" style = {{height: height, minWidth:width, objectFit: 'cover'}} src={`${BASE_URL}${image}`}  alt={id} />
              </div>
              <div>
                <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
              </div>
              </div>
            ))}

          </section> */}

          {/* <section  className={sectionClassName} style={{ "--speed": `${speed}ms`,  "--direction": direction === 'right' ? 'reverse' : 'normal', }}>
          {images.map(({ id, image, name }) => (
              <div className='flex flex-column' key={id}>
               <div className="image " style = {{margin: margin}}  >
                <img onClick={()=>handleNavigation(name, id)}   
                
                className="sliderimg" style = {{height: height, minWidth:width, objectFit: 'cover'}} src={`${BASE_URL}${image}`}  alt={id} />
              </div>
              <div>
                <h3 className=' text-light  text-center p-2 font-semibold'>{name}</h3>
              </div>
              </div>
             
            ))}
          </section> */}
        </div>
      </div>
    );
  };

  export { HomeBanner };