import React from 'react'
import public_info from "../assets/public_info.jpg"
import { useState } from 'react';
function PrivacyPolicy() {
 

 
  return (
   
    <div className='publicpaper'>
        <div className='m-auto rounded p-3 ' style={{ position: 'relative', height: '80%', overflow: 'scroll', width: '90%', backgroundColor: 'whitesmoke', backgroundImage: `url(${public_info})`, backgroundSize: 'cover' }}>
        
          <h1 className='text-justify ms-3' style={{ lineHeight: '25px', fontWeight: '500', margin: '2rem' }}>
THIS PRIVACY STATEMENT APPLIES TO THE SITES AND APPS WHERE IT APPEARS TO BE “ AS IS “
<br/><br/>
THIS STATEMENT DESCRIBES HOW WE TREAT ANY OF YOUR PERSONAL INFORMATION WE COLLECT ON THIS SITE. IT ALSO INCLUDES COMMUNICATION IN PHONE OR EMAIL OR ANY OTHER MEDIUMS OF INTERACTIONS YOU HAVE WITH US.
<br/><br/>
OUR PRIVACY STATEMENT HAS BEEN DESIGNED WITH YOUR COMFORT IN MIND. HOW THE POLICY APPLIES TO YOU WILL DEPENDS ON HOW YOU INTERACT WITH US.
<br/><br/>
YOUR CHOICES AND RIGHTS UNDER EACH SCENARIO ARE EXPLAINED IN MORE DETAILED FORMAT.
</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
   WHAT INFORMATION WE HAVE & WHERE WE GET IT:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>

    WE COLLECT AND STORE DIFFERENT TYPES OF INFORMATION ABOUT YOU WHEN YOU CREATE AN ACCOUNT, CREATE BOOKINGS, RESERVE EVENTS, CONTACT US, AND USE OUR WEBSITES, SOCIAL MEDIA AND ANY OTHER DIGITAL MEDIA PLATFORMS OPERATING WITH US.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
HOW WE USE YOUR INFORMATION & WHY:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE COLLECT AND USE YOUR INFORMATION FOR LOTS OF REASONS SUCH AS UNDERSTANDING YOUR MUSIC INTEREST, UNDERSTANDING YOUR PREFERENCES, FILTERING EVENTS OF YOUR CHOICES, SHARING LATEST NEWS & UPDATES, FOR MARKETING PURPOSES, FOR IMPROVING OUR SOLUTIONS & SERVICES AND AS OTHERWISE IF REQUIRED BY LAW.
</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
WHO WE SHARE YOUR DATA WITH & WHY:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE RESPECT YOUR PRIVACY. WE DO NOT RENT, OUTSOURCE, SELL YOUR INFORMATION TO ANYONE OR ANYWHERE OR 3RD PARTIES WITHOUT YOUR APPROVAL OR PRIOR CONSENT.
<br/> 
WE USE YOUR COLLECTIBLE INFORMATION FOR DATA ANALYTICS AND SEGREGATION PURPOSES INTERNALLY TO IMPROVE ACCURACY TOWARDS AGGREGATED SOLUTIONS & SERVICES.

</h1>
 <h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
 YOUR CHOICES & RIGHTS:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
AMONG OTHER RIGHTS, YOU CAN CHOOSE WHETHER TO RECEIVE MARKETING & PROMOTIONAL MATERIALS FROM US TIME TO TIME. YOU ALSO HAVE THE RIGHT TO ACCESS THE INFORMATION WE HAVE ABOUT YOU.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
LOOKING AFTER YOUR INFORMATION:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE TAKE STANDARD STEPS & MEASURES TO ENSURE YOUR INFORMATION IS PROTECTED AND SECURE IN OUR ACTIVE SERVERS AND WE MAKE SURE TO DELETE IT SECURELY WHEN YOU ARE NO LONGER A PART OF OUR COMMUNITY.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
CONTACT US:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
IF YOU HAVE ANY QUESTIONS OR QUERIES ABOUT THIS STATEMENT, OR HOW WE HANDLE YOUR INFORMATION, GET IN TOUCH WITH US
</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
WHAT INFORMATION WE HAVE & WHERE WE GET IT:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE COLLECT INFORMATION FROM AND ABOUT YOU.
<br/> <br/>
CONTACT AND BILLING INFORMATION. WHEN YOU CREATE AN ACCOUNT, CREATE A BOOKING OR RESERVATION, OR APPLY FOR PROMOTIONAL MEMBERSHIP. ON UPDATED VERSIONS, EXAMPLE ONCE WE ADD TRANSACTION/BILLING LINKS TO OUR PLATFORM, WE MAY COLLECT YOUR CONTACT AND BILLING INFORMATION, SUCH AS YOUR NAME, STREET ADDRESS, ZIP CODE, EMAIL, PHONE NUMBER AND CREDIT CARD NUMBER.
<br/> <br/>
WARNING: WE DO NOT COLLECT OR STORE CVV CODE TOWARDS YOUR CREDIT OR DEBIT CARD.
<br/> <br/>
FOR MORE INFORMATION: <a style={{color: 'blue'}} href='HTTPS://WWW.CREDITCARDINSIDER.COM/LEARN/CREDIT-CARD-SECURITY/'>HTTPS://WWW.CREDITCARDINSIDER.COM/LEARN/CREDIT-CARD-SECURITY/</a> 
<br/> <br/>
INFORMATION YOU POST. WE MAY COLLECT INFORMATION YOU POST IN A PUBLIC FORUM ON OUR WEBSITE OR ON A 3RD PARTY SOCIAL MEDIA SPACE IF THE PLATFORM IS OPERATIONAL WITH US.
<br/> <br/>
DEMOGRAPHIC INFORMATION. WE MIGHT COLLECT INFORMATION LIKE YOUR AGE, AGE GROUP, OR GENDER, OR INFORMATION ABOUT EVENTS YOU LIKE OR BOOKINGS YOU MAKE. WE MIGHT COLLECT THIS AS PART OF A SURVEY, FOR EXAMPLE, TO IMPROVE ACCURACY TOWARDS AGGREGATED SOLUTIONS & SERVICES.
<br/> <br/>
OTHER INFORMATION. IF YOU USE OUR WEBSITE OR APPS, WE MAY COLLECT INFORMATION ABOUT THE BROWSER AND DEVICE YOU’RE USING, YOUR IP ADDRESS, YOUR LOCATION, THE SITE FROM WHICH YOU ARE REDIRECTED TO THIS SITE, THE SITE TO WHICH YOU ARE REDIRECTED FROM THIS SITE, AND HOW YOU USED OR DIDN’T USE OUR SITE OR APP. WE MAY COLLECT THIS USING TECHNOLOGY SUCH AS GPS AND WI-FI.
</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
WE COLLECT INFORMATION IN DIFFERENT WAYS:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE COLLECT INFORMATION DIRECTLY FROM YOU. FOR EXAMPLE, IF YOU BUY MEMBERSHIPS, CREATE AN ACCOUNT, OR REGISTER FOR A PROMOTION. WE ALSO COLLECT INFORMATION IF YOU SHARE ON OUR WEBSITES ON OTHER WEBSITES OR ASK US A QUESTION
<br/> <br/>
WE COLLECT INFORMATION FROM YOU PASSIVELY. WE USE TRACKING TOOLS LIKE BROWSER COOKIES AND WEB BEACONS. WE ALSO COLLECT INFORMATION FROM OUR APPS.
<br/> <br/>
FOR MORE INFORMATION ABOUT THESE TOOLS AND HOW TO CONTROL THEM: <a style={{color: 'blue'}} href='HTTPS://WWW.CERILLION.COM/LEGAL/COOKIES'>HTTPS://WWW.CERILLION.COM/LEGAL/COOKIES</a> 
<br/> <br/>
WE GET INFORMATION ABOUT YOU FROM 3RD PARTIES. FOR EXAMPLE, IF YOU USE A SOCIAL MEDIA FEATURE WITHIN OUR WEBSITES OR APPS OR POST TO A SOCIAL MEDIA PLATFORM, THE SOCIAL MEDIA SITE WILL GIVE US SOME INFORMATION ABOUT YOU.



</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
HOW WE USE YOUR INFORMATION & WHY:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE USE INFORMATION AS DISCLOSED AND DESCRIBED HERE
<br/> <br/>
WE USE INFORMATION TO PROVIDE YOU WITH IMPROVED SOLUTIONS AND SERVICES. WE USE YOUR INFORMATION TO PROCESS YOUR BOOKINGS, RESERVATIONS OR VALIDATE MEMBERSHIPS AND PROVIDE YOU WITH CUSTOMER ASSISTANCE. THIS INCLUDES SENDING YOU EMAILS ABOUT YOUR ACCOUNT OR A BOOKING, RESERVATION REQUESTS THAT YOU HAVE MADE OR DISCOUNT COUPONS IF YOU ARE A HAVING A MEMBERSHIP WITH US. WE MIGHT ALSO CONTACT YOU ABOUT THIS POLICY OR OUR WEBSITE TERMS & CONDITIONS AS PER YOUR UTILIZATION OF THIS SITE.
<br/>
WE USE INFORMATION TO RESPOND TO YOUR REQUESTS OR QUESTIONS. FOR EXAMPLE, WE MIGHT USE YOUR INFORMATION TO CONFIRM YOUR REGISTRATION FOR AN EVENT OR PROMOTIONS. YOU MAY GIVE US YOUR FRIEND’S INFORMATION, FOR EXAMPLE VIA OUR REFERRAL SERVICE TO TELL A FRIEND ABOUT OUR WEBSITE OR TO PURCHASE A MEMBERSHIP. WE WILL ONLY USE YOUR FRIEND’S INFORMATION TO PROVIDE SERVICES YOU REQUESTED. YOU MAY CONTACT OUR CUSTOMER ASSISTANCE TEAM TO ASK US TO DELETE THEIR INFORMATION.
<br/>
WE USE YOUR INFORMATION TO IMPROVE OUR SOLUTIONS AND SERVICES. WE MIGHT USE YOUR INFORMATION TO CUSTOMIZE YOUR EXPERIENCE WITH US BY ENHANCING ACCURACY OR FEATURES. THIS COULD INCLUDE DISPLAYING EVENTS, OUTLETS, ARTISTS, CONTENTS BASED ON YOUR FILTERED PREFERENCES.
<br/>
WE MAY USE YOUR INFORMATION TO MAKE OUR SITE, SOLUTIONS AND SERVICES AVAILABLE AT OUR DISPOSAL BETTER RESPONSIVE TOWARDS YOU. WE MAY COMBINE INFORMATION WE GET FROM YOU WITH INFORMATION ABOUT YOU WE GET FROM 3RD PARTY PLATFORMS, EXAMPLE SOCIAL MEDIA OR ANY 3RD PARTY WEBSITE WORKING WITH US.
<br/>
WE USE INFORMATION FOR SECURITY PURPOSES. WE USE INFORMATION TO PROTECT OUR COMPANY, OUR CUSTOMERS, OUR ENTITIES OR OUR SITES AND ANY OTHER DIGITAL OR MEDIA PLATFORMS OPERATING WITH US. THIS INCLUDES TO PREVENT ILLEGAL, MANIPULATIVE OR DESTRUCTIVE ACTIONS & BEHAVIORS.
<br/>
WE USE INFORMATION FOR MARKETING & PROMOTIONAL PURPOSES. FOR EXAMPLE, WE MIGHT SEND YOU INFORMATION ABOUT SPECIAL PROMOTIONS OR OFFERS OR MEMBERSHIP EXPERIENCES YOU MIGHT BE INTERESTED IN. WE MIGHT ALSO TELL YOU ABOUT NEW FEATURES OR SERVICES AS SOON AS THEY ARE UPDATED. THESE MIGHT BE OUR OWN PROGRAMS OR FEATURES, OR 3RD PARTY OFFERS OR SOLUTIONS WE THINK YOU MIGHT FIND INTERESTING. FOR EXAMPLE, IF YOU BUY MEMBERSHIP FROM US, WE MAY ENROLL YOU IN OUR NEWSLETTER.
<br/>
WE MAY ALSO USE PUSH NOTIFICATIONS THROUGH OUR SITE. WE MAY USE PUSH NOTIFICATIONS AND YOUR LOCATION INFORMATION IN A COMBINATION TO SEND YOU KEY ALERTS REGARDING LOCAL EVENTS OF YOUR INTEREST.
<br/>
WE USE INFORMATION AS OTHERWISE PERMITTED BY LAW, TOWARDS LAW IF ANY OR AS WE MAY NOTIFY YOU.
<br/>
WE KEEP YOUR INFORMATION AS LONG AS IT IS NECESSARY OR RELEVANT FOR OUR STRUCTURED ORGANIZATION. WE ALSO MAY RETAIN INFORMATION TO RESOLVE DISPUTES, ENFORCE OUR AGREEMENTS AND OTHERWISE REQUIRED BY LAW IF ANY OR DISCARD THEM SECURELY WHEN THE FOLLOWING INFORMATION ARE NO LONGER NEEDED. FOR EXAMPLE, WHEN YOU ARE NO LONGER HOLDING AN ACCOUNT WITH US OR NO LONGER SUBSCRIBED TO OUR NEWSLETTER OR PUSH NOTIFICATION OR COMBINATION OF ALL.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
HOW WE SHARE YOUR INFORMATION & WHY:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE DO NOT RENT, OUTSOURCE, SELL YOUR INFORMATION TO ANYONE OR ANYWHERE OR 3RD PARTIES WITHOUT YOUR APPROVAL OR PRIOR CONSENT.
<br/><br/>
WE MAY SHARE INFORMATION WITHIN NEROLIFE NETWORKS PVT. LTD. & NEROLIFE GROUP OF COMPANIES. THIS MAY INCLUDE OUR CRM TOOLS - NIGHTCUBE, OUR DEVELOPERS - ECODERS LTD., AND PARTNER OUTLETS (FOR EXAMPLE, IF YOU ARE HEADING TOWARDS ANY ENLISTED OUTLET FOR EVENT), AND/OR ANY INHOUSE DEVELOPED API INTEGRATION PLATFORMS FOR EXAMPLE, PHONE MESSAGING SERVICES (PMS), CHAT BOTS & VIRTUAL CUSTOMER ASSISTANCE (ARTIFICIAL INTELLIGENCE BOTS / MACHINE LEARNING DIAGNOSTICS / HUMAN BEHAVIORAL PATTERN RECOGNITIONS)
<br/>
WE MAY SHARE INFORMATION WITH INTEGRATED COMPANIES WHO PERFORM SERVICES ON OUR BEHALF. FOR EXAMPLE, WE SHARE INFORMATION WITH COMPANIES WHO HELP US MANAGE OUR IT INFRASTRUCTURE OR WHO FULFILL YOUR BOOKINGS & RESERVATIONS OR ALLOCATE MEMBERSHIPS AND NEWSLETTERS. SOME VENDORS MAY BE OPERATING OUTSIDE OF BENGALURU, KARNATAKA, INDIA OR INDIA.
<br/>
WE MAY SHARE INFORMATION WITH OUR BUSINESS PARTNERS AS IN PARTNER OUTLETS WHERE EVENTS ARE TAKING PLACE. THIS INCLUDES A PARTNER OUTLET WHO OPERATES A VENUE WHERE WE HOLD EVENTS OR PARTNER OUTLET WHO ORGANIZE AND HOST AN EVENT IN NATURE OF THE ARTIST, PROMOTERS OR TEAM, OR SPONSOR OF AN EVENT. OUR PARTNERS USE THE INFORMATION WE GIVE THEM AS DESCRIBED IN THEIR PRIVACY POLICIES, WHICH MAY INCLUDE SENDING YOU MARKETING MATERIALS & PROMOTIONAL COMMUNICATIONS. YOU SHOULD READ THOSE POLICES TO LEARN HOW THEY TREAT YOUR INFORMATION.
<br/>
WE MAY SHARE INFORMATION IF WE THINK WE HAVE TO IN ORDER TO COMPLY WITH THE LAW OR TO PROTECT OURSELVES. FOR EXAMPLE, WE WILL SHARE INFORMATION TO RESPOND TO A COURT ORDER OR SUBPOENA. WE MAY ALSO SHARE INFORMATION IF A GOVERNMENT AGENCY OR INVESTIGATORY BODY REQUESTS IT. OR, WE MIGHT ALSO SHARE INFORMATION WHEN WE ARE INVESTIGATING POTENTIAL FRAUD TO PREVENT ILLEGAL, MANIPULATIVE OR DESTRUCTIVE ACTIONABLE BEHAVIOR.
<br/>
WE MAY SHARE INFORMATION WITH ANY SUCCESSOR TO ALL OR PART OF OUR BUSINESS. FOR EXAMPLE, IF PART OF OUR BUSINESS IS SOLD, WE MAY GIVE INFORMATION AS PART OF THAT TRANSACTION.
<br/>
WE MAY SHARE YOUR INFORMATION FOR REASONS NOT DESCRIBED IN THIS STATEMENT. YOU WILL BE INFORMED BY US AND/OR OUR GOVERNING BODIES BEFORE WE DO SO AS WE ARE BONDED TO APPLY SUCH TRANSACTION ONLY IN ACCORDANCE TO YOUR APPROVAL OR PRIOR CONSENT.
</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
YOUR CHOICES & RIGHTS:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
YOU HAVE CERTAIN CHOICES ABOUT HOW WE USE YOUR INFORMATION
<br/><br/>
YOU CAN OPT OUT OF RECEIVING OUR MARKETING OR PROMOTIONAL COMMUNICATIONS. TO STOP RECEIVING OUR PROMOTIONAL EMAILS, FOLLOW THE INSTRUCTIONS IN ANY MARKETING EMAIL YOU GET FROM US. YOU CAN ALSO CHANGE YOUR PREFERENCE SETTINGS IN YOUR ACCOUNT. IT MAY TAKE ABOUT 45 OPERATING DAYS TO PROCESS YOUR REQUEST. EVEN IF YOU OPT OUT OF GETTING MARKETING OR PROMOTIONAL COMMUNICATIONS, WE WILL STILL BE ABLE TO SEND YOU TRANSACTIONAL MESSAGES. FOR EXAMPLE, WE MAY STILL CONTACT YOU ABOUT YOUR BOOKING OR MEMBERSHIP.
<br/>
YOU CAN MODIFY INFORMATION YOU HAVE GIVEN US. TO CORRECT OR DELETE INFORMATION OR UPDATE ACCOUNT SETTINGS, LOG INTO YOUR ACCOUNT AND FOLLOW THE INSTRUCTIONS. WE MAKE CHANGES AS SOON AS WE CAN. THIS INFORMATION MAY STAY IN OUR BACKUP FILES. IF WE CANNOT MAKE THE CHANGES YOU WANT, WE WILL LET YOU KNOW AND EXPLAIN WHY. IF YOU CONTACT US REQUESTING ACCESS TO YOUR INFORMATION, WE WILL RESPOND WITHIN 90 OPERATING DAYS.
<br/>
YOU CAN CONTROL COOKIES AND TRACKING TOOLS.
<br/>
FOR MORE INFORMATION ON HOW TO MANAGE COOKIES AND OTHER TRACKING TOOLS, PLEASE CLICK HERE: <a  style = {{color : 'blue'}} href='HTTPS://WWW.CERILLION.COM/LEGAL/COOKIES'>HTTPS://WWW.CERILLION.COM/LEGAL/COOKIES</a>    
<br/>
YOU CAN CONTROL LOCATION TOOLS ON YOUR MOBILE DEVICES. FOR EXAMPLE, YOU CAN TURN OFF THE GPS LOCATOR OR PUSH NOTIFICATIONS ON YOUR DEVICE.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
PRIVACY LAW OF INDIA:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
UNDER THE PERSONAL DATA PROTECTION BILL, 2019 IF YOU ARE A RESIDENT OF INDIA AND HAVE AN ESTABLISHED CONSUMER RELATIONSHIP WITH US, YOU CAN WRITE AN EMAIL TO US REQUESTING A LIST OF THE PERSONAL INFORMATION WE HAVE SHARED WITH 3RD PARTIES & THEIR UTILIZATION PURPOSES. WE WILL ALSO PROVIDE YOU A LIST OF THE 3RD PARTIES THAT HAVE RECEIVED YOUR INFORMATION. YOU CAN MAKE REQUEST ONCE EVERY 3 YEAR.
<br/><br/>
MENTION IN YOUR EMAIL THAT YOU ARE MAKING A “PERSONAL DATA PROTECTION BILL” INQUIRY. WE WILL RESPOND TO SUCH WITHIN 90 DAYS POST RECEIVING YOUR REQUEST.
<br/>
FOR MORE INFORMATION ON “PERSONAL DATA PROTECTION BILL” PLEASE VISIT: <a  style = {{color : 'blue'}} href='HTTPS://WWW.PRSINDIA.ORG/BILLTRACK/PERSONAL-DATA-PROTECTION-BILL-2019'>HTTPS://WWW.PRSINDIA.ORG/BILLTRACK/PERSONAL-DATA-PROTECTION-BILL-2019</a> 
<br/><br/>
KARNATAKA STATE HUMAN RIGHTS COMMISSION, IF YOU ARE A RESIDENT OF KARNATAKA, YOU HAVE PRIVACY RIGHTS GOVERNED BY THE KARNATAKA STATE HUMAN RIGHTS COMMISSION (KSHRC) UNDER GOVERNMENT OF KARNATAKA (GOK)
<br/>
FOR MORE INFORMATION ON “KARNATAKA STATE HUMAN RIGHTS COMMISSION” PLEASE VISIT: <a  style = {{color : 'blue'}} href='HTTPS://KSHRC.KARNATAKA.GOV.IN/ENGLISH'>HTTPS://KSHRC.KARNATAKA.GOV.IN/ENGLISH</a>  
<br/><br/>
YOU MAY SUBMIT A REQUEST TO ENACT ANY OF THE ABOVE RIGHTS BY WRITTEN SUBMISSION THROUGH CUSTOMER ASSISTANCE EMAIL: NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM OR CALLING US (+91)-9740619310. WE MAY REQUIRE ADDITIONAL INFORMATION TO VERIFY YOUR IDENTITY BEFORE RESPONDING TO A KSHRC/GOK REQUEST.  WE WILL RESPOND TO YOUR REQUEST WITHIN 90 OPERATING DAYS IF SITUATION STANDS VALID AND REQUIRED UNDER THE LAW.
<br/>
PERSONAL INFORMATION COLLECTED ON USERS
<br/>
NAMES AND ALIASES
<br/>
PHONE NUMBER<br/>
E-MAIL ADDRESS<br/>
UNIQUE IDENTIFIERS<br/>
AGE GROUP<br/>
IP ADDRESS<br/>
INTERACTIONS WITH CUSTOMER ASSISTANCE<br/>
INFORMATION ABOUT TRANSACTIONS MADE ON ALL OF OUR PLATFORMS<br/>
PERSONAL PREFERENCES AND ATTRIBUTES<br/>
COOKIES/WEB BEACONS – WE USE TRACKING TOOLS LIKE BROWSER COOKIES AND WEB BEACONS<br/>
DEVICE ATTRIBUTES – GPS, WI-FI, IP ADDRESS<br/><br/>

PERSONAL INFORMATION DISCLOSED FOR ANY BUSINESS PURPOSE OR SOLD ABOUT USERS.

TO CANCEL SHARING OF YOUR PERSONAL INFORMATION FOR OUR SERVICE IMPROVEMENTS, PLEASE SEND “DO NOT SHARE MY PERSONAL INFORMATION” WITH YOUR CUSTOMER DETAILS VIA. EMAIL: NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM TO OUR CUSTOMER ASSISTANCE TEAM OR USE “CONTACT US” OPTIONS PRESENTED ABOVE TO SUBMIT A REQUEST.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
SOURCES OF DATA ABOUT USERS:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
SELF-DISCLOSED (EX. INFORMATION PROVIDED WHEN INTERACTING WITH OUR SOLUTIONS AND SERVICES, SITES, ALL DIGITAL PLATFORMS OPERATING UNDER US)<br/>
BUSINESS PARTNERS (EX. ANALYTICS COMPANIES, CRM SOFTWARE’S EXAMPLE NIGHTCUBE, PROMOTERS, ARTISTS, MEMBERSHIP FORUMS, PARTNER OUTLETS, ETC.)<br/>
DATA BROKERS (EX. MARKETING COMPANIES, ADVERTISING PARTNERS, PUBLIC FIGURES & INFLUENCERS, SOCIAL MEDIA ETC.)<br/>
TECHNICAL SERVICE PROVIDERS (EX. CLOUD STORAGE & SERVICE PROVIDERS, IT MAINTENANCE)

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
BUSINESS OR COMMERCIAL PURPOSES FOR COLLECTING OR SHARING PERSONAL INFORMATION ABOUT USERS:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
PROVIDING SOLUTIONS AND SERVICES<br/>
EVENT MANAGEMENT<br/>
MARKET RESEARCH AND PREFERENCE ANALYTICS<br/>
TO PREVENT, POTENTIAL FRAUD, ILLEGAL, MANIPULATIVE OR DESTRUCTIVE BEHAVIOR<br/>
MARKETING & PROMOTIONAL MATERIALS<br/>
CLIENT SERVICES<br/>
STAFF ADMINISTRATION, TRAINING & GUIDELINES

    
</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
THIRD PARTIES WITH WHOM WE SHARE PERSONAL INFORMATION:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
BUSINESS PARTNERS (EX. ANALYTICS COMPANIES, CRM SOFTWARE’S EXAMPLE NIGHTCUBE, PROMOTERS, ARTISTS, MEMBERSHIP FORUMS, PARTNER OUTLETS, ETC.)<br/>
DATA BROKERS (EX. MARKETING COMPANIES, ADVERTISING PARTNERS, PUBLIC FIGURES & INFLUENCERS, SOCIAL MEDIA ETC.)<br/>
TECHNICAL SERVICE PROVIDERS (EX. CLOUD STORAGE & SERVICE PROVIDERS, IT MAINTENANCE)
WE, OUR WEBSITES AND APPS, ENTITIES OPERATING WITH US, ENTITIES OPERATING FOR US, 3RD PARTIES ASSOCIATED, BUSINESS PARTNERS ASSOCIATED DO NOT RESPOND TO “DO NOT TRACK” REQUESTS AS DATA FROM BROWSERS, BROWSER COOKIES OR WEB BEACONS ARE TRACKED BY DEFAULT.
<br/>
OUR SITES AND APPS ARE NOT INTENDED FOR CHILDREN & MINORS BELOW 16 YEARS OF AGE AND MANY FUNCTIONS ARE VIEW ONLY FOR USERS BETWEEN 16 - 21 YEARS OLD.
<br/>
OUR SITES AND APPS ARE MEANT FOR ADULTS OVER 21 YEARS OF AGE. WE DO NOT KNOWINGLY COLLECT PERSONAL INFORMATION FROM CHILDREN UNDER 16. IF YOU ARE A PARENT OR LEGAL GUARDIAN AND THINK YOUR CHILD UNDER 16 HAS GIVEN US INFORMATION, YOU CAN EMAIL US. YOU CAN ALSO WRITE TO US AT THE ADDRESS LISTED AT THE END OF THIS POLICY. PLEASE MARK YOUR INQUIRIES “MINOR ACCESS INFORMATION REQUEST” 

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
LOOKING AFTER YOUR INFORMATION:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
WE USE STANDARD SECURITY LAYER (SSL PACKET)<br/><br/>
WE HAVE SECURITY MEASURES IN PLACE TO PROTECT YOUR INFORMATION. THE STANDARD SECURITY LAYER(SSL) MEASURES WE USE WILL DEPEND ON THE TYPE OF INFORMATION COLLECTED. HOWEVER, THE INTERNET IS NOT 100% SECURE. WE CANNOT PROMISE THAT YOUR USE OF OUR SITES WILL BE COMPLETELY SAFE. IF YOU THINK THAT YOUR ACCOUNT HAS BEEN MANIPULATED, HACKED AND/OR OTHERWISE ALTERED USING YOUR CREDENTIALS, KINDLY CONTACT US AT THE ADDRESS BELOW.

</h1>

<h1 className='my-4' style={{ fontSize : '17px', fontWeight: '500'}}>
WE STORE INFORMATION BOTH INSIDE AND OUTSIDE OF INDIA AND INDIAN PROVINCES:
</h1>

<h1 className='ps-3' style = {{lineHeight : '25px'}}>
IF YOU LIVE OUTSIDE INDIA, YOU UNDERSTAND AND AGREE THAT WE MAY TRANSFER YOUR INFORMATION TO THE LEGAL AUTHORITY & GOVERNMENT BODIES OF INDIA AS PER REQUIREMENT. YOU COMPLY THAT THIS SITE IS SUBJECT TO GOVERNMENT OF INDIA RULES & REGULATIONS, WHICH MAY OR MAY NOT AFFORD THE SAME LEVEL OF PROTECTION AS THOSE IN YOUR CURRENTLY RESIDING OR LIVING COUNTRY.<br/>
WE MAY HYPERLINK 3RD PARTY SITES OR SERVICES WE DON’T CONTROL IF YOU CLICK ON ONE OF THOSE LINKS, YOU WILL BE TAKEN TO WEBSITES WE DO NOT CONTROL. THIS STATEMENT DOES NOT APPLY TO THE PRIVACY PRACTICES OF THOSE WEBSITES. READ THE PRIVACY PRACTICES OF OTHER WEBSITES CAREFULLY. WE ARE NOT RESPONSIBLE FOR THESE 3RD PARTY SITES OR THEIR OPERATIONS. OUR SITE MAY ALSO SERVE 3RD PARTY CONTENT WHICH MIGHT CONTAIN 3RD PARTY COOKIES, WEB BEACONS OR OTHER TRACKING TECHNOLOGIES. WE DO NOT CONTROL THE USE OF THOSE TECHNOLOGIES.
<br/><br/>
CONTACT US:
<br/><br/>
PLEASE CONTACT US IF YOU WANT MORE INFORMATION
<br/><br/>
IF YOU HAVE MORE QUESTIONS & QUERIES ABOUT THIS PRIVACY STATEMENT OR ANY OTHER PRIVACY CONCERNS, YOU CAN WRITE AN EMAIL TO US STATING THE SAME. PLEASE DO NOT INCLUDE YOUR CREDIT CARD NUMBER OR OTHER SENSITIVE INFORMATION IN YOUR EMAIL.
<br/>
YOU CAN WRITE US AT:
<br/><br/>
CUSTOMER ASSISTANCE: NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM
<br/><br/>
NEROLIFE NETWORKS PVT. LTD<br/>
#17 KIRLOSKAR, 1ST CROSS<br/>
SAPTAGIRI, BENGALURU 560073, KARNATAKA, INDIA
<br/><br/>
ATTENTION: LEGAL
<br/><br/>
WHAT TO DO IF THERE IS AN UPDATE TO THIS STATEMENT
<br/><br/>
FROM TIME TO TIME WE MAY CHANGE OUR PRIVACY PRACTICES. WE WILL NOTIFY YOU OF ANY MATERIAL CHANGES TO THIS POLICY STATEMENT AS MAY BE REQUIRED BY LAW. WE WILL ALSO POST AN UPDATED COPY ON OUR WEBSITE. PLEASE CHECK OUR SITE PERIODICALLY FOR UPDATES.
<br/><br/>
© 2024 NEROLIFE NETWORKS PVT. LTD. ALL RIGHTS RESERVED.
<br/><br/>
<br/><br/>
</h1>




















        </div>
        <h1 className="text-light  text-center copyrightsection1 mt-5" style={{fontSize: '10px'}}>All Rights Reserved. Copyright 2024 © NEROLIFE NETWORKS PVT. LTD. </h1>
    </div>
   

)
}

export default PrivacyPolicy;
