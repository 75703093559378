// import React from "react";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import GoogleIcon from "@mui/icons-material/Google";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import CopyrightIcon from "@mui/icons-material/Copyright";
// import { useNavigate } from "react-router-dom";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import "./styles.css";

// const Footer = () => {
//   const navigate = useNavigate();
//   return (
//     <div className="w-100 footer pb-2">
//       <div className=" leftfooter">
//         <div className="newlettersection mb-2 ms-3 p-3">
//           <div className="">
//             <input
//               type="text newsletterinput"
//               className="form-control"
//               placeholder="Enter Your Email"
//               aria-label="Enter your Email"
//               aria-describedby="basic-addon2"
//               style={{ width: "400px" }}
//             />
//             <div className="flex align-center justify-center w-100">
//               <button
//                 type="submit"
//                 className="input-group-text"
//                 id="basic-addon2"
//               >
//                 <ArrowForwardIcon style={{ color: "white" }} />
//               </button>
//               <div className="newsletterdiv">
//                  <h6 className="newslettertext">NEWSLETTER</h6>
//               </div>
//             </div>
//           </div>
//         </div>

//         <ul className="nav justify-content-start ">
//           <li className="nav-item cursor-pointer">
//             <a className="nav-link text-light cursor-pointer" onClick={() => navigate("/terms-and-condition")} >
//               TERMS & CONDITIONS
//             </a>
//           </li>
//           <li className="nav-item cursor-pointer">
//             <a className="nav-link text-light cursor-pointer" onClick={() => navigate("/privacy")}>
//               PRIVACY
//             </a>
//           </li>
//           <li className="nav-item cursor-pointer">
//             <a
//               className="nav-link text-light cursor-pointer"
//               onClick={() => navigate("/company-profile")}
//             >
//               COMPANY PROFILE
//             </a>
//           </li>
//           <li className="nav-item cursor-pointer">
//             <a className="nav-link text-light cursor-pointer"
//               onClick={() => navigate("/aboutus")}
//             >
//               ABOUT US
//             </a>
//           </li>
//           <li className="nav-item cursor-pointer">
//             <a
//               className="nav-link text-light cursor-pointer"
//               onClick={() => navigate("/underconstruction")}
//             >
//               PARTNERS
//             </a>
//           </li>
//         </ul>
//       </div>
      
//       <div className=" centerfooter flex flex-column justify-center align-items-center">
//         <div className="flex socialicons">
//           <FacebookIcon className="me-5" style={{ color: "white" }} />
//           <InstagramIcon className="me-5" style={{ color: "white" }} />
//           <GoogleIcon className="me-5" style={{ color: "white" }} />
//           <TwitterIcon style={{ color: "white" }} />
//         </div>
//         <h1 className="text-light  mb-2 copyrightsection1" style={{ fontSize: "10px" }} >
//           All Rights Reserved. Copyright 2024 © NEROLIFE NETWORKS PVT. LTD.{" "}
//         </h1>
//       </div>

//       <div className="rightfooter">
//         <div className="footerright">
//           <ul className="nav rightfootermenu">
//             <li className="nav-item cursor-pointer rightsectiontxt">
//               <a className="nav-link text-light"
//                 href="https://www.ecoders.in"
//                 target="_blank"              >
//                 DEVELOPED & MAINTAINED BY{" "}
//                 <span className="font-semibold">ECODERS</span>
//               </a>
//             </li>

//             <li className="nav-item cursor-pointer rightsectiontxt">
//               <a
//                 className="nav-link text-light"
//                 href="https://www.ecoders.in"
//                 target="_blank"
//               >
//                 POWERED BY{" "}
//                 <span className="font-semibold">
//                   {" "}
//                   <CopyrightIcon className="me-1"></CopyrightIcon>NIGHTCUBE
//                   SYSTEMS
//                 </span>
//               </a>
//             </li>

//             <li className="nav-item cursor-pointer rightsectiontxt">
//               <a
//                 className="nav-link text-light"
//                 href="https://www.ecoders.in"
//                 target="_blank"
//               >
//                 AN ORIGINAL{" "}
//                 <span className="font-semibold">NEROLIFE NETWORKS</span>{" "}
//                 PRODUCTION
//               </a>
//             </li>
//           </ul>
//           <h1 className="text-light text-center copyrightsection2" style={{ fontSize: "10px" }}>
//             All Rights Reserved. Copyright 2024 © NEROLIFE NETWORKS PVT. LTD.
//           </h1>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Footer;



import React from 'react';
import './Footer.css';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";
import XIcon from '@mui/icons-material/X';
import { useNavigate } from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
  return (
    <div className='finalfooter'>

                <div className='newslettersection'>
                    <div className="input-group input-group-sm mb-1">
                        <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" />
                    </div>

                    <div className='newsletterdiv'>
                    <p className='newletterpara text-light'>NEWSLETTER</p>
                    <button
                        type="submit"
                        className="input-group-text"
                        id="basic-addon2"
                    >
                        <ArrowForwardIcon style={{ color: "white"}}/>
                    </button>
                    
                    </div>
                    
                </div>
                <hr className='text-warning mt-3 mb-3'/>

        <div className='mainfooter mt-3'>
            <div className='footerleft mb-3'>
            <ul className="nav" 
            stytle={{marginLeft: "-10px"}}>
<small><li className="nav-item cursor-pointer">
                    <a className="nav-link text-light" onClick={() => navigate("/terms-and-condition")}>TERMS & CONDITIONS</a>
                </li></small>
                

                <small><li className="nav-item cursor-pointer">
                    <a className="nav-link text-light" onClick={() => navigate("/privacy")}>PRIVACY</a>
                </li></small>

                <small>
                <li className="nav-item cursor-pointer">
                    <a className="nav-link text-light"  onClick={() => navigate("/company-profile")} >COMPANY PROFILE</a>
                </li>
                </small>

            <small>
            <li className="nav-item cursor-pointer">
                                <a className="nav-link text-light"  onClick={() => navigate("/aboutus")} >ABOUT US</a>
                            </li>
            </small>
                
                <small>
                <li className="nav-item cursor-pointer">
                <a className="nav-link text-light"   onClick={() => navigate("/underconstruction")} >PARTNERS</a>
                </li>
                </small>

                </ul>
            </div>

            <div className='footercenter mb-3'>
            <ul className="nav ">
                <li className="nav-item cursor-pointer">
                    <a className="nav-link text-light" aria-current="page" href="https://www.facebook.com/NROLYF"><FacebookIcon /></a>
                </li>
                <li className="nav-item cursor-pointer">
                    <a className="nav-link text-light" href="https://www.instagram.com/nrolyf/">
                        <InstagramIcon />
                    </a>
                </li>
                <li className="nav-item cursor-pointer">
                    <a className="nav-link text-light" href="https://g.co/kgs/ywJZCYD">
                        <GoogleIcon />
                    </a>
                </li>
                <li className="nav-item cursor-pointer">
                    <a className="nav-link text-light" href="https://twitter.com/NerolifeN">
                        <XIcon />
                    </a>
                </li>
                </ul>
            </div>

            <div className='footerright mb-3'>
            <ul className="nav flex-column">
                <li className="nav-item cursor-pointer lh-1">
                   <small><a className="nav-link lastlinks text-light lh-1" aria-current="page" href="https://bevyinsight.com" target="_blank">DEVELOPED & MAINTAINED BY <b>BEVY INSIGHT</b></a></small> 
                </li>
               <small>
               <li className="nav-item cursor-pointer lh-1">
                    <a className="nav-link lastlinks text-light lh-1" href="#">POWERED BY <b>NIGHTCUBE SYSTEMS</b></a>
                </li>
                </small> 

                <small>
                <li className="nav-item cursor-pointer lh-1">
                    <a className="nav-link lastlinks text-light lh-1" href="#">AN ORIGINAL <b>NEROLIFE NETWORKS</b> PRODUCTIONS</a>
                </li>
                </small>

                </ul>
            </div>
        </div>

        <div className='footerlast text-center mt-2  '>
             <small><small><p className='text-light'>All Rights Reserved Copyright 2024 &copy; NEROLIFE NETWORKS PVT LTD</p></small></small>   
            </div>
    </div>
  )
}

export default Footer;