import React from "react";
import "./Outlets.css";
import Footer from "../components/Footer";

import { useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate } from "react-router-dom";

import { Banner } from "../components/Banner";
import "../components/styles.css";

const Outletcontent2 = () => {
  const navigate = useNavigate();

  const [outletData, setOutletData] = useState([]);

  const [nightclubs, setNightclubs] = useState([]);
  const [lounges, setLounges] = useState([]);
  const [concertHalls, setConcertHalls] = useState([]);
  const [pubsandBreweries, setPubsandBreweries] = useState([]);
  const [openAir, setOpenAir] = useState([]);
  const [rooftopcafeAndBars, setRooftopcafeAndBars] = useState([]);
  const [restoCafes, setRestocafes] = useState([]);
  const [poolside, setPoolside] = useState([]);

  const [outleticons, setOutletIcons] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  useEffect(() => {
    let isMounted = true;

    const getOutletData = async () => {
      const response = await axiosPrivate.get("/outlets", {});
      setOutletData(response.data);
    };

    isMounted && getOutletData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (outletData.length > 0) {
      const images = outletData?.map((outlet) => ({
        id: outlet.outlet_id,
        name: outlet.outlet_name,
        image: outlet.outlet_icon,
      }));

      setOutletIcons(images);
    }
  }, [outletData]);

  useEffect(() => {
    let isMounted = true;

    const getOutletsbyCategory = async (outletCategory) => {
      try {
        const response = await axiosPrivate.get(
          `/outlets/type/${outletCategory}`
        );
        // isMounted && setAllEvents(response.data);
        outletCategory === "PUB & BREWERY"
          ? setPubsandBreweries(response.data)
          : outletCategory === "POOLSIDE"
          ? setPoolside(response.data)
          : outletCategory === "CONCERT HALL"
          ? setConcertHalls(response.data)
          : outletCategory === "LOUNGE"
          ? setLounges(response.data)
          : outletCategory === "OPEN AIR"
          ? setOpenAir(response.data)
          : outletCategory === "ROOFTOP CAFE AND BAR"
          ? setRooftopcafeAndBars(response.data)
          : outletCategory === "RESTO CAFE AND BAR"
          ? setRestocafes(response.data)
          : setNightclubs(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getOutletsbyCategory("NIGHTCLUB");
    isMounted && getOutletsbyCategory("POOLSIDE");
    isMounted && getOutletsbyCategory("CONCERT HALL");
    isMounted && getOutletsbyCategory("LOUNGE");
    isMounted && getOutletsbyCategory("OPEN AIR");
    isMounted && getOutletsbyCategory("RESTO CAFE AND BAR");
    isMounted && getOutletsbyCategory("PUB & BREWERY");
    isMounted && getOutletsbyCategory("ROOFTOP CAFE AND BAR");

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="layout mt-5 p-5 mb-5">
      <div>
        <div className="mt-5 mb-5 flex flex-column">
          {outletData && (
            <Banner
              images={nightclubs}
              speed={20000}
              type="videos"
              margin="8px"
            />
          )}
          <h1
            className="nightclubstext text-light mt-5  font-semibold"
            style={{ fontSize: "22px" }}
          >
            NIGHTCLUBS
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {outletData && (
            <Banner
              images={lounges}
              speed={20000}
              type="images"
              videos
              margin="8px"
              direction="right"
            />
          )}
          <h1
            className="nightloungetext text-light mt-5 me-4 font-semibold"
            style={{ fontSize: "22px" }}
          >
            LOUNGES
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column justify-content-start">
          {outletData && (
            <Banner
              images={restoCafes}
              speed={20000}
              type="videos"
              margin="8px"
            />
          )}
          <h1
            className="restocafetext text-light mt-5  font-semibold"
            style={{ fontSize: "22px" }}
          >
            RESTO CAFE AND BARS
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {outletData && (
            <Banner
              images={pubsandBreweries}
              speed={20000}
              type="images"
              videos
              margin="8px"
              direction="right"
            />
          )}
          <h1
            className="pubsandbrewerestext text-light mt-5 me-4 font-semibold"
            style={{ fontSize: "22px" }}
          >
            PUBS & BREWERIES
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column justify-content-start">
          {outletData && (
            <Banner images={openAir} speed={20000} type="videos" margin="8px" />
          )}
          <h1
            className="openairtext text-light mt-5  font-semibold"
            style={{ fontSize: "22px" }}
          >
            OPEN AIR
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {outletData && (
            <Banner
              images={rooftopcafeAndBars}
              speed={20000}
              type="images"
              videos
              margin="8px"
              direction="right"
            />
          )}
          <h1
            className="rooftoptext text-light mt-5 me-4 font-semibold"
            style={{ fontSize: "22px" }}
          >
            ROOFTOP CAFE AND BARS
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column justify-content-start">
          {outletData && (
            <Banner
              images={concertHalls}
              speed={20000}
              type="videos"
              margin="8px"
            />
          )}
          <h1
            className="concerthalltext text-light mt-5  font-semibold"
            style={{ fontSize: "22px" }}
          >
            CONCERT HALLS
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {outletData && (
            <Banner
              images={poolside}
              speed={20000}
              type="images"
              videos
              margin="8px"
              direction="right"
            />
          )}
          <h1
            className="poolsidetext text-light mt-5 me-4 font-semibold"
            style={{ fontSize: "22px" }}
          >
            POOLSIDE
          </h1>
          <hr className="text-warning" style={{ marginTop: "10px" }} />
        </div>
      </div>

      <div className="footer-overlay">
        <Footer />
      </div>
    </div>
  );
};

export default Outletcontent2;
