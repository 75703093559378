import React from 'react';
import public_info from "../assets/public_info.jpg";
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
function AboutUS() {

  return (
   
    <div className='publicpaper'  >
    
        <div className='m-auto rounded p-3 ' style={{ position: 'relative', height: '80%', overflow: 'scroll', width: '90%', backgroundColor: 'whitesmoke', backgroundImage: `url(${public_info})`, backgroundSize: 'cover' }}>
        
          <h1 className='text-justify ms-3' style={{ lineHeight: '25px', fontWeight: '500', margin: '2rem' }}>
          
          ABOUT NEROLIFE NETWORKS:
        </h1>

        <h1 className='text-justify ms-3' style={{ lineHeight: '25px', fontWeight: '500' }}>
          NEROLIFE NETWORKS PVT. LTD., REGISTERED 2019, IS A COMPANY DEDICATED TOWARDS NIGHTLIFE SOCIAL & CLUB CULTURE TO PROVIDE COMPREHENSIVE SOLUTIONS AS AN AGGREGATOR BETWEEN EVENT DRIVEN CONSUMERS & MEDIA ENTERTAINMENT OUTLETS AS IN NIGHTCLUBS, PUBS ETC. WHERE CULTURAL EVENTS INVOLVING ELECTRONIC MUSIC, SOUND PRODUCERS, DJ’S PERFORMING LIVE MUSIC ARE ORGANIZED ON REGULAR BASIS TOWARDS ENTERTAINMENT.
          <br /><br />
          BEING CONSUMER CENTRIC SINCE INCEPTION, WE’VE OBSESSED TOWARDS UNDERSTANDING MUSIC INTEREST AND EVENTS PREFERENCE OF EACH INDIVIDUAL THEN REVERSE ENGINEERED TO PROVIDE SOLUTIONS WHICH ENSURES OPTIONS CATERING A PERFECT NIGHTOUT BLENDED WITH PERSONAL MUSIC TASTE & EVENT PREFERENCE SAVING TIME & EFFORT OF INDIVIDUAL WHILE CONSISTENTLY MATCHING BUSINESS REQUIREMENTS OF ENTERTAINMENT OUTLETS WHERE SHOWS ARE BEING HOSTED.
          <br /><br />
          DISRUPTING ENTERTAINMENT INDUSTRY NORMS WITH BREAKTHROUGH TECHNOLOGY, IMPLEMATION OF BUSINESS ANALYTICS, OUR SYSTEM ALLOWS EACH INDIVIDUAL TO EXPLORE THE CATEGORY OF MUSIC & TYPE OF EVENTS THEY WANT TO EXPERIENCE WITH SUGGESTIONS OF EVENTS IN PLACES HOSTING THE SAME CATEGORY OF MUSIC & TYPE OF EVENT ACCORDING TO INDIVIDUAL’S OWN CHOICE ALONGSIDE KEY INFORMATION TO MAKE INFORMED DECISION OVER ALTERNATIVE INVITES OR 3RD PARTY REFERRED.
          <br />


        </h1>

        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          WHY WE DO WHAT WE DO:
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          WE WANT TO SAVE YOU TIME AND MAKE SOUND DECISIONS
          <br /><br />
          “WE WANT INDIVIDUALS TO GO WHERE THEY WANT TO GO BUT ALSO KNOW WHERE THEY'RE GOING, AS PER THEIR PREFERENCE “
          <br /><br />
          THROUGH OUT THE BUSY SCHEDULE OF EVERY UBER MILLENIAL & GEN Z, BOTTLENECK CITY TRAFFIC, WE WANT INDIVIDUALS TO GO WHERE THEY WANT TO GO BUT ALSO KNOW WHERE THEY'RE GOING. PEOPLE ARE LOOKING FOR SOLUTIONS THAT CAN BE FILTERED FOR THEM BY SORTING INFORMATIONS AS PER INDIVIDUAL MUSIC TASTE, EVENT PREFERENCE & LIFE STYLE GUIDED BY SMART SUGGESTIONS VIA. PERSONALISED CHOICES BY REACHING OUT TO THE RIGHT PEOPLE ON THE RIGHT OCCASION OVER TRADITIONAL PRACTICES SAVING THEIR LEISURE TIME FOR THEMSELVES.
          <br /><br />
          WE AT NEROLIFE NETWORKS, SERVES ITS PURPOSE BY PROVIDING SOLUTIONS & SERVICES THAT RESPECT & REPRESENTS EACH & EVERY INDIVIDUAL'S ENTERTAINMENT PREFERENCE & RIGHTS TO EXPERIENCES INVOLVING EVENTS AT OUTLETS WITH ARRAY OF MULTIPLE CATEGORY OF MUSIC & TYPE OF EVENTS BY BETTER UNDERSTANDING OF INDIVIDUAL NEEDS TO MATCH OUTLET’S BUSINESS REQUIREMENTS.

        </h1>

        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          FOR EVERY INDIVIDUAL PERSON:
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          WE WANT TO SAVE YOU TIME AND MAKE SOUND DECISIONS
          <br /><br />
          THROUGH OUT THE BUSY SCHEDULE OF EVERY UBER MILLENIAL & GEN Z, BOTTLENECK CITY TRAFFIC, WE WANT INDIVIDUALS TO GO WHERE THEY WANT TO GO BUT ALSO KNOW WHERE THEY'RE GOING. PEOPLE ARE LOOKING FOR SOLUTIONS THAT CAN BE FILTERED FOR THEM BY SORTING INFORMATIONS AS PER INDIVIDUAL MUSIC TASTE, EVENT PREFERENCE & LIFE STYLE GUIDED BY SMART SUGGESTIONS VIA. PERSONALISED CHOICES BY REACHING OUT TO THE RIGHT PEOPLE ON THE RIGHT OCCASION OVER TRADITIONAL PRACTICES SAVING THEIR LEISURE TIME FOR THEMSELVES.

        </h1>

        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          FOR EVERY BUSINESS OUTLET
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          WE WANT TO CRAFT A MEANINGFUL RELATIONSHIP BETWEEN YOU AND YOUR COMMUNITY
          <br /><br />
          NEROLIFE NETWORKS 2PROVIDES SOLUTIONS & SERVICES THAT REPRESENTS INDIVIDUAL CUSTOMER ENTERTAINMENT PREFERENCE & INTEREST TO ENTERTAINMENT OUTLETS INVOLVING MULTIPLE CATEGORY OF MUSIC & TYPE OF EVENTS FOR BETTER UNDERSTANDING OF CUSTOMER NEEDS TO MATCH OUTLET’S BUSINESS REQUIREMENTS.

        </h1>
        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          OUR STORY:
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          MUSIC HAVE ALWAYS BEEN THE CORE ASSET BEHIND ALL FUNDAMENTAL OF OUR BUSINESS ENGAGEMENTS FROM INCEPTION TILL DATE & FUTURE WITH THE FASCINATION & IMAGINATION OF THE FOUNDERS, PARTNERS AND EVERYONE INVOLVED WITH THE COMPANY TO LEARN ABOUT EACH AND EVERY INDIVIDUAL PERSON, THEIR PERSONAL TASTE IN MUSIC, DEVELOPMENT OF TASTE ACCORDING TO MOOD, AND WORKING CONSISTENTLY TOWARDS PROVIDING SOLUTIONS THAT SENTIMENTS EVERY INDIVIDUAL PERSON'S EMOTIONAL ENGAGEMENT AND AFFECTION TOWARDS MUSIC & NIGHTOUTS, WHILE ALSO SIMPLIFYING THE HIGHLY COMPLEX ENTERTAINMENT INDUSTRY TO CURATE BETTER EXPERIENCE AS A WHOLE.
          <br /><br />
          WE WELCOME YOU TO OUR JOURNEY ACCESSING ALL AREAS WE HAVE HAD DEALT WITH INCLUDING DOWNSTREAM ENDURANCE OF FAILURES, UPSTREAM EXPERIENCES OF SUCCESS, AS WELL AS LEAPS ASPIRED EVER SINCE OUR ACTIVE STINT IN THE INDUSTRY SINCE 2017.


        </h1>

        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          WHEN WE STARTED:
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          ORIGINALLY INCEPTED ON AUGUST 2017, OFFLINE, UNREGISTERED, NEROLIFE STARTED OPERATIONS ON BOOTSTRAP BASIS AS A SUBSIDY EVENT MANAGEMENT COMPANY, IN THE NIGHTLIFE BASED CATEGORY OF MUSIC EVENTS & ENTERTAINMENT INDUSTRY TO LEARN AND UNDERSTAND EVENT CURATION WITH THE FOUNDING TEAM ENGAGING PERMUTATION & COMBINATION OF A/B TESTINGS WITH WIDE VARIETY OF VERSATILE INTERNAL ASSOCIATIONS, MUSIC PRODUCERS, SOCIAL FIGURES & MEDIA INFLUENCERS AND HENCE THE TERM NETWORKS WAS ADDED.
          <br /><br />
          AS FOR EVENTS ORGANIZED, NEROLIFE NETWORKS CURATED CONCEPT NIGHTS THROUGH WHICH WE LIMITED THE SCOPE OF MASS INTERVENTION BY INCREASING REACTION & INTEREST OF MUSIC CATEGORY & EVENT TYPE SPECIFIC TO TARGET AUDIENCE.
          <br /><br />
          WE FOCUSED ON GROUPS & COMMUNITIES HAVING PREFERENCE OF FOLLOWING HIP-HOP/BASS CULTURE WITH LIVE ELECTRONIC MUSIC.
          <br /><br />
          WE REACHED OUT FOR GROUPS & COMMUNITIES HAVING PREFERENCE ON DEEP, HOUSE, CHILL, INDUSTRY, WAREHOUSE TECHNO JAMS TO PROJECT FOOTFALL.
          <br /><br />
          EACH CONCEPT WE DESIGNED WERE DRESSED FOR A SPECIFIC GROUP OF ENTHUSIASTS & ACTIVISTS WITH RESPECT TO THE PARTICULAR CONCEPT RESULTED IN HIGH REACTION AND RESPONSE OVER MASS INTERVENTION.
        </h1>

        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          THE MARKET:
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          WE HAVE FACED CHALLENGES TOO
          <br /><br />
          POST DESIGNING & PROGRAMMING OF ANY EVENT, WE HAVE ALWAYS HAD FOLLOWED INDUSTRY SPECIFIC TRENDS & TRADITIONAL MEASUREMENTS BY REACHING OUT TO POTENTIAL MUSIC DRIVEN CONSUMERS EXPONENTIALLY THROUGH MULTIPLE CHANNELS OF COMMUNICATION.
          <br /><br />
          OUR UPSTREAM EXPERIENCES WERE INVOLVED WITH UBER EXPOSED PAGE 3 CONTENTS & SATISFIED COMMUNITIES AS BACK BONE TO OUR OPERATIONS BUT WE UNDERSTOOD THERE IS A LIMITED LEARNING CURVE TO SUCCESS COMPARED TO HIDDEN ARRAYS OF OPPORTUNITY.
          <br /><br />
          IN DOWNSTREAMING ENDURANCE OF FAILURES., WHILE HOSTING A CLUB EVENT IN A 5-STAR HOTEL, ONE GROUP OF 26 MEMBERS AMONGST MANY ATTENDING THE EVENT LEFT TOGETHER HALFWAY THROUGH THE SHOW IN FAVOR OF AN ALTERNATIVE CATEGORY OF MUSIC ON ANOTHER OUTLET.
          <br /><br />
          ON MULTIPLE INSTANCES, WE OBSERVED MULTIPLE SMALL GROUPS KEPT SWITCHING BACK AND FORTH BETWEEN OUTLETS OF DISTINCTIVE HOTELS IRRESPECTIVE TO HOSTING HIGHLY RATED INTERNATIONAL MUSIC ARTISTS.
          <br /><br />
          ALSO AS RECENT AS IT CAN BE, WHILE HOSTING EVENT IN A PREMIUM OUTLET IN CBD, GROUP OF INDIVIDUALS ATTENDING THE SHOW WOULD GO & EXPLORE EVERY PREMIUM OUTLET NEARBY BEFORE JOINING US BACK AGAIN FOR THE NIGHT.
          <br /><br />
          FURTHER CASE STUDY OF TRADITIONAL PRACTICES, UNDER MULTIPLE SCENARIOS, WE NOTICED A CONCERNING PATTERN OF CONSISTENT DESCREASING CUSTOMER BANDWIDTH I.E. DECREASE OF AVERAGE CONVERTION TO REACH (CPR VALUE) OR FOOTFALL, DYNAMIC INFLATION RATE, DECREASE OF AVERAGE EXPENDITURE PER CUSTOMER (APC VALUE) OR REVENUE, INCREASE OF COST PER ACQUISITION (CPA VALUE) OR MARKETING COST, COMBINATION OF WHICH ARE SLOWING DOWN THE EVENT DRIVEN ENTERTAINMENT INDUSTRY.
          <br /><br />
          PROMOTIONALS IN THE MARKET PLACE ARE GUIDED TOWARDS ATTRACTING CUSTOMERS THROUGH ASSUMPTIONS & EXPERIENCES WITHOUT DATA DRIVEN ACKNOWLEDGEMENT OF CUSTOMER PREFERENCE RESULTING A STEEP FALL IN CUSTOMER RESPONSE.
          <br /><br />
          INDIVIDUALS ARE TARGETTED & COMMUNICATED IN MASS WITH MULTIPLE EVENT INVITATIONS & PROMOTIONAL MESSAGES ON EVERYDAY BASIS WITHOUT INDIVIDUAL CONSENT YEILDING VERY LIMITED RESPONSE ALSO RESULTING A STEEP FALL IN CUSTOMER RESPONSE.
          <br /><br />
          DESPITE UTILIZATION OF KEY INSIGHTS ON EVENT INFOMERCIALS, LACK OF SEGREGATED CUSTOMER PREFERENCE KNOWLEDGE WITH DATA AMONGST THE INDUSTRY IS PREVENTING HOSTING OUTLETS TO NAVIGATE BUSINESS AS PER THEIR OWN CUSTOMER AND COMMUNITY PREFERENCE CAUSING IN-ACTION WHICH IS ALSO DECREASING CONVERTION EFFICIENCY ON REGULAR BASIS.

        </h1>

        <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
          NIGHTCUBE SAaS © NEROLIFE NETWORKS:
        </h1>

        <h1 className='ps-3' style={{ lineHeight: '25px' }}>
          NIGHTCUBE SYSTEM ENGINE PROVIDES OUTLETS WITH KEY INSIGHTS ABOUT THEIR REGULAR CUSTOMERS AS IN CUSTOMER OUTLOOK & CHOICES STATISTICALLY WITH ANALYTICS BASED DISTINGUISHED DIVERSIFICATIONS TO UNDERSTAND & CUSTOMIZE BUSINESS AS PER CUSTOMER PREFERENCE INCREASING EFFICIANCY OF OVERALL INTERACTION WHILE DECREASING PROBABILITY OF ASSUMPTIONS AND PROPOSITIONS.
          <br /><br />
          NIGHTCUBE SAaS SYSTEM ENGINE © NEROLIFE NETWORKS IS INDIA’S FIRST DIGITAL NIGHTLIFE BASED INFOTAINMENT & CUSTOMER MANAGEMENT PLATFORM DESIGNATED FOR MUSIC ENTERTAINMENT ORIENTED INDIVIDUALS & BUSINESS OUTLETS HOSTING EVENTS. NIGHTCUBE REPRESENTS CUSTOMER ENTERTAINMENT PREFERENCE TO ENTERTAINMENT OUTLETS INVOLVING MULTIPLE CATEGORY OF MUSIC & TYPE OF EVENTS FOR BETTER UNDERSTANDING OF CUSTOMER NEEDS TO MATCH OUTLET’S BUSINESS REQUIREMENTS. WITH BREAK THROUGH TECHNOLOGY & DATA DRIVEN CRM ANALYTICS WE AIM TO BRING MUSIC AND ENTERTAINMENT SERVICES TO THE MUSIC ORIENTED CONSUMERS WITH EVENTS OF THEIR OWN PREFERENCE CATERING MUSIC OF THEIR OWN CHOICE. OUR TEAM DEDICATES HOURS IN DEVELOPING & CAREFULLY CRAFTING EACH SECTION TO PROVIDE RICH & SEAMLESS CUSTOMER EXPERIENCE. WE TAKE UTMOST EFFORTS TO BALANCE CONSUMER SATISFACTION & CLIENTS BUSINESS EFFICIENCY AND MAINTAIN COMPLETE TRANSPARENCY AMONGST ALL ENTITIES INVOLVED. WE ASPIRE INNOVATION, TECHNOLOGY & OPENNESS AND ARE EAGER TOWARDS EVERYONE’S CONTRIBUTION OF FEEDBACK & SUGGESTIONS.
          <br /><br />
          A PLATFORM CRAFTED TO ENSURE A MEANINGFUL LONGTERM RELATIONSHIP BETWEEN OUTLETS AND THIER OWN CUSTOMER SATISFACTION.
          <br /><br />
          <br /><br />
        </h1>
      </div>
      <h1 className="text-light  text-center copyrightsection1 mt-5" style={{ fontSize: '10px' }}>All Rights Reserved. Copyright 2024 © NEROLIFE NETWORKS PVT. LTD. </h1>
    </div>
     
  
  )
}

export default AboutUS
