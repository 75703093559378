//for local

import axios from "axios";

//  const BASE_URL = "http://localhost:8000/api";
const BASE_URL = "https://nerolife.in/api";


export default axios.create({
  baseURL: BASE_URL,
});

axios.defaults.withCredentials = true;

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});


//for uploading

// import axios from "axios";

// var domain = window.location.hostname
// var port = window.location.port
// var protocol = window.location.protocol
// if (port !==""){
//     domain = domain+":"+port;
// }
// domain = protocol+"//"+domain;
// const BASE_URL = domain + "/api";
// export default axios.create({
//   baseURL: BASE_URL,
//   withCredentials: true,
// });

// axios.defaults.withCredentials = true;

// export const axiosPrivate = axios.create({
//   baseURL: BASE_URL,
//   headers: { "Content-Type": "application/json" },
//   withCredentials: true,
// });
