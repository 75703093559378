import React from "react";

import { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { axiosPrivate } from "../api/axios";

const NightcubeInbox = () => {
  const location = useLocation();
  const { userRole } = location.state;
  const userId = localStorage.getItem("userId");

  const [inbox, setInbox] = useState([]);

  const getInbox = async () =>{
    const result = await axiosPrivate.get("/inbox");
    let inbox_messages = result.data;
    const filteredMessages = inbox_messages.filter(message => {
      // Assuming `message_receivers` is an array of objects containing receiver information in each message
      // and you want to filter messages based on whether any receiver's id matches the userId
      
      // Extracting receiver ids from the message receivers array
      const receiverIds = message.message_receivers.map(receiver => receiver.id);
    
      // Checking if the userId matches with any receiver id
      return receiverIds.includes(userId);
    });


    setInbox(filteredMessages);
  }

  const getUnarchived = async () =>{
    const result = await axiosPrivate.get("/inbox/unArchived");
    let inbox_messages = result.data;
    const filteredMessages = inbox_messages.filter(message => {
      const receiverIds = message.message_receivers.map(receiver => receiver.id);
      return receiverIds.includes(userId);
    });
    setInbox(filteredMessages);
  }


  

  const handleDeletMessage =  async (id) =>{

    if(userRole === 'superadmin'){
        let userConfirmed = window.confirm("Are You Sure To Delete This Message ? ");
        if (userConfirmed) {
            const result = await axiosPrivate.delete(`/inbox/delete/${id}`);
            alert("Message Deleted Succesfully");
            getInbox();
        } 
    }else{
        const result = await axiosPrivate.patch(`/inbox/archive/${id}`);
        alert("Message Removed");
        getUnarchived();
    }
  }



  useEffect(()=>{
    let isMounted = true;
    if(userRole === 'superadmin'){
        isMounted && getInbox();
    }else{
        isMounted && getUnarchived();
    }
    return()=>{
        isMounted = false;
    }
  }, []);

  return (
    <div className="parent">
      <div className="p-4" >

        {inbox.map((message, index) =>(

          <div key={index} className="border mb-2 rounded flex text-dark" style={{ width: "100%", minHeight: '80px', overflow: 'hidden' }}>
          <div className="p-2 flex align-items-center " style={{ width: "10%", borderRight: '1px solid #ccc', backgroundColor : '#4f4b4a' }}>
              <h1  style={{ fontSize: "17px", fontWeight: '500',  color: message.category === 'Artist' ? "#e31013" : message.category === 'Outlet' ? "#14dbc4":message.category === 'Event' ? "orange" : message.category === 'HELP'?'red':'greenYellow'}}>{message.subject}</h1>
          </div>
          <div className="p-2 flex align-items-center" style={{ width: "85%",  backgroundColor: 'white', whiteSpace :'pre-line' }}>
              <p style={{ fontSize: "15px" }}>{message.message}</p>
          </div>
          <div className="border cursor-pointer flex align-items-center justify-content-center" style={{ width: "5%",  backgroundColor: 'white' }}>
              <DeleteForeverIcon onClick={() => handleDeletMessage(message._id)} style={{ fontSize: '27px' }} />
          </div>
          </div>

        ))}

       

      </div>
    </div>
  );
};

export default NightcubeInbox;
