import React from "react";

import { useEffect } from "react";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Modal from "@mui/material/Modal";

import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import SearchIcon from "@mui/icons-material/Search";

import { axiosPrivate } from "../api/axios";

import {useNavigate, useLocation} from "react-router-dom"

import "./Outlets.css";
import { isOverflown } from "@mui/x-data-grid/utils/domUtils";





const NightcubePOS = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const location = useLocation();
  const  state  = location.state;

  let userRole = state && state.userRole;


  const navigate = useNavigate();

  const [searchingByContact, setSearchingByContact] = React.useState(false);
  const [searchingByEmail, setSearchingByEmail] = React.useState(false);

  const [searchingByTransactionId, setSearchingByTransactionId] = React.useState(false);

  const [searchButtonClicked, setSearchButtonClicked] = React.useState(false);

  const [customerData, setCustomerData] = React.useState({});

  const [outletData, setOutletData] = React.useState({});

  const [posData, setPosData] = React.useState([]);

  const [row, setRow] = React.useState([]);

  const [contact, setContact] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [grossAmount, setGrossAmount] = React.useState();
  const [totalAmount, setTotalAmount] = React.useState();
  const [coupon, setCoupon] = React.useState("");
  const [promotion, setPromotion] = React.useState(0);
  const [finalAmount, setFinalAmount] = React.useState(0);

  const [posCount, setPosCount] = React.useState();

  const [eligibility, setEligibility] = React.useState(false);
  const [transactionCategory, setTransactionCategory] = React.useState(false);

  const paddingLength = 10;

  const [admins, setAdmins] = React.useState([]);

  const handleOpenCase = (row)=>{
    setOpen(true);
    getOutletDatabyId(row.outlet_id)
    setSearchingByTransactionId(true);

    handleSearchbyTransactionId(row.transaction_id)
  }

  const columns = [
    { field: "id", headerName: "SL NO", width: 80 },
    {
      field: "transaction_id",
      // approved or not approved
      headerName: "Transaction Id",
      width: 150,
      headerAlign: "left",
      align: "center",
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 150,
      headerAlign: "left",
    },
  
    {
      field: "phone",
      headerName: "Customer Phone",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "outlet_id",
      headerName: "Outlet Id",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
  
    {
      field: "outlet_name",
      headerName: "Outlet Name",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
            const onClick = () => navigate(`/singleOutlet/${params.row.outlet_id}/${params.row.outlet_name}`);
            return <div className="cursor-pointer" onClick={onClick}>{params.row.outlet_name}</div>;
          }
    },
  
  
    {
      field: "gross_amount",
      headerName: "Gross Amount",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "eligibility",
      headerName: "Eligibility",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "total_amount",
      // total amount  = gross amount + gst (18%)
      headerName: "Total Amount",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "coupon",
      headerName: "Coupons",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "final_amount",
      // after discount
      headerName: "Final Amount",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "status",
      // approved or not approved
      headerName: "Status",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  
    userRole !== 'outlet' &&
   
  
    { 
      field: 'controls',
      headerName: 'Controls',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
       
        
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            {params.row.status === 'Rejected' &&
               <Button
               className="me-2"
                 style={{ backgroundColor: "#007bff", color: "white" }}
                 onClick={()=>handleOpenCase(params.row)}
               >
                 Open Case
               </Button>
            }

             {(params.row.status === 'Rejected' && userRole === 'superadmin')&&
                <Button
                style={{ backgroundColor: "tomato", color: "white" }}
                >
                Reject
                </Button>
             }
  
           
           
             
          
          </div>
        );
      }
    },
  
  
  ];
  

  function roundToNearest(num) {
    const epsilon = 0.0001; // Small value to ensure correct rounding
    const adjustedNum = num - epsilon;
    if(adjustedNum)
      return Math.ceil(adjustedNum);
    return "N/A"
  }

  const handleContactClicked = () => {
    setSearchingByContact(true);
    setSearchingByEmail(false);
  };

  const handleEmailClicked = () => {
    setSearchingByEmail(true);
    setSearchingByContact(false);
  };

  const getPosData = async () =>{
    const outlet_id = localStorage.getItem("userId");
    const response = await axiosPrivate.get(`/pos/outlet/${outlet_id}`)
    setPosData(response.data);
  }

  const getAllPos = async () =>{
    
    const response = await axiosPrivate.get(`/pos`)
    setPosCount(response.data.length);

    if(userRole !== 'outlet'){
      setPosData(response.data);
    }


  }

  

  useEffect(() => {
    let isMounted = true;

    const getAdmins = async () => {
      try {
        const response = await axiosPrivate.get(`/user/admins`, {
          // signal: controller.signal
        });
        setAdmins(response.data);
        
      } catch (err) {
        console.error(err);
      }
    };

    const getOutletData = async () =>{
      const outlet_id = localStorage.getItem("userId");
      const response = await axiosPrivate.get(`/outlets/${outlet_id}`)
      setOutletData(response.data);
    }

    

   
    if(userRole === 'outlet'){
      isMounted && getOutletData();
      isMounted && getPosData();
    }

   
    isMounted && getAllPos();
    isMounted && getAdmins();

    return()=>{
      isMounted = false;
    }

   
  
  }, [userRole])

  const getOutletDatabyId = async (outlet_id) =>{
    const response = await axiosPrivate.get(`/outlets/${outlet_id}`)
    setOutletData(response.data);
  }

  useEffect(()=>{
    const rows = posData.map((pos, index) => ({
        id:index+1,
        transaction_id: pos.transaction_id,
        customer_name: pos.customer_name,
        phone: pos.phone,
        outlet_id: pos.outlet_id,
        outlet_name: pos.outlet_name,
        gross_amount: pos.gross_amount,
        eligibility: pos.eligibility?'Eligible':'Not Eligible',
        total_amount: pos.total_amount,
        coupon: pos.coupon,
        final_amount: pos.final_amount,
        status: pos.status,
      }));

      setRow(rows);
  }, [posData]);

  const sendmessges = async (review) => {
    try { 
        
        const response = await axiosPrivate.post(
          `/inbox`,
          {
            "message_receivers": JSON.stringify(
              [
                ...admins
                  .filter(admin => admin.role !== "admin1")
                  .map(admin => ({
                    'id': admin.userId,
                    'email_id': admin.email,
                    'name': admin.first_name + ' ' + admin.last_name
                  })),
                {
                  'id': outletData?.outlet_id,
                  'email_id': outletData?.email_id ,
                  'name': outletData?.outlet_name
                }
              ]
            ),
              "subject":  "TRANSACTION RECORDED",
              "message": `New Transaction Recording Transaction ID : TCX${String(posCount + 1).padStart(
                paddingLength,
                "0"
              )} has been ${review} by ${outletData?.outlet_name}. \n\n Recorded Details:\n
                          Customer Name: ${customerData.customer_first_name + ' ' + customerData.customer_last_name} 
                          Contact : ${customerData.phone} 
                          Email : ${customerData.email} 
                          Transaction Category : ${transactionCategory?"HAPPY HOURS":'REGULAR HOURS'} 
                          Total Amount: ${roundToNearest(totalAmount)} 
                          Coupon : ${coupon} 
                          Promotion Offer : ${roundToNearest(promotion)} 
                          Final Amount: ${roundToNearest(finalAmount)} `,

              "category": "Pos"
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        

       
      
    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };



  const handleSearchbyContact = async () => {
    setSearchButtonClicked(true);
    try{
      const result = await axiosPrivate.get(`/customers/customerbyContact/${contact}`);
      if (result.status === 200) {
       
        setCustomerData(result.data);
    } else if (result.status === 400) {
  
        setCustomerData({});
    } else {
        
    }
      
    } catch (err) {
        console.error(err);
        setCustomerData({});
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const handleSearchbyTransactionId = async (transaction_id) => {
    setSearchButtonClicked(true);
    try{
      const result = await axiosPrivate.get(`/pos/byTransaction/${transaction_id}`);
      if (result.status === 200) {
        let pos = result.data
       
        setCustomerData(pos);

        setGrossAmount(pos.gross_amount);
        setEligibility(pos.eligibility);
        setTotalAmount(pos.total_amount);
        setCoupon(pos.coupon);
        setPromotion(pos.total_amount - pos.final_amount);
        setFinalAmount(pos.final_amount)

    } else if (result.status === 400) {
      
        setCustomerData({});
    } else {
       
        // Handle other types of errors here
    }
      
    } catch (err) {
        console.error(err);
        setCustomerData({});
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const handleSubmit = async (review) =>{
    await axiosPrivate.post("/pos", {
        transaction_id: `TCX${String(posCount + 1).padStart(
          paddingLength,
          "0"
        )}`,
        customer_id: customerData.customer_id,
        customer_name: customerData.customer_first_name + ' ' + customerData.customer_last_name,
        phone: customerData.phone,
        outlet_id: outletData.outlet_id,
        outlet_name: outletData.outlet_name,
        gross_amount: roundToNearest(grossAmount),
        eligibility: eligibility,
        total_amount: roundToNearest(totalAmount),
        coupon : coupon,
        final_amount : roundToNearest(finalAmount),
        status : review,
    });
    handleClose();
    setSearchButtonClicked(false);
    alert("POS Status Recorded");
    sendmessges(review);
    getPosData();
    getAllPos();
  };

  const handleUpdate = async (review) =>{
    await axiosPrivate.patch(`/pos/update/${customerData?.transaction_id}`, {
        gross_amount: roundToNearest(grossAmount),
        eligibility: eligibility,
        total_amount: roundToNearest(totalAmount),
        coupon : coupon,
        final_amount : roundToNearest(finalAmount),
        status : review,
    });
    handleClose();
    setSearchButtonClicked(false);
    alert("POS Status Updated");
    getPosData();
    getAllPos();
  };




  const handleSearchbyEmail = async () => {
    setSearchButtonClicked(true);
    try{
      const result = await axiosPrivate.get(`/customers/customerbyEmail/${email}`);
      if (result.status === 200) {
      
        setCustomerData(result.data);
    } else if (result.status === 400) {
       
        setCustomerData({});
    } else {
      
        // Handle other types of errors here
    }
      
    } catch (err) {
        console.error(err);
        setCustomerData({});
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const handleModalClosed = () =>{
    setSearchButtonClicked(false);
    setTransactionCategory(false);
    setGrossAmount(0);
    setEligibility(false);
    setTotalAmount(0);
    setCoupon("");
    setPromotion(0);
    setFinalAmount(0);
    handleClose();
   

  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: '90vh',
    overflowY: 'scroll',
    bgcolor: "rgba(0,0,0,0.5)",
    boxShadow: 24,

    color: "white",
    p: 4,
  };

  const handleAmount = (e) =>{
    let gross_amount = e.target.value;
    let promotion_value = 0;
    setGrossAmount(gross_amount);
    if(gross_amount > 1000){
      setEligibility(true);
    }else{
      setEligibility(false);
    }

    let total_amount = parseFloat(gross_amount) + parseFloat(gross_amount) * outletData.gst_percentage / 100;
    setTotalAmount(total_amount);

    if(transactionCategory && gross_amount > 1000){
      setCoupon("CUBE50")
      let promotion_value = total_amount * 0.5;
      setPromotion(promotion_value);
      setFinalAmount(total_amount - promotion_value);
    }
    
    else{
      if(gross_amount>=0 && gross_amount<1000){
        setCoupon("N/A")
        promotion_value = 0
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
      }else if(gross_amount>=1000 && gross_amount<2500){
        setCoupon("CUBE10")
        promotion_value = total_amount * 0.1
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
      }else if(gross_amount>=2500 && gross_amount<4000){
        setCoupon("CUBE15")
        promotion_value = total_amount * 0.15
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
      }else{
        setCoupon("CUBE20")
        promotion_value = total_amount * 0.2
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
      }
    }

    

    

    



  }

  // useEffect(() => {
  //   let final_amount = totalAmount - promotion;
  //   setFinalAmount(final_amount);
  // }, [promotion])

  const handleTransactionChange = () =>{
    setTransactionCategory(false);
    if(grossAmount){
      if(grossAmount > 1000){
        setEligibility(true);
      }else{
        setEligibility(false);
      }
  
      let total_amount = parseFloat(grossAmount) + parseFloat(grossAmount) * outletData.gst_percentage / 100;
      setTotalAmount(total_amount);
  
      if(grossAmount>0 && grossAmount<1000){
        setCoupon("Nill")
        setPromotion(0);
        setPromotion(totalAmount);
      }else if(grossAmount>=1000 && grossAmount<2500){
        setCoupon("CUBE10")
        let promotion_value = total_amount * 0.1;
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
      }else if(grossAmount>=2500 && grossAmount<4000){
        setCoupon("CUBE15")
        let promotion_value = total_amount * 0.15;
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);

       
      }else{
        setCoupon("CUBE20")
        let promotion_value = total_amount * 0.2;
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
      }
    }
   
  }

  const handleTransactionChange2 = () =>{
    setTransactionCategory(true);
    let total_amount = parseFloat(grossAmount) + parseFloat(grossAmount) * outletData.gst_percentage / 100;
    setTotalAmount(total_amount);

    if(grossAmount){
      if(grossAmount > 1000){
        setEligibility(true);
        setCoupon("CUBE50")
        let promotion_value = total_amount * 0.5;
        setPromotion(promotion_value);
        setFinalAmount(total_amount - promotion_value);
       
      }else{
        setEligibility(false);
      }

   
  
   
    }
  }

  return (
    <div className="parent">
      <div className="mainsection " style={{ position: "relative" }}>
        <div className="container-fluid text-center ">
          <div className="roas_section p-3 " id="pos">
            <div className="row">
              <div className="flex justify-content-between mb-3">
                <h2
                  className="text-center p-3 font-semibold"
                  style={{ fontSize: "20px" }}
                >
                  POINT OF SALES
                </h2>

                {userRole === 'outlet' &&
                  <div
                  className="rounded p-4   flex align-items-center"
                  style={{ height: "60px" }}
                >
                  <h1 className="me-3" style={{ fontSize: "20px" }}>
                    ADD NEW CASE
                  </h1>

                  <ControlPointIcon
                    className="cursor-pointer"
                    style={{ fontSize: "60px" }}
                    onClick={handleOpen}
                  />
                </div>
                }

               

              </div>

              <Box
                sx={{
                  height: "auto",
                  width: "100%",
                }}
              >
                <DataGrid
                  columnVisibilityModel={{
                    // Hide columns status and traderName, the other columns will remain visible
                    outlet_id: false,
                   
                  }}
                  rows={row}
                  columns={columns}
                  sx={{ backgroundColor: "white", fontSize: "17px" }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  disableRowSelectionOnClick
                />
              </Box>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="border-warning rounded"
          sx={style}
          style={{ position: "relative" }}
        >
          <h1 className="mx-2" style={{ fontSize: "17px" }}>
            ENTER CUSTOMER DETAILS
          </h1>

          <CloseIcon
            className="closeButton"
            onClick={handleModalClosed}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          />

          {searchingByTransactionId?
           <div className="mt-4 mx-2" style={{ width: "30%" }}>
           <label
             style={{ color: "white" }}
             for="exampleFormControlInput1"
             className="form-label mb-2"
           >
              TRANSACTION ID:
           </label>
           <div className="input-group mb-3">
             <input
               disabled
               type="text"
               className="form-control"
               name="transaction_id"
               placeholder="Transaction Id"
              //  onChange={(e)=>setContact(e.target.value)}
               aria-label="Recipient's username"
               aria-describedby="basic-addon2"
               value={customerData.transaction_id}
               style={{ backgroundColor: "#d1cdcd" }}
              
             />
             <span
              //  onClick={handleSearchbyContact}
               className="input-group-text cursor-pointer"
               id="basic-addon2"
             >
               <SearchIcon />
             </span>
           </div>
         </div>
        
          :
          <div className="flex  mt-3 mx-2 " style={{ height: "50px" }}>
          <Button
            className="rounded me-3"
            style={{ backgroundColor: "#007bff", color: "white" }}
            onClick={handleContactClicked}
          >
            Search By Contact
          </Button>
          <Button
            className="rounded"
            style={{ backgroundColor: "#007bff", color: "white" }}
            onClick={handleEmailClicked}
          >
            Search By Email
          </Button>
        </div>
          
          }

        

          {searchingByContact && (
            <div className="mt-4 mx-2" style={{ width: "30%" }}>
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                ENTER CONTACT NUMBER:
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder="Contact Number"
                  onChange={(e)=>setContact(e.target.value)}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={contact}
                />
                <span
                  onClick={handleSearchbyContact}
                  className="input-group-text cursor-pointer"
                  id="basic-addon2"
                >
                  <SearchIcon />
                </span>
              </div>
            </div>
          )}

          {searchingByEmail && (
            <div className="mt-4 mx-2" style={{ width: "30%" }}>
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                ENTER EMAIL ID:
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Email ID"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={(e)=>setEmail(e.target.value)}
                  value={email}
                />
                <span
                  onClick={handleSearchbyEmail}
                  className="input-group-text cursor-pointer"
                  id="basic-addon2"
                >
                  <SearchIcon />
                </span>
              </div>
            </div>
          )}

          {searchButtonClicked &&  
           <div>
           <div className="flex justify-content-between">
             <div style={{ width: "45%" }}>



             <div className="mt-3 mx-2">
                 <label
                   style={{ color: "white" }}
                   for="exampleFormControlInput1"
                   className="form-label mb-2"
                 >
                   VALIDITY:
                 </label>
                 <div className="input-group mb-3">

                 <input
                   style={{ backgroundColor: "#d1cdcd" }}
                   type="text"
                   disabled
                   className="form-control"
                   name="validity"
                   aria-label="Recipient's username"
                   aria-describedby="basic-addon2"
                   value={Object.keys(customerData).length > 0 ? 'Valid' : 'Invalid'}
                 />

                 </div>
               </div>

               {Object.keys(customerData).length > 0 &&
                <div>
                <div className="mt-3 mx-2">
                  <label
                    style={{ color: "white" }}
                    for="exampleFormControlInput1"
                    className="form-label mb-2"
                  >
                    CUSTOMER NAME:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      style={{ backgroundColor: "#d1cdcd" }}
                      type="text"
                      disabled
                      className="form-control"
                      name="customer_name"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={

                        searchingByTransactionId?
                          customerData.customer_name
                        :

                        (customerData.customer_first_name + ' ' + customerData.customer_last_name)
                        
                      
                      
                      }
                    />
                  </div>
                </div>
 
                <div className="mt-3 mx-2">
                  <label
                    style={{ color: "white" }}
                    for="exampleFormControlInput1"
                    className="form-label mb-2"
                  >
                    CONTACT:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      style={{ backgroundColor: "#d1cdcd" }}
                      type="text"
                      disabled
                      className="form-control"
                      name="contact"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={customerData.phone}
                    />
                  </div>
                </div>

                {!searchingByTransactionId &&

                <div>
                <div className="mt-3 mx-2">
                  <label
                    style={{ color: "white" }}
                    for="exampleFormControlInput1"
                    className="form-label mb-2"
                  >
                    EMAIL ID:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      style={{ backgroundColor: "#d1cdcd" }}
                      type="text"
                      disabled
                      className="form-control"
                      name="email"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={customerData.email}
                    />
                  </div>
                </div>
 
                <div className="mt-3 mx-2">
                  <label
                    style={{ color: "white" }}
                    for="exampleFormControlInput1"
                    className="form-label mb-2"
                  >
                    GENDER:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      style={{ backgroundColor: "#d1cdcd" }}
                      type="text"
                      disabled
                      className="form-control"
                      name="gender"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={customerData.gender}
                    />
                  </div>
                </div>
 
                <div className="mt-3 mx-2">
                  <label
                    style={{ color: "white" }}
                    for="exampleFormControlInput1"
                    className="form-label mb-2"
                  >
                    AGE GROUP:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      style={{ backgroundColor: "#d1cdcd" }}
                      type="text"
                      disabled
                      className="form-control"
                      name="age_group"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={customerData.age_group}
                    />
                  </div>
                </div>

                <div className="mt-3 mx-2">
                  <label
                    style={{ color: "white" }}
                    for="exampleFormControlInput1"
                    className="form-label mb-2"
                  >
                    MEMBERSHIP:
                  </label>
                  <div className="input-group mb-3">
                    <input
                      style={{ backgroundColor: "#d1cdcd" }}
                      type="text"
                      disabled
                      className="form-control"
                      name="membership_plan"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={customerData.membership_plan?customerData.membership_plan : 'Normal'}
                    />
                  </div>
                </div>
                </div>

                  }
 
                

                </div>
               }



              

             



             

              


             </div>

             {Object.keys(customerData).length > 0 &&

                    <div style={{ width: "45%" }}>

                      {!searchingByTransactionId ?
                      <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        htmlFor="exampleFormControlInput4"
                        className="form-label mb-2"
                      >
                        TRANSACTION CATEGORY
                      </label>
                      <div className="row px-3">
                        <div className="col   p-2" style={{ height: "50px" }}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="gridRadios1"
                              value="Regular Hours"
                              checked={transactionCategory === false}
                              onChange={handleTransactionChange}
                             
                            />
                            <label
                              className="form-check-label text-light"
                              for="gridRadios1"
                            >
                              REGULAR HOURS
                            </label>
                          </div>
                        </div>
                        <div className="col  p-2 " style={{ height: "50px" }}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="gridRadios2"
                              value="Happy Hours"
                              onChange={handleTransactionChange2}
                              checked={transactionCategory === true}
                            />
                            <label
                              className="form-check-label text-light"
                              for="gridRadios2"
                            >
                              HAPPY HOURS
                            </label>
                          </div>
                        </div>
                      </div>
                             </div>
                       :
                       <div className="mt-1 mx-2">
                       <label
                         style={{ color: "white" }}
                         for="exampleFormControlInput1"
                         className="form-label mb-2"
                       >
                         OUTLET NAME:
                       </label>
                       <div className="input-group mb-3">
                         <input
                          disabled
                           type="text"
                           className="form-control"
                           name="gross_amount"
                           placeholder="Enter Gross Amount"
                           aria-label="Recipient's username"
                           aria-describedby="basic-addon2"
                           value={customerData.outlet_name}
                           style={{ backgroundColor: "#d1cdcd" }}
                         />
                       </div>
                     </div>
                       }


                   



                    <div className="mt-1 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        GROSS AMOUNT:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="gross_amount"
                          placeholder="Enter Gross Amount"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e)=>handleAmount(e)}
                          value={grossAmount}
                        />
                      </div>
                    </div>

                   

                    <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        ELIGIBILITY:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          style={{ backgroundColor: "#d1cdcd" }}
                          type="text"
                          disabled
                          className="form-control"
                          name="eligibility"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={eligibility?'Eligible':'Not Eligible'}
                        />
                      </div>
                    </div>

                    <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        TOTAL AMOUNT:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          style={{ backgroundColor: "#d1cdcd" }}
                          type="text"
                          disabled
                          className="form-control"
                          name="total_amount"
                          placeholder="Total Amount"
                          value={roundToNearest(totalAmount)}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          
                        />
                      </div>
                    </div>

                    <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        COUPON:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          style={{ backgroundColor: "#d1cdcd" }}
                          type="text"
                          disabled
                          className="form-control"
                          name="coupon"
                          placeholder="Coupon Code"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={coupon}
                        />
                      </div>
                    </div>

                    <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        PROMOTION OFFER:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          style={{ backgroundColor: "#d1cdcd" }}
                          type="text"
                          disabled
                          className="form-control"
                          name="promotion"
                          placeholder="Offer Applied"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={roundToNearest(promotion)}
                        />
                      </div>
                    </div>

                    <div className="mt-3 mx-2">
                      <label
                        style={{ color: "white" }}
                        for="exampleFormControlInput1"
                        className="form-label mb-2"
                      >
                        FINAL AMOUNT:
                      </label>
                      <div className="input-group mb-3">
                        <input
                          style={{ backgroundColor: "#d1cdcd" }}
                          type="text"
                          disabled
                          className="form-control"
                          name="final_amount"
                          placeholder="Total Amount - Promotion"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={roundToNearest(finalAmount)}
                        />
                      </div>
                    </div>
                    </div>
              }

            


           </div>

           { (!searchingByTransactionId && Object.keys(customerData).length > 0) &&
              <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-1"
              >
                REVIEW:
              </label>

              <div className=" flex  mt-1" style={{ height: "50px" }}>
                <Button
                  className="rounded me-3 "
                  style={{ backgroundColor: "greenyellow", fontWeight: "600" }}
                  onClick={()=>searchingByTransactionId?handleUpdate("Approved"):handleSubmit("Approved")}
                >
                  Approve
                </Button>
                <Button
                  className="rounded"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={()=>searchingByTransactionId?handleUpdate("Rejected"):handleSubmit("Rejected")}
                >
                  Reject
                </Button>
              </div>
              </div>
            }

        { (searchingByTransactionId && userRole ==='superadmin' && Object.keys(customerData).length > 0) &&
              <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-1"
              >
                REVIEW:
              </label>

              <div className=" flex  mt-1" style={{ height: "50px" }}>
                <Button
                  className="rounded me-3 "
                  style={{ backgroundColor: "greenyellow", fontWeight: "600" }}
                  onClick={()=>searchingByTransactionId?handleUpdate("Approved"):handleSubmit("Approved")}
                >
                  Approve
                </Button>
                <Button
                  className="rounded"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={()=>searchingByTransactionId?handleUpdate("Rejected"):handleSubmit("Rejected")}
                >
                  Reject
                </Button>
              </div>
              </div>
            }

            {
              Object.keys(customerData).length === 0 &&

              <div className="mx-2 mt-3">
                <h1 style={{fontSize: '17px'}}>USER NOT FOUND</h1>
                <p className="mt-2 " >It Appears To Be This USER Is Not Available In The System due to USER Account Pending. Kindly Activate Account.</p>

                    <Button
                  className="rounded mt-3"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  onClick={()=>navigate("/nightcube/survey-form")}
                >
                 Add Customer
                </Button>
              </div>

            }



          


         </div>
          }

         


        </Box>


      </Modal>
    </div>
  );
};

export default NightcubePOS;
