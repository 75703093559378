import React, { useState } from "react";
import axios, { axiosPrivate } from "../api/axios";
import "./Outlets.css";

import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";


import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function AddEvent() {
  const navigate = useNavigate();
  let outletId = localStorage.getItem("userId");
  let outletName = localStorage.getItem("userName");

  const { state } = useLocation();

  const eventId = state?.eventId;
  const userRole = state?.userRole;
  const email = state?.email;

  const [personName, setPersonName] = React.useState([]);

  const [artistData, setArtistData] = React.useState([]);

  const [eventData, setEventData] = React.useState({});

  const [outletData, setOutletData] = React.useState({});

  const [docCount, setDocCount] = React.useState({});

  const [admins, setAdmins] = useState([]);



  const [form, setForm] = useState({
    event_id: "",
    event_name: "",
    outlet_id: "", //based on single outlet selected
    outlet_name: "", //based on single outlet selected
    artist_lineup: [], //add all the artist selected max 3 id and name
    event_start_time: null, // timestamp
    event_end_time: null, // timestamp
    music_category: "BOLLYWOOD", // dropdown
    event_category: "LADIES NIGHT", // dropdown
    event_location_map: "", // outlet location map
    event_address: "", //outlet address
    description: "",
    // customer_category : "",  //radio buttons single lady /couple /group
    event_price: { type: Number }, // price option
    event_disclaimer:
      "1. Rights To Admission Reserved\n2. Cover Charges Applicable at Gate Post 8 PM\n3. 21+ /Physical ID Mandatory\n4. Club Rules Applied\n5. Cover Charges Redeemable Inside",
    ticket_link: "",
    poster_url: null, // Add poster_url field
    intro_video: null,
  });

  useEffect(() => {
    let isMounted = true;

    const getEventData = async () => {
      const response = await axiosPrivate.get(`/events/${eventId}`);
      setEventData(response.data);
      let event_data = response.data;



      const event_start_time = new Date(event_data.event_start_time);
      const event_end_time = new Date(event_data.event_end_time);
      const artist_list = event_data.artist_lineup;

      setPersonName(
        artist_list.map((item) => ({
          id: item.id,
          artist_stage_name: item.name,
        }))
      );

      artist_list.map((artist) => (
        setPersonName([...personName, { id: artist.id, artist_stage_name: artist.name }])
      ))

      const starting_year = event_start_time.getFullYear();
      const starting_month = String(event_start_time.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const starting_day = String(event_start_time.getDate()).padStart(2, "0");
      const starting_hours = String(event_start_time.getHours()).padStart(2, "0");
      const starting_minutes = String(event_start_time.getMinutes()).padStart(2, "0");
      const starting_seconds = String(event_start_time.getSeconds()).padStart(2, "0");

      const ending_year = event_end_time.getFullYear();
      const ending_month = String(event_end_time.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const ending_day = String(event_end_time.getDate()).padStart(2, "0");
      const ending_hours = String(event_end_time.getHours()).padStart(2, "0");
      const ending_minutes = String(event_end_time.getMinutes()).padStart(2, "0");
      const ending_seconds = String(event_end_time.getSeconds()).padStart(2, "0");

      // 5.5 hours in milliseconds

      // Adjust start time to IST


      // Adjust end time to IST
     console.log("year->",starting_year)
     console.log("starting hour->",starting_hours)
      console.log("starting minute->", starting_minutes)
      console.log("end year->", ending_year)
      console.log("end starting hour->", ending_hours)
      console.log("end starting minute->", ending_minutes)

      setForm({
        event_id: event_data.event_id,
        event_name: event_data.event_name,
        outlet_id: event_data.outlet_id, //based on single outlet selected
        outlet_name: event_data.outlet_name, //based on single outlet selected
        artist_lineup: event_data.artist_lineup, //add all the artist selected max 3 id and name

        event_start_time: `${starting_year}-${starting_month}-${starting_day} ${starting_hours}:${starting_minutes}:${starting_seconds}`, // timestamp
        event_end_time: `${ending_year}-${ending_month}-${ending_day} ${ending_hours}:${ending_minutes}:${ending_seconds}`, // timestamp

        // value={new Date(form?.event_end_time).toISOString().slice(0, 16)}

        music_category: event_data.music_category, // dropdown
        event_category: event_data.event_category, // dropdown
        event_location_map: event_data.event_location_map, // outlet location map
        event_address: event_data.event_address, //outlet address
        description: event_data.description,
        // customer_category : event_data,  //radio buttons single lady /couple /group
        event_price: event_data.event_price, // price option
        event_disclaimer: event_data.event_disclaimer,
        ticket_link: event_data.ticket_link,
        poster_url: event_data.poster_url, // Add poster_url field
        intro_video: event_data.intro_video,
      });
    };

    isMounted && eventId && getEventData();

    return () => {
      isMounted = false;
    };
  }, [eventId]);



  useEffect(() => {
    let isMounted = true;

    const getAdmins = async () => {
      try {
        const response = await axios.get(`/user/admins`, {
          // signal: controller.signal
        });
        setAdmins(response.data);

      } catch (err) {
        console.error(err);
      }
    };

    const getDocCount = async () => {
      try {
        const response = await axios.get(`/documents`, {
          // signal: controller.signal
        });
        setDocCount(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    const getArtistData = async () => {
      try {
        const response = await axiosPrivate.get(`/artists`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setArtistData(response.data);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getArtistData();
    isMounted && getDocCount();
    isMounted && getAdmins();

    return () => {
      isMounted = false;
      // controller.abort();
    };
  }, []);

  useEffect(() => {
    // Use the setForm function to update the state
    setForm((prevForm) => ({
      ...prevForm,
      outlet_id: outletId,
      outlet_name: outletName,
    }));
  }, [outletId, outletName]);

  useEffect(() => {
    // Use the setForm function to update the state
    setForm((prevForm) => ({
      ...prevForm,
      outlet_id: outletId,
      outlet_name: outletName,
      event_address: outletData.address,
      event_location_map: outletData.map,
    }));
  }, [outletId, outletName, outletData]);

  const handleChange2 = (event, newValue) => {


    if (newValue.length > 1) {
      alert("You can select only one artist.");
      return;
    }



    if (Array.isArray(newValue)) {
      // Update the Autocomplete value
      setPersonName(newValue);

      // ... (rest of your code)
    }

    const updatedArtistLineup = newValue.map((artist) => ({
      id: artist.artist_id,
      name: artist.artist_stage_name,
    }));


    setForm({
      ...form,
      artist_lineup: updatedArtistLineup,
    });
  };

  const handleRemoveArtist = (artistToRemove) => {
    const updatedArtists = personName.filter(
      (artist) => artist.id !== artistToRemove.id
    );
    setPersonName(updatedArtists);

    const updatedArtistLineup = updatedArtists.map((artist) => ({
      id: artist.id,
      name: artist.name,
    }));
    setForm({
      ...form,
      artist_lineup: updatedArtistLineup,
    });
  };

  useEffect(() => {

  }, [form])

  const handleChange = (e) => {
    ;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedVideoTypes = ["video/mp4", "video/webm"];

    if (e.target.type === "file") {
      if (
        allowedTypes.includes(e.target.files[0].type) ||
        allowedVideoTypes.includes(e.target.files[0].type)
      ) {
        setForm({
          ...form,
          [e.target.name]: e.target.files[0],
        });
      } else {
        alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getOutletData = async () => {
      const response = await axiosPrivate.get(`/outlets/${outletId}`, {});
      setOutletData(response.data);
    };



    if (userRole === 'outlet') {
      isMounted && getOutletData();
    }


    return () => {
      isMounted = false;
    };

  }, [outletId, userRole]);

  const resetForm = () => {
    // Define your initial form state or an empty object
    const initialFormState = {
      outlet_icon: null,
      intro_video: null,
      opening_time: "01:00 PM",
      closing_time: "01:00 AM",
      outlet_category: "NIGHTCLUB",
      website: "",
      address: "",
      map: "",
      facebook_url: "",
      instragram_url: "",
      description: "",
      google_url: "",
    };

    // Update the state to reset the form
    setForm(initialFormState);
  };

  useEffect(() => {
    if (!eventId) {
      form.event_id = `NE${String(docCount.totalEvents + 1).padStart(
        paddingLength,
        "0"
      )}`;
    }
  }, [docCount, form, eventId]);

  function formatTime(dateObject) {
    // Get hours, minutes, and seconds from the Date object
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Return formatted time string
    return `${String(formattedHours).padStart(2, "0")}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  }


  const sendmessges = async () => {
    try {

      const response = await axiosPrivate.post(
        `/inbox`,
        {
          "message_receivers": JSON.stringify(
            [
              ...admins
                .filter(admin => admin.role !== "admin2")
                .map(admin => ({
                  'id': admin.userId,
                  'email_id': admin.email,
                  'name': admin.first_name + ' ' + admin.last_name
                })),
              {
                'id': outletId,
                'email_id': email,
                'name': outletName
              }
            ]
          ),
          "subject": "EVENT LISTED",
          "message": `New Event has been successfully listed by ${outletName}.\n\n Recorded Details:\n
                          Event Name: ${form.event_name}
                          Date: ${String(new Date(form.event_start_time).getDate()).padStart(2, '0') + '/' + (String(new Date(form.event_start_time).getMonth() + 1)).padStart(2, "0") + '/' + String(new Date(form.event_start_time).getFullYear()).padStart(2, "0")} 
                          Start Time:  ${formatTime(new Date(form.event_start_time))} 
                          End Time:   ${formatTime(new Date(form.event_end_time))}  
                          Cover Charge : ${form.event_price} `,
          "category": "Event"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );





    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };


  const sendCancelMessge = async () => {
    try {

      const response = await axiosPrivate.post(
        `/inbox`,
        {
          "message_receivers": JSON.stringify(
            [
              ...admins
                .filter(admin => admin.role !== "admin2")
                .map(admin => ({
                  'id': admin.userId,
                  'email_id': admin.email,
                  'name': admin.first_name + ' ' + admin.last_name
                })),
              {
                'id': outletId,
                'email_id': email,
                'name': outletName
              }
            ]
          ),
          "subject": "EVENT CANCELLED",
          "message": `Event ${eventData.event_name} has been cancelled by ${outletName}.\n\n Recorded Details:\n
                          Event Name: ${eventData.event_name}
                          Date: ${String(new Date(eventData.event_start_time).getDate()).padStart(2, '0') + '/' + (String(new Date(eventData.event_start_time).getMonth() + 1)).padStart(2, "0") + '/' + String(new Date(eventData.event_start_time).getFullYear()).padStart(2, "0")} 
                          Start Time:  ${formatTime(new Date(eventData.event_start_time))} 
                          End Time:   ${formatTime(new Date(eventData.event_end_time))}  
                          Cover Charge : ${eventData.event_price} `,
          "category": "Event"
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );





    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };



  const paddingLength = 10;

  const updateEvent = async () => {
    const formData2 = new FormData();

    if (form) {
      for (const key in form) {
        if (key === "artist_lineup") {
          // If the property is 'artist_lineup', stringify and append it
          formData2.append(key, JSON.stringify(form[key]));
        } else {
          // For other properties, directly append to FormData
          formData2.append(key, form[key]);
        }
      }
    }

    if (eventId && formData2) {
      const response = await axiosPrivate.put(`/events/${eventId}`, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });


      if (response.status === 200) {
        alert("Information Updated Succesfully");
        // Assuming you have the `navigate` function from your router
        navigate(`/singleEvent/${form.event_id}/${form.event_name}`, { replace: "true", state: null });
      }
    } else {
      alert("Failed to Add Event");
    }
  };

  const addEvent = async () => {
    try {
      if (
        !form.event_name ||
        !form.artist_lineup ||
        form.artist_lineup.length === 0 ||
        !form.event_start_time || // timestamp
        !form.event_end_time || // timestamp
        !form.description ||
        // customer_category : "",  //radio buttons single lady /couple /group
        !form.event_price || // price option
        !form.event_disclaimer ||
        !form.ticket_link ||
        !form.poster_url || // Add poster_url field
        !form.intro_video
      ) {
        alert("Please Select All Fields");
      } else {
        const formData = new FormData();

        for (const key in form) {
          if (key === "artist_lineup") {
            // If the property is 'artist_lineup', stringify and append it
            formData.append(key, JSON.stringify(form[key]));
          } else {
            // For other properties, directly append to FormData
            formData.append(key, form[key]);
          }
        }

        console.log("music ", form.music_category)

        const response = await axiosPrivate.post(
          `/events/add_event/${form.event_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );





        if (response.status === 200) {
          alert("Events Listed successfully");

          const response2 = await axiosPrivate.patch(
            `/documents/totalEvents`
          );

          if (response2.status === 200) {
            sendmessges();
            navigate(`/singleEvent/${form.event_id}/${form.event_name}`, {
              replace: "true",
              state: null,
            });
          }


        } else {
          alert("Failed to Add Event");
        }
      }
    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while updating insights");
    }
  };

  const handleCancelEvent = async () => {
    let userConfirmed = window.confirm("Are You Sure To Cancel This Event?\n\nIf You Click OK, This Event Will Be Removed From The System ");

    if (userConfirmed) {
      const result = await axiosPrivate.delete(`/events/${form.event_id}`);
      alert("Event Removed Succesfully");
      sendCancelMessge();
      navigate(`/`);

    }

  }




  return (
    <div className="layout mb-5">
      <div
        className="mx-auto mt-40 mb-5 "
        style={{ width: "500px", height: 'auto' }}
      >
        <h1
          className="text-center"
          style={{
            fontSize: "28px",
            fontWeight: "600",
            color: "white",
            letterSpacing: "1px",
          }}
        >
          {eventId ? <span> UPDATE EVENT </span> : <span>LIST NEW EVENT</span>}
        </h1>

        <div className="mt-20 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            EVENT NAME
          </label>
          <input
            type="text"
            name="event_name"
            placeholder="Event Name"
            onChange={handleChange}
            className="form-control"
            value={form.event_name}
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            EVENT POSTER
          </label>
          <input
            type="file"
            name="poster_url"
            accept=".png, .jpg, .jpeg"
            onChange={handleChange}
            className="form-control"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            EVENT INTRO VIDEO
          </label>
          <input
            type="file"
            name="intro_video"
            accept="video/*"
            onChange={handleChange}
            className="form-control"
            id="inputGroupFile04"
            aria-describedby="inputGroupFileAddon04"
            aria-label="Upload"
          />
        </div>

        <div className="mt-3 mx-2 ">
          <label
            style={{ color: "white" }}
            for="artist-section"
            className="form-label mb-2"
          >
            SELECT ARTISTS
          </label>
          <Autocomplete
            className="rounded"
            multiple
            id="artist-section"
            style={{ backgroundColor: "white" }}
            options={artistData}
            value={personName}
            onChange={handleChange2}
            isOptionEqualToValue={(option, value) =>
              option.artist_id === value.artist_id
            }
            getOptionLabel={(option) => option.artist_stage_name} // Specify how to display the option label
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Search..."
                style={{ color: "white" }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option.artist_stage_name}
                  {...getTagProps({ index })}
                  onDelete={() => handleRemoveArtist(option)}
                  deleteIcon={
                    <IconButton size="small" color="error">
                      <CloseIcon />
                    </IconButton>
                  }
                />
              ))
            }
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="time1"
            className="form-label mb-2"
          >
            EVENT START TIME
          </label>

          <input
            type="datetime-local"
            id="time1"
            name="event_start_time"
            onChange={handleChange}
            className="form-control"
            value={form.event_start_time}
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="time2"
            className="form-label mb-2"
          >
            EVENT END TIME
          </label>

          <input
            type="datetime-local"
            id="time2"
            name="event_end_time"
            onChange={handleChange}
            className="form-control"
            value={form.event_end_time}
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            EVENT CATEGORY
          </label>
          <select
            name="event_category"
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
            value={form.event_category}
          >
            {/* <option selected>Open this select menu</option> */}
            <option value="LADIES NIGHT">LADIES NIGHT</option>
            <option value="BRUNCH">BRUNCH</option>
            <option value="FRIDAY NIGHT LIVE">FRIDAY NIGHT LIVE</option>
            <option value="SATURDAY NIGHT LIVE">SATURDAY NIGHT LIVE</option>
            <option value="SUNDAY NIGHT LIVE">SUNDAY NIGHT LIVE</option>
            <option value="COSTUME & THEMES">COSTUME & THEMES</option>
            <option value="CONCEPTS">CONCEPTS</option>
            <option value="HAPPY HOURS">HAPPY HOURS</option>
          </select>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            MUSIC CATEGORY
          </label>
          <select
            name="music_category"
            onChange={handleChange}
            className="form-select"
            aria-label="Default select example"
            value={form.music_category}
          >
            {/* In value dont use characters: _ , | , or any special characters: Only Allowed: - and /  */}
            {/* <option selected>Open this select menu</option> */}
            <option value="BOLLYWOOD">BOLLYWOOD (हिंदी)</option>
            <option value="EDM">EDM</option>
            <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
            <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
            <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
            <option value="DEEP/HOUSE">DEEP/HOUSE</option>
            <option value="CHILL/HOUSE">CHILL/HOUSE</option>
            <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
            <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
            <option value="KANNADA">KANNADA (ಕನ್ನಡ)</option>
          </select>
        </div>

        {/* <div className="mt-3 mx-2">
          <label style={{color: 'white'}} className=" mb-2" for="exampleFormControlInput1" className="form-label mb-2">
            CUSTOMER CATEGORY
          </label>
          <div className="flex justify-evenly">
          <input
  type="radio"
  id="single-lady"
  name="customer_category"
  value="single-lady"
  checked={form.customer_category === 'single-lady'}
  onChange={handleChange}
  style={{ transform: 'scale(1.3)' }} 
/>
<label className="text-light" htmlFor="single-lady">Single Lady</label>

<input
  type="radio"
  id="couple"
  name="customer_category"
  value="couple"
  checked={form.customer_category === 'couple'}
  onChange={handleChange}
  style={{ transform: 'scale(1.3)' }} 
/>
<label  className="text-light" htmlFor="couple">Couple</label>

<input
  type="radio"
  id="group"
  name="customer_category"
  value="group"
  checked={form.customer_category === 'group'}
  onChange={handleChange}
  style={{ transform: 'scale(1.3)' }} 
/>
<label  className="text-light" htmlFor="group">Group</label>
      </div>
        </div>   */}

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            COVER CHARGE
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text">
              <CurrencyRupeeIcon />
            </span>
            <input
              type="number"
              name="event_price"
              placeholder="Event Price"
              onChange={handleChange}
              className="form-control"
              value={form.event_price}
            />
          </div>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            TICKET LINK
          </label>
          <input
            type="text"
            name="ticket_link"
            placeholder="Ticket Link"
            onChange={handleChange}
            className="form-control"
            value={form.ticket_link}
          />
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            DISCLAIMER
          </label>
          <textarea
            maxLength={250}
            className="form-control"
            name="event_disclaimer"
            placeholder="Event Disclaimer"
            onChange={handleChange}
            value={form.event_disclaimer}
            style={{ height: "100px" }}
          ></textarea>
        </div>

        <div className="mt-3 mx-2">
          <label
            style={{ color: "white" }}
            for="exampleFormControlInput1"
            className="form-label mb-2"
          >
            DESCRIPTION
          </label>
          <textarea
            maxLength={1700}
            className="form-control"
            name="description"
            onChange={handleChange}
            placeholder="Description About your Event"
            id="floatingTextarea2"
            style={{ height: "100px" }}
            value={form.description}
          ></textarea>
        </div>

        <div
          onClick={eventId ? updateEvent : addEvent}
          className="mx-2 mt-4 flex align-items-center  justify-content-center btnstyles"
        >
          {eventId ? (
            <h1 className="text-white" style={{ fontSize: "20px" }}>
              {" "}
              UPDATE{" "}
            </h1>
          ) : (
            <h1 className="text-white" style={{ fontSize: "20px" }}>
              {" "}
              SUBMIT{" "}
            </h1>
          )}
        </div>
        {eventId &&
          <div
            onClick={handleCancelEvent}
            className="mx-2 mt-4 flex align-items-center   justify-content-center btnstyles"
            style={{ backgroundColor: 'red' }}
          >
            <h1 className="text-white" style={{ fontSize: "20px" }}>
              {" "}
              CANCEL EVENT{" "}
            </h1>
          </div>
        }

      </div>
    </div>
  );
}

export default AddEvent;
