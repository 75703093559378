import React from "react";
import "./Outlets.css";

import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useNavigate, useParams } from "react-router-dom";

import axios from "../api/axios";
import { ElevatorSharp } from "@mui/icons-material";

const REGISTER_URL = "/user/register";

function OutletRegister() {
  const { role } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);

  const navigate = useNavigate();

  const [form, setForm] = useState({
    outlet_name: "",
    last_name: "",
    userId: "",
    phone: "",
    email: "",
    password: "",
    dob: "",
    gender: "",
    role: "",
    address: "",
  });

  useEffect(() => {
    form.dob = selectedDate;
  }, [selectedDate]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const [docCount, setDocCount] = useState({});

  useEffect(() => {
    let isMounted = true;
    const getDocCount = async () => {
      try {
        const response = await axios.get(`/documents`, {
          // signal: controller.signal
        });
        isMounted && setDocCount(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getDocCount();

    return () => {
      isMounted = false;
      // controller.abort();
    };
  });

  const paddingLength = 10;

  const handleSubmit = async () => {
    try {
      if (!form.outlet_name || !form.email || !form.phone || !form.password) {
        alert("please select all fields");
        ;
      } else {
        if (role === "outlet") {
          form.userId = `NO${String(docCount.totalOutlets + 1).padStart(
            paddingLength,
            "0"
          )}`;
        } else if (role === "artist") {
          form.userId = `NA${String(docCount.totalArtists + 1).padStart(
            paddingLength,
            "0"
          )}`;
        } else {
          form.userId = `NC${String(docCount.totalCustomers + 1).padStart(
            paddingLength,
            "0"
          )}`;
        }

        form.role = role;

      

        const response = await axios.post(REGISTER_URL, JSON.stringify(form), {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        });

        
        alert("Registered Succesfully");
        navigate("/login/user");
      }
    } catch (err) {
      if (!err?.response) {
        alert("No Seerver Rewsponse");
      }
    }
  };

  return (
    <div className="layout">
      <div className="m-auto" style={{ height: "500px", width: "500px" }}>
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "400",
            color: "white",
            letterSpacing: "1px",
          }}
        >
          REGISTER
        </h1>
        <div className="row g-3 mt-2 flex flex-wrap">
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput1"
              className="form-label mb-2"
            >
              OUTLET NAME
            </label>
            <input
              type="text"
              name="outlet_name"
              onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Enter Outlet Name"
              required
            />
          </div>
        </div>

        <div className="row g-3 mt-4">
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput3"
              className="form-label mb-2"
            >
              CONTACT
            </label>
            <input
              type="text"
              name="phone"
              onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput3"
              placeholder="Enter your mobile number"
              required
            />
          </div>
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput4"
              className="form-label mb-2"
            >
              EMAIL ADDRESS
            </label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput4"
              placeholder="Enter email"
              required
            />
          </div>
        </div>

        <div className="row g-3 mt-2 flex flex-wrap">
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput5"
              className="form-label mb-2"
            >
              PASSWORD
            </label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput5"
              placeholder="Password"
              required
            />
          </div>
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput7"
              className="form-label mb-2"
            >
              CONFIRM PASSWORD
            </label>
            <input
              type="password"
              name="password2"
              // onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="Confirm Password"
              required
            />
          </div>
        </div>

        <div className="row g-3 mt-2 flex flex-wrap">
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput1"
              className="form-label mb-2"
            >
              OUTLET CATEGORY
            </label>
            <select className="form-select" aria-label="Default select example">
              {/* <option selected>Open this select menu</option> */}
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>

        <div className="row g-3 mt-2 flex flex-wrap">
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput5"
              className="form-label mb-2"
            >
              OPENING TIME
            </label>
            <input
              type="text"
              name="opening_time"
              onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput5"
              placeholder="Opening Time"
              required
            />
          </div>
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput7"
              className="form-label mb-2"
            >
              CLOSING TIME
            </label>
            <input
              type="text"
              name="password2"
              // onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="CLosing Time"
              required
            />
          </div>
        </div>

        <div className="row g-3 mt-2 flex flex-wrap">
          <div className="col">
            <label
              style={{ color: "white" }}
              htmlFor="exampleFormControlInput1"
              className="form-label mb-2"
            >
              OUTLET NAME
            </label>
            <input
              type="text"
              name="outlet_name"
              onChange={handleChange}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Enter Outlet Name"
              required
            />
          </div>
        </div>

        <div
          onClick={handleSubmit}
          className="my-4 mt-5 flex align-items-center justify-content-center btnstyles"
        >
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            REGISTER
          </h1>
        </div>
      </div>
    </div>
  );
}

export default OutletRegister;
