import React, { useState } from 'react';
import public_info from "../assets/public_info.jpg";
function CompanyProfile() {

  return (
    
      <div className='publicpaper'>
        <div className='m-auto rounded p-3 ' style={{ position:'relative' , height: '80%', overflow: 'scroll', width: '90%', backgroundColor: 'whitesmoke', backgroundImage: `url(${public_info})`, backgroundSize: 'cover' }}>
        
          <h1 className='text-justify ms-3' style={{ lineHeight: '25px', fontWeight: '500',margin:'2rem' }}>
            NEROLIFE NETWORKS PVT. LTD., OPERATING UNDER NEROLIFE GROUP OF COMPANIES, HAS BEEN LICENCED AS A NIGHTLIFE BASED MEDIA & ENTERTAINMENT AGGREGATOR WITH BOTH TECHNOLOGY VERTICALS AND DOMAINS.
            <br /><br />
            OUR COMPANY PROFILE IS BASED ON DATA DRIVEN PROGRAM TOWARDS TRADITIONAL ENTERTAINMENT INDUSTRY CONSISTING OF USER COMMUNITY, MUSIC ARTIST COMMUNITY, EVENTS, EVENT HOSTING OUTLETS AS IN NIGHTCLUBS, BARS, PUBS, RESTO CAFÉ’S ETC. WHERE IN APART FROM TRADITIONAL FOOD & BEVERAGE SERVICES THESE OUTLETS ARE ALSO INVOLVED IN ORGANIZING AND HOSTING PUBLIC ENTERTAINMENT EVENTS ON REGULAR BASIS INVOLVING ELECTRONIC MUSICIANS & ARTISTS, VOCALISTS & SINGERS, DJ’S AND MUSIC PRODUCERS PERFORMING LIVE/RECOREDED MUSIC FOR ENTERTAINMENT PURPOSES & MEDIA PRACTICES SERVING PUBLIC INTERESTS.
            <br /><br />
            BASED ON BEST INTEREST OF USER COMMUNITY, ARTIST COMMUNITY, EVENTS, EVENTS HOSTING OUTLETS, TECHNOLOGY AND OTHERWISE DIVERSIFIED PORTFOLIO, NEROLIFE NETWORKS IS HEAD QUARTERED IN “BENGALURU, THE SILICON VALLEY OF INDIA”
            <br /><br />
            IN THE NIGHTLIFE DOMAIN OF ENTERTAINMENT INDUSTRY VERTICAL, THERE IS A WIDER SENSE OF NECESSITY FOR VARIETY OF PERSPECTIVE UNDERSTANDING & UNIQUE CHOICES OF EVERY INDIVIDUAL FROM USER COMMUNITY, TO MUSIC ARTISTS,  TO EVENT CURATORS, TO EVENT HOSTING OUTLETS TOWARDS WHICH WE ARE PURPOSEFULLY AGGREGATING AND DESIGNING SOLUTIONS ON BALANCE MAINTAINING & RETAINING A LONG LASTING RELATIONSHIP BETWEEN EACH AND EVERY ENTITIES INVOLVED AND WE HENCE SERVE OUR PURPOSE BY PROVIDING SERVICES TOWARDS THE INDUSTRY BY PROJECTING EVERY INDIVIDUAL’S VIEWS & REQUIREMENTS INFORMATION CHANNELED TO OPERATING BUSINESSES BY INTEGRATING SCIENCE WITH ADVANCE TECHNOLOGIES CATERING TOWARDS THE FUTURE AND BETTERMENT OF THE INDUSTRY AND ALL THOSE INVOLVED.

          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            PURPOSE:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            PROVIDING SOLUTIONS TO THE NICHE AND BEING THE UNANIMOUS BRIDGE BETWEEN EVENT CONSUMERS AND INVOLVED ENTITIES IN THE AREA OF NIGHTLIFE SOCIAL AND CLUB CULTURE CATEGORY OF ENTERTAINMENT INDUSTRY FOR ENDLESS SATISFACTION. A GLOBAL NIGHTLIFE SOLUTION.
          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            VISION:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            PROVIDING SOLUTIONS & SERVICES BY UNDERSTANDING EVERY INDIVIDUAL’S NEEDS TO HELP ENTITIES OF ENTERTAINMENT INDUSTRY.
          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            MISSION:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            TO PROVIDE A MEANINGFUL RELATIONSHIP WITH CUSTOMER FOCUSED SOLUTIONS & SERVICES BASED ON INDUSTRY NEEDS & REQUIREMENTS THROUGH CONSISTANTLY EVOLVING & VALUES ADDED TECHNOLOGIES AS PER DYNAMIC MARKET.
          </h1>
          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            CORE VALUES:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            WE UNDERSTAND EVERY INDIVIDUAL IS UNIQUE BY NATURE HAVE DIFFERENT TASTES IN MUSIC AS WELL AS DIFFERENT MEANS OF RECIPROCATING SOUND EXPERIENCE ATMOSPHERE HENCE WE AT NEROLIFE NETWORKS ACTIVELY & INTENTIONALLY PUSHING BOUNDARIES AND LIMITS BY WORKING ON SOLUTIONS & SERVICES TO PROVIDE EVERY INDIVIDUAL WITH MUSIC & EVENT EXPERIENCE OF THEIR OWN CHOICES THROUGH OUR TECHNOLOGIES DEVELOPED WITH ADVANCE INNOVATIONS, SMART ALGORITHMS & PREFERENCE INTERCEPTIONS.

          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            GOALS & OBJECTIVES:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            WORLDWIDE AND CITY WISE PERMUTATION AND COMBINATION OF WELL EQUIPPED & HIGHLY MAINTAINED UBER MODERN & FAMED OUTLETS TO PROJECT INFOTAINMENT ON MULTI-POINT OPTIONS & DIFFERENTIAL SERVICES FOR A STRONG BASE OF USER COMMUNITY.
            <br /><br />
            CROSS-CITY APPLICATION FOR INFOTAINMENT REQUIREMENTS OF TRAVELLING INDIVIDUALS.
            <br /><br />
            OBTAIN & RETAIN VALUABLE MARKET REPUTATION IN THE NIGHTLIFE EXPERIENCE OF ENTERTAINMENT INDUSTRY BY INTEGRATING ADVANCE SOLUTIONS FOR OPERATING ENTITIES WITH CUSTOMER CENTRIC SERVICES BY BECOMING THE KEY TECHNOLOGY AGGREGATOR OF THE INDUSTRY.


          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            SCOPE OF WORK:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            NEROLIFE NETWORKS PVT. LTD., OPERATING UNDER NEROLIFE GROUP OF COMPANIES, PROVIDES TECHNOLOGY BASED SOLUTIONS & AGGREGATION TOWARDS EVENT ORGANISING ENTITIES AND SERVICES OPERATING UNDER NIGHTLIFE DOMAIN OF ENTERTAINMENT INDUSTRY VERTICAL TOWARDS UNIQUE INDIVIDUAL MUSIC CHOICES & EVENT PREFERENCES.
          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            INDUSTRY INFORMATION:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            THE FOLLOWING ATTACHMENT PROVIDES RELATIVE INFORMATION TOWARDS THE INDUSTRY - <a style={{ color: 'blue' }} href='HTTPS://WWW.MARKETRESEARCHREPORTS.COM/BARS-CLUBS'>HTTPS://WWW.MARKETRESEARCHREPORTS.COM/BARS-CLUBS</a>
          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            BACKGROUND & DEVELOPMENT:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            THE FOLLOWING ATTACHMENT PROVIDES RELATIVE INFORMATION TOWARDS THE BACKGROUND & DEVELOPMENT - <a style={{ color: 'blue' }} href='HTTPS://EN.WIKIPEDIA.ORG/WIKI/NIGHTCLUB'>HTTPS://EN.WIKIPEDIA.ORG/WIKI/NIGHTCLUB</a>


          </h1>

          <h1 className='my-4' style={{ fontSize: '17px', fontWeight: '500' }}>
            BUSINESS CARDS:
          </h1>

          <h1 className='ps-3' style={{ lineHeight: '25px' }}>
            COMPANY NAME: NEROLIFE NETWORKS PVT. LTD.<br />
            COMPANY ADDRESS: #17, KIRLOSKAR LAYOUT,<br />
            BANGALORE 560073 KARNATAKA, INDIA<br />
            EMAIL: NEROLIFEEXCLUSIVENETWORKS@GMAIL.COM<br />
            CONTACT: (+91)-9740619310<br /><br />

            WELCOME TO <span className='font-bold'>NEROLIFE</span>. <span className='thoughtsAppliedstyles'>Thoughts Applied.</span>
            <br /><br /><br /><br />
          </h1>
        </div>

        <h1 className="text-light  text-center copyrightsection1 mt-5" style={{ fontSize: '10px' }}>All Rights Reserved. Copyright 2024 © NEROLIFE NETWORKS PVT. LTD. </h1>


      </div>

    
  )
}

export default CompanyProfile
