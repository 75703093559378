import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import "./NightCubeSystems.css";
import { useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const ArtistMangement = () => {
  const [expanded, setExpanded] = useState(false);

  const [artistData, setArtistData] = useState([]);
  const [artistMusicData, setArtistMusicData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getArtistbyCategory = async (category) => {
      try {
        const response = await axiosPrivate.get(
          `/artists/category/${category}`
        );

        if(category === 'artist_category'){
          setArtistData(response.data);
        }else{
          setArtistMusicData(response.data);
        }

       
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getArtistbyCategory('artist_category');
    isMounted && getArtistbyCategory('music_operations');

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="parent">
      <div className="mainsection content">
        <div className="container-fluid text-center">
          <div className="row mt-5">
            <div
              className="col  d-flex flex-column justify-content-start align-items-center "
              style={{ height: "400px", width: "33%" }}
            >
              <h4
                style={{ fontSize: "20px", fontWeight: "600" }}
                className="text-center"
              >
                ALL ARTISTS
              </h4>
              <div
                className="maincircle d-flex justify-content-evenly align-items-center mx-0"
                style={{ height: "100%", width: "100%" }}
              >


                <div
                  className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                  style={{ width: "100%" }}
                >
                  <PieChart
                    series={[
                      {
                        data: artistData.map((item, index) => ({
                          id: index,
                          value: item.count,
                          label: item.category.toUpperCase(),
                          color:
                            item.category === "ELECTRONIC MUSICIAN & PRODUCER"
                              ? "#FAF700"
                              : item.category === "DJ"
                              ? "#00EBFA"
                              : item.category === "KARAOKE"
                              ? "#FF3600"
                              : item.category === "VOCALIST & SINGER"
                              ? "#FFFFFF"
                              : item.category === "ACOUSTIC"
                              ? "#3396B8"
                              : item.category === "LIVE BAND"
                              ? "#00FC7E"
                              : "#FE0041",
                        })),
                      },
                    ]}
                    width={890}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>

                <div
                  className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                  style={{ width: "100%" }}
                >
                  <PieChart
                    series={[
                      {
                        data: artistMusicData.map((item, index) => ({
                          id: index,
                          value: item.count,
                           label:  item.category.toUpperCase(),
                          
                          color:
                            item.category === "KANNADA (ಕನ್ನಡ)"
                              ? "#FAF700"
                              : item.category === "DUBSTEP/BASS"
                              ? "#FF3600"
                              : item.category === "HIP-HOP/TRAP"
                              ? "#00EBFA"
                              : item.category === "BOLLYWOOD (हिंदी)"
                              ? "#FFFFFF"
                              : item.category === "LIVE BAND/METAL"
                              ? "#3396B8"
                              : item.category === "TECHNO/UNDERGROUND"
                              ? "#00FC7E"
                              : item.category === "CHILL/HOUSE"
                              ? "#6E7C7A"
                              : item.category === "BIG ROOM/ELECTRO"
                              ? "#ED0241"
                              : item.category === "EDM"
                              ? "#AD5041"
                              : item.category === "DEEP/HOUSE"
                              ? "#FAF700"
                              : "#FE0041"

                             
                        })),
                      },
                    ]}
                    width={890}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>


              </div>
            </div>
          </div>

          <div className="mainsectionend ">
            <div className=" flex justify-content-end">
              <div
                className=" flex align-items-center p-3 justify-content-between font-semibold"
                style={{ height: "50px", width: "250px" }}
              >
                <h1>EXPAND DISTRIBUTION</h1>

                {expanded ? (
                  <KeyboardDoubleArrowUpIcon
                    onClick={() => setExpanded(false)}
                    className="cursor-pointer"
                    style={{ fontSize: "30" }}
                  />
                ) : (
                  <KeyboardDoubleArrowDownIcon
                    onClick={() => setExpanded(true)}
                    className="cursor-pointer"
                    style={{ fontSize: "30" }}
                  />
                )}
              </div>
            </div>

            {expanded && (
              <div>
                <div className="accordion-body p-5">
                  <div className="row custom-pie-chart-container">

                    <div
                      className="col d-flex flex-column justify-content-center align-items-center"
                      style={{ height: "550px" }}
                    >
                      <div
                          className="maincircle d-flex justify-content-center"
                          style={{ height: "80%", width: "100%" }}
                        >
                          <div className="piechart d-flex align-items-center">
                            <PieChart
                              series={[
                                {
                                  data: artistData.map((item, index) => ({
                                    id: index,
                                    value: item.count,

                                    color:
                                      item.category ===
                                      "ELECTRONIC MUSICIAN & PRODUCER"
                                        ? "#FAF700"
                                        : item.category === "DJ"
                                        ? "#00EBFA"
                                        : item.category === "KARAOKE"
                                        ? "#FF3600"
                                        : item.category === "VOCALIST & SINGER"
                                        ? "#FFFFFF"
                                        : item.category === "ACOUSTIC"
                                        ? "#3396B8"
                                        : item.category === "LIVE BAND"
                                        ? "#00FC7E"
                                        : item.category === "METAL & TOOLS"
                                        ? "#FE0041"
                                        : "#01950F",
                                  })),
                                },
                              ]}
                              width={250}
                              height={300}
                            />
                          </div>

                          <div className="piecalculations d-flex align-items-center">
                            <BarChart
                              xAxis={[
                                {
                                  id: "barCategories",
                                  data: artistData.map((item, index) =>
                                    item.category.toUpperCase()
                                  ), // Assuming you want to use index as category
                                  scaleType: "band",
                                },
                              ]}
                              series={[
                                {
                                  data: artistData.map((item) => item.count),
                                },
                              ]}
                              width={1200}
                              height={500}
                            />
                          </div>
                        </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FAF700",
                            }}
                          ></div>
                          <h1>ELECTRONIC MUSICIAN & PRODUCER</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00EBFA",
                            }}
                          ></div>
                          <h1>KARAOKE</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FF3600",
                            }}
                          ></div>
                          <h1>LIVE BAND</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FFFFFF",
                            }}
                          ></div>
                          <h1>METAL & TOOLS</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#3396B8",
                            }}
                          ></div>
                          <h1>VOCALIST & SINGER</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>ACCOUSTIC</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FE0041",
                            }}
                          ></div>
                          <h1>DJ</h1>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col mt-5 d-flex flex-column justify-content-center align-items-center"
                      style={{ height: "550px" }}
                    >
                      <div
                          className="maincircle d-flex justify-content-center"
                          style={{ height: "80%", width: "100%" }}
                        >
                          <div className="piechart d-flex align-items-center">
                            <PieChart
                               series={[
                                {
                                  data: artistMusicData.map((item, index) => ({
                                    id: index,
                                    value: item.count,
                                    
                                    color:
                                      item.category === "KANNADA (ಕನ್ನಡ)"
                                        ? "#FAF700"
                                        : item.category === "DUBSTEP/BASS"
                                        ? "#FF3600"
                                        : item.category === "HIP-HOP/TRAP"
                                        ? "#00EBFA"
                                        : item.category === "BOLLYWOOD (हिंदी)"
                                        ? "#FFFFFF"
                                        : item.category === "LIVE BAND/METAL"
                                        ? "#3396B8"
                                        : item.category === "TECHNO/UNDERGROUND"
                                        ? "#00FC7E"
                                        : item.category === "CHILL/HOUSE"
                                        ? "#6E7C7A"
                                        : item.category === "BIG ROOM/ELECTRO"
                                        ? "#ED0241"
                                        : item.category === "EDM"
                                        ? "#AD5041"
                                        : "#5f99a1"
          
                                       
                                  })),
                                },
                              ]}
                              width={250}
                              height={300}
                            />
                          </div>

                          <div className="piecalculations d-flex align-items-center">
                            <BarChart
                              xAxis={[
                                {
                                  id: "barCategories",
                                  data: artistMusicData.map((item, index) =>
                                    item.category.toUpperCase()
                                  ), // Assuming you want to use index as category
                                  scaleType: "band",
                                },
                              ]}
                              series={[
                                {
                                  data: artistMusicData.map((item) => item.count),
                                },
                              ]}
                              width={1500}
                              height={500}
                            />
                          </div>
                        </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FAF700",
                            }}
                          ></div>
                          <h1>KANNADA (ಕನ್ನಡ)</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FF3600",
                            }}
                          ></div>
                          <h1>DUBSTEP/BASS</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00EBFA",
                            }}
                          ></div>
                          <h1>HIP-HOP/TRAP</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FFFFFF",
                            }}
                          ></div>
                          <h1>BOLLYWOOD (हिंदी)</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#3396B8",
                            }}
                          ></div>
                          <h1>LIVE BAND/METAL</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>TECHNO/UNDERGROUND</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#6E7C7A",
                            }}
                          ></div>
                          <h1>CHILL/HOUSE</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#ED0241",
                            }}
                          ></div>
                          <h1>BIG ROOM/ELECTRO</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#AD5041",
                            }}
                          ></div>
                          <h1>EDM</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#5f99a1",
                            }}
                          ></div>
                          <h1>DEEP/HOUSE</h1>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistMangement;
